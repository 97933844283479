/* eslint-disable no-magic-numbers */
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { DrawerHeading } from '@/shared/DrawerHeading';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { layersForCurrentModelSelector } from '@/redux/layers/layers.selectors';
import { Button } from '@/shared/Button';
import { JSX, useEffect, useMemo, useRef } from 'react';
import { openLayerFactoryModal } from '@/redux/modal/modal.slice';
import { hasPrivilegeToWriteProjectSelector } from '@/redux/user/user.selectors';
import { LayersDrawerLayer } from '@/components/Map/Drawer/LayersDrawer/LayersDrawerLayer.component';
import { mapEditToolsLayerObjectSelector } from '@/redux/mapEditTools/mapEditTools.selectors';

export const LayersDrawer = (): JSX.Element => {
  const layersForCurrentModel = useAppSelector(layersForCurrentModelSelector);
  const hasPrivilegeToWriteProject = useAppSelector(hasPrivilegeToWriteProjectSelector);
  const dispatch = useAppDispatch();
  const layersDrawerRef = useRef<HTMLDivElement>(null);
  const mapEditToolsLayerObject = useAppSelector(mapEditToolsLayerObjectSelector);

  const addNewLayer = (): void => {
    dispatch(openLayerFactoryModal());
  };

  const sortedLayers = useMemo(() => {
    return [...Object.values(layersForCurrentModel)].sort(
      (layerA, layerB) => layerB.details.z - layerA.details.z,
    );
  }, [layersForCurrentModel]);

  const negativeZLayers = useMemo(
    () => sortedLayers.filter(layer => layer.details.z < 0),
    [sortedLayers],
  );
  const positiveZLayers = useMemo(
    () => sortedLayers.filter(layer => layer.details.z >= 0),
    [sortedLayers],
  );

  useEffect(() => {
    if (!mapEditToolsLayerObject || !layersDrawerRef.current) {
      return;
    }
    const layerObjectElement =
      document.getElementById(`layer-image-item-${mapEditToolsLayerObject.id}`) ||
      document.getElementById(`layer-text-item-${mapEditToolsLayerObject.id}`) ||
      document.getElementById(`layer-rect-item-${mapEditToolsLayerObject.id}`) ||
      document.getElementById(`layer-oval-item-${mapEditToolsLayerObject.id}`) ||
      document.getElementById(`layer-line-item-${mapEditToolsLayerObject.id}`);
    if (!layerObjectElement) {
      return;
    }

    const container = layersDrawerRef.current;
    const extraPadding = 20;

    const elementRect = layerObjectElement.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    if (elementRect.top < containerRect.top) {
      container.scrollTo({
        top: container.scrollTop + (elementRect.top - containerRect.top) - extraPadding,
        behavior: 'smooth',
      });
    }

    if (elementRect.bottom > containerRect.bottom) {
      container.scrollTo({
        top: container.scrollTop + (elementRect.bottom - containerRect.bottom) + extraPadding,
        behavior: 'smooth',
      });
    }
  }, [mapEditToolsLayerObject]);

  return (
    <div data-testid="layers-drawer" className="h-full max-h-full">
      <DrawerHeading title="Layers" />
      <div
        className="flex h-[calc(100%-93px)] max-h-[calc(100%-93px)] flex-col overflow-y-auto px-6 pb-4"
        ref={layersDrawerRef}
      >
        {hasPrivilegeToWriteProject && (
          <div className="flex justify-start py-2">
            <Button icon="plus" isIcon isFrontIcon onClick={addNewLayer}>
              Add layer
            </Button>
          </div>
        )}
        <div className="flex flex-col gap-2">
          {Boolean(positiveZLayers.length) && (
            <span className="border-b-2 border-dashed border-b-gray-400 text-center text-lg font-semibold">
              Layers above the diagram
            </span>
          )}
          <div className="flex flex-col gap-5">
            {positiveZLayers.map(layer => (
              <LayersDrawerLayer key={layer.details.id} layerDetails={layer.details} />
            ))}
          </div>
          {Boolean(negativeZLayers.length) && (
            <span className="border-b-2 border-dashed border-b-gray-400 text-center text-lg font-semibold">
              Layers below the diagram
            </span>
          )}
          <div className="flex flex-col gap-5">
            {negativeZLayers.map(layer => (
              <LayersDrawerLayer key={layer.details.id} layerDetails={layer.details} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
