/* eslint-disable no-magic-numbers */
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { MapInstance } from '@/types/map';
import { HorizontalAlign, VerticalAlign } from '@/components/Map/MapViewer/MapViewer.types';
import {
  BLACK_COLOR,
  COMPARTMENT_CIRCLE_CENTER,
  COMPARTMENT_CIRCLE_RADIUS,
  WHITE_COLOR,
} from '@/components/Map/MapViewer/MapViewer.constants';
import { Color } from '@/types/models';
import VectorSource from 'ol/source/Vector';
import { MapSize } from '@/redux/map/map.types';
import Compartment from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/Compartment/Compartment';
import getEllipseCoords from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getEllipseCoords';
import getCoordsX from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getCoordsX';
import getCoordsY from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getCoordsY';
import isPointInEllipse from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/isPointInEllipse';

export type CompartmentCircleProps = {
  id: number;
  complexId?: number | null;
  compartmentId: number | null;
  pathwayId: number | null;
  sboTerm: string;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  fillColor?: Color;
  borderColor?: Color;
  fontColor?: Color;
  innerWidth?: number;
  outerWidth?: number;
  thickness?: number;
  text?: string;
  fontSize?: number;
  nameX: number;
  nameY: number;
  nameHeight: number;
  nameWidth: number;
  nameVerticalAlign?: VerticalAlign;
  nameHorizontalAlign?: HorizontalAlign;
  overlaysVisible: boolean;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  vectorSource: VectorSource;
  backgroundId: number;
  mapSize: MapSize;
};

export default class CompartmentCircle extends Compartment {
  innerCenterX: number;

  innerCenterY: number;

  innerRx: number;

  innerRy: number;

  constructor({
    id,
    complexId,
    compartmentId,
    pathwayId,
    sboTerm,
    x,
    y,
    width,
    height,
    zIndex,
    fillColor = WHITE_COLOR,
    borderColor = BLACK_COLOR,
    fontColor = BLACK_COLOR,
    innerWidth = 1,
    outerWidth = 2,
    thickness = 12,
    text = '',
    fontSize = 12,
    nameX,
    nameY,
    nameHeight,
    nameWidth,
    nameVerticalAlign = 'MIDDLE',
    nameHorizontalAlign = 'CENTER',
    overlaysVisible,
    pointToProjection,
    mapInstance,
    vectorSource,
    backgroundId,
    mapSize,
  }: CompartmentCircleProps) {
    super({
      id,
      complexId,
      compartmentId,
      pathwayId,
      sboTerm,
      x,
      y,
      width,
      height,
      thickness,
      outerWidth,
      innerWidth,
      zIndex,
      text,
      fontSize,
      nameX,
      nameY,
      nameWidth,
      nameHeight,
      fontColor,
      nameVerticalAlign,
      nameHorizontalAlign,
      fillColor,
      borderColor,
      overlaysVisible,
      pointToProjection,
      mapInstance,
      vectorSource,
      backgroundId,
      mapSize,
    });
    const innerX = this.x + this.thickness;
    const innerY = this.y + this.thickness;
    const innerCompartmentWidth = this.width - 2 * this.thickness;
    const innerCompartmentHeight = this.height - 2 * this.thickness;

    this.innerCenterX = getCoordsX(
      innerX,
      COMPARTMENT_CIRCLE_CENTER.absoluteX,
      COMPARTMENT_CIRCLE_CENTER.relativeX,
      COMPARTMENT_CIRCLE_CENTER.relativeHeightForX,
      innerCompartmentHeight,
      innerCompartmentWidth,
    );
    this.innerCenterY = getCoordsY(
      innerY,
      COMPARTMENT_CIRCLE_CENTER.absoluteY,
      COMPARTMENT_CIRCLE_CENTER.relativeY,
      COMPARTMENT_CIRCLE_CENTER.relativeWidthForY,
      innerCompartmentHeight,
      innerCompartmentWidth,
    );
    this.innerRx =
      COMPARTMENT_CIRCLE_RADIUS.absoluteX +
      (COMPARTMENT_CIRCLE_RADIUS.relativeX * innerCompartmentWidth) / 100 +
      (innerCompartmentHeight * (COMPARTMENT_CIRCLE_RADIUS.relativeHeightForX || 0)) / 100;
    this.innerRy =
      COMPARTMENT_CIRCLE_RADIUS.absoluteY +
      (COMPARTMENT_CIRCLE_RADIUS.relativeY * innerCompartmentHeight) / 100 +
      (innerCompartmentWidth * (COMPARTMENT_CIRCLE_RADIUS.relativeWidthForY || 0)) / 100;

    this.feature.set('isCompartmentClicked', this.isCompartmentClicked.bind(this));
  }

  private isCompartmentClicked({
    point,
    hitTolerance = 5,
  }: {
    point: { x: number; y: number };
    hitTolerance?: number;
  }): boolean {
    const effectiveInnerRx = this.innerRx - hitTolerance;
    const effectiveInnerRy = this.innerRy - hitTolerance;

    return !isPointInEllipse({
      point,
      centerX: this.innerCenterX,
      centerY: this.innerCenterY,
      radiusX: effectiveInnerRx,
      radiusY: effectiveInnerRy,
    });
  }

  protected getCompartmentCoords(): void {
    this.outerCoords = getEllipseCoords({
      x: this.x,
      y: this.y,
      center: COMPARTMENT_CIRCLE_CENTER,
      radius: COMPARTMENT_CIRCLE_RADIUS,
      height: this.height,
      width: this.width,
      points: 36,
      pointToProjection: this.pointToProjection,
    });
    this.innerCoords = getEllipseCoords({
      x: this.x + this.thickness,
      y: this.y + this.thickness,
      center: COMPARTMENT_CIRCLE_CENTER,
      radius: COMPARTMENT_CIRCLE_RADIUS,
      height: this.height - 2 * this.thickness,
      width: this.width - 2 * this.thickness,
      points: 20,
      pointToProjection: this.pointToProjection,
    });
  }
}
