import { ONE, ZERO } from '@/constants/common';
import { rootSelector } from '@/redux/root/root.selectors';
import { ModelElementWithPinType } from '@/types/modelElement';
import { ElementIdTabObj } from '@/types/elements';
import { ModelElement } from '@/types/models';
import { createSelector } from '@reduxjs/toolkit';
import {
  allModelElementsSubmapConnectionsForCurrentSubmapSelector,
  allSearchModelElementForCurrentModelSelector,
  allSearchModelElementsIdTabForCurrentModelSelector,
  searchedModelElementsForCurrentModelSelector,
  searchedModelElementsSelector,
} from '@/redux/modelElements/modelElements.selector';
import {
  allDrugsElementsOfCurrentMapSelector,
  allDrugsIdTabSelectorOfCurrentMap,
  drugsElementsForSelectedSearchElementSelector,
  searchedDrugsElementsOfCurrentMapSelector,
} from '@/redux/drugs/drugs.selectors';
import {
  allChemicalsElementsOfCurrentMapSelector,
  allChemicalsIdTabSelectorOfCurrentMap,
  chemicalsElementsForSelectedSearchElementSelector,
  searchedChemicalsElementsOfCurrentMapSelector,
} from '../chemicals/chemicals.selectors';

export const bioEntitySelector = createSelector(rootSelector, state => state.bioEntity);

export const bioEntityIsContentTabOpenedSelector = createSelector(
  bioEntitySelector,
  bioEntity => bioEntity.isContentTabOpened,
);

export const allVisibleBioEntitiesSelector = createSelector(
  searchedModelElementsForCurrentModelSelector,
  searchedChemicalsElementsOfCurrentMapSelector,
  searchedDrugsElementsOfCurrentMapSelector,
  (content, chemicals, drugs): Array<ModelElement> => {
    return [content, chemicals, drugs].flat();
  },
);

export const allBioEntitiesForSearchElementSelector = createSelector(
  searchedModelElementsSelector,
  chemicalsElementsForSelectedSearchElementSelector,
  drugsElementsForSelectedSearchElementSelector,
  (content, chemicals, drugs): Array<ModelElement> => {
    const contentBioEntities = (content?.data || []).map(({ modelElement }) => modelElement);

    return [contentBioEntities, chemicals || [], drugs || []].flat();
  },
);

export const allElementsForSearchElementNumberByModelId = createSelector(
  allBioEntitiesForSearchElementSelector,
  (elements): Record<number, number> => {
    return elements.reduce(
      (acc, { model }) => ({
        ...acc,
        [model]: (acc?.[model] || ZERO) + ONE,
      }),
      {} as Record<number, number>,
    );
  },
);

export const allBioEntitiesElementsIdsSelector = createSelector(
  allSearchModelElementsIdTabForCurrentModelSelector,
  allChemicalsIdTabSelectorOfCurrentMap,
  allDrugsIdTabSelectorOfCurrentMap,
  (content, chemicals, drugs): ElementIdTabObj => {
    return {
      ...content,
      ...chemicals,
      ...drugs,
    };
  },
);

export const allSubmapConnectionsBioEntityOfCurrentSubmapWithRealConnectionsSelector =
  createSelector(
    allModelElementsSubmapConnectionsForCurrentSubmapSelector,
    allElementsForSearchElementNumberByModelId,
    (submapConnectionsBioEntity, modelElementsNumber): ModelElement[] => {
      return submapConnectionsBioEntity.filter(
        ({ submodel }) => submodel && modelElementsNumber?.[submodel.mapId] > ZERO,
      );
    },
  );

export const allBioEntitiesWithTypeOfCurrentMapSelector = createSelector(
  allSearchModelElementForCurrentModelSelector,
  allChemicalsElementsOfCurrentMapSelector,
  allDrugsElementsOfCurrentMapSelector,
  allSubmapConnectionsBioEntityOfCurrentSubmapWithRealConnectionsSelector,
  (content, chemicals, drugs, submapConnections): ModelElementWithPinType[] => {
    return [
      content.map(v => ({ ...v, type: 'modelElement' as const })),
      chemicals.map(v => ({ ...v, type: 'chemicals' as const })),
      drugs.map(v => ({ ...v, type: 'drugs' as const })),
      submapConnections.map(v => ({ ...v, type: 'modelElement' as const })),
    ].flat();
  },
);

export const allVisibleBioEntitiesIdsSelector = createSelector(
  allVisibleBioEntitiesSelector,
  allSubmapConnectionsBioEntityOfCurrentSubmapWithRealConnectionsSelector,
  (elements, submapConnections): (string | number)[] => {
    return [...elements, ...submapConnections].map(e => e.id);
  },
);
