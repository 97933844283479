import { ONE, ZERO } from '@/constants/common';
import { EntityNumber } from '@/redux/entityNumber/entityNumber.types';
import { ModelElementWithPinType, MultiPinModelElement } from '@/types/modelElement';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { getMultipinCanvasArgs } from './getMultipinCanvasArgs';
import { getMultipinStyle } from './getMultipinStyle';
import { getPinFeature } from './getPinFeature';

export const getMultipinSingleFeature = (
  multipin: MultiPinModelElement,
  {
    pointToProjection,
    entityNumber,
    activeIds,
  }: {
    pointToProjection: UsePointToProjectionResult;
    entityNumber: EntityNumber;
    activeIds: (string | number)[];
  },
): Feature => {
  const [mainElement, ...sortedElements] = multipin.sort(
    (a, b) => (activeIds.includes(b.id) ? ONE : ZERO) - (activeIds.includes(a.id) ? ONE : ZERO),
  );
  const feature = getPinFeature(mainElement, pointToProjection, mainElement.type);

  const canvasPinsArgMainElement = getMultipinCanvasArgs(mainElement, {
    activeIds,
    entityNumber,
    isDarkColor: true,
  });

  const canvasPinsArgs = sortedElements.map((element: ModelElementWithPinType) =>
    getMultipinCanvasArgs(element, {
      activeIds,
      entityNumber: {}, // additional elements id's should be not visible
    }),
  );

  const style = getMultipinStyle({ pins: [canvasPinsArgMainElement, ...canvasPinsArgs] });

  feature.setStyle(style);
  return feature;
};
