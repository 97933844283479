export const MAX_LINE_WIDTH = 48;

export const MIN_LINE_WIDTH = 1;

export const MAX_ARROW_SCALE = 50;

export const MIN_ARROW_SCALE = 0.1;

export const DEFAULT_LINE_TYPE = 'SOLID';

export const DEFAULT_ARROW = 'NONE';

export const DEFAULT_ARROW_SCALE = 1;

export const DEFAULT_ARROW_LENGTH = 15;

export const DEFAULT_ARROW_ANGLE = 2.748893571891069;

export const DEFAULT_ARROW_LINE_TYPE = 'SOLID';
