import { ModelElement, NewReaction } from '@/types/models';

export function mapReactionToModelElement(reaction: NewReaction): ModelElement {
  return {
    id: reaction.id,
    name: reaction.name,
    model: reaction.model,
    elementId: reaction.elementId,
    references: reaction.references,
    z: reaction.z,
    notes: reaction.notes,
    symbol: reaction.symbol,
    visibilityLevel: reaction.visibilityLevel,
    synonyms: reaction.synonyms,
    abbreviation: reaction.abbreviation,
    formula: reaction.formula,
    sboTerm: reaction.sboTerm,
  } as ModelElement;
}
