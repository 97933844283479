import { WebSocketEntityUpdateInterface } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.types';
import { store } from '@/redux/store';
import { ENTITY_OPERATION_TYPES } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.constants';
import { MapInstance } from '@/types/map';
import drawElementOnLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/drawElementOnLayer';
import { layerDeleteRect } from '@/redux/layers/layers.slice';
import removeElementFromLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/removeElementFromLayer';
import updateElement from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/updateElement';
import { getLayerRect } from '@/redux/layers/layers.thunks';

export default async function processLayerRect({
  data,
  mapInstance,
}: {
  data: WebSocketEntityUpdateInterface;
  mapInstance: MapInstance;
}): Promise<void> {
  const { dispatch } = store;
  if (
    data.type === ENTITY_OPERATION_TYPES.ENTITY_CREATED ||
    data.type === ENTITY_OPERATION_TYPES.ENTITY_UPDATED
  ) {
    const resultRect = await dispatch(
      getLayerRect({
        modelId: data.mapId,
        layerId: data.layerId,
        rectId: data.entityId,
      }),
    ).unwrap();
    if (!resultRect) {
      return;
    }
    if (data.type === ENTITY_OPERATION_TYPES.ENTITY_CREATED) {
      drawElementOnLayer({
        mapInstance,
        activeLayer: data.layerId,
        object: resultRect,
        drawFunctionKey: 'drawRect',
      });
    } else {
      updateElement(mapInstance, data.layerId, resultRect);
    }
  } else if (data.type === ENTITY_OPERATION_TYPES.ENTITY_DELETED) {
    dispatch(
      layerDeleteRect({
        modelId: data.mapId,
        layerId: data.layerId,
        rectId: data.entityId,
      }),
    );
    removeElementFromLayer({ mapInstance, layerId: data.layerId, featureId: data.entityId });
  }
}
