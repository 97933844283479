/* eslint-disable no-magic-numbers */
import { OverlayBioEntityRender } from '@/types/OLrendering';
import { GetOverlayBioEntityColorByAvailableProperties } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useGetOverlayColor';
import { OverlayBioEntityGroupedElementsType } from '@/components/Map/MapViewer/MapViewer.types';
import sortElementOverlayByColor from '@/components/Map/MapViewer/utils/mapElementsRendering/overlay/sortElementOverlayByColor';
import calculateOverlayDimensions from '@/components/Map/MapViewer/utils/mapElementsRendering/overlay/calculateOverlayDimensions';

export default function processOverlayGroupedElements(
  groupedElements: OverlayBioEntityGroupedElementsType,
  entityOverlays: Array<OverlayBioEntityRender>,
  getColor: GetOverlayBioEntityColorByAvailableProperties,
): void {
  Object.values(groupedElements).forEach(elementOverlay => {
    const overlaysPerGroup: Array<OverlayBioEntityRender> = [];
    sortElementOverlayByColor(elementOverlay, getColor);

    const totalHeight = elementOverlay[0].height;
    const totalAmount = elementOverlay.reduce(
      (accumulator: number, overlay) => accumulator + overlay.amount,
      0,
    );

    elementOverlay.forEach((overlay, index) => {
      const overlayEntity = calculateOverlayDimensions(
        overlay,
        index,
        totalAmount,
        totalHeight,
        overlaysPerGroup,
      );
      overlaysPerGroup.push(overlayEntity);
    });
    entityOverlays.push(...overlaysPerGroup);
  });
}
