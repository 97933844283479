/* eslint-disable no-magic-numbers */
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import Style from 'ol/style/Style';
import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { MapInstance } from '@/types/map';
import { Color, LayerOval as LayerOvalModel } from '@/types/models';
import { rgbToHex } from '@/components/Map/MapViewer/utils/mapElementsRendering/style/rgbToHex';
import Polygon from 'ol/geom/Polygon';
import getStroke from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStroke';
import getStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStyle';
import getScaledElementStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getScaledElementStyle';
import { Stroke } from 'ol/style';
import { MapSize } from '@/redux/map/map.types';
import { Coordinate } from 'ol/coordinate';
import getEllipseCoords from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getEllipseCoords';
import {
  LAYER_ELEMENT_TYPES,
  TRANSPARENT_COLOR,
} from '@/components/Map/MapViewer/MapViewer.constants';
import { BoundingBox } from '@/components/Map/MapViewer/MapViewer.types';
import { store } from '@/redux/store';
import { updateLayerOval } from '@/redux/layers/layers.thunks';
import { layerUpdateOval } from '@/redux/layers/layers.slice';
import { mapEditToolsSetLayerObject } from '@/redux/mapEditTools/mapEditTools.slice';
import getBoundingBoxPolygon from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/utils/getBoundingBoxPolygon';

export interface LayerOvalProps {
  elementId: number;
  x: number;
  y: number;
  width: number;
  height: number;
  layer: number;
  zIndex: number;
  color: Color;
  borderColor: Color;
  lineWidth: number;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  mapSize: MapSize;
}

export default class LayerOval {
  elementId: number;

  x: number;

  y: number;

  width: number;

  height: number;

  layer: number;

  zIndex: number;

  color: Color;

  borderColor: Color;

  lineWidth: number;

  style: Style = new Style();

  ovalPolygon: Polygon = new Polygon([]);

  boundingBoxPolygon: Polygon = new Polygon([]);

  strokeStyle: Stroke = new Stroke();

  feature: Feature<Polygon>;

  mapSize: MapSize;

  pointToProjection: UsePointToProjectionResult;

  minResolution: number;

  constructor({
    elementId,
    x,
    y,
    width,
    height,
    layer,
    zIndex,
    color,
    borderColor,
    lineWidth,
    pointToProjection,
    mapInstance,
    mapSize,
  }: LayerOvalProps) {
    this.elementId = elementId;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.layer = layer;
    this.zIndex = zIndex;
    this.color = color;
    this.borderColor = borderColor;
    this.lineWidth = lineWidth;
    this.pointToProjection = pointToProjection;
    this.mapSize = mapSize;

    const maxZoom = mapInstance?.getView().get('originalMaxZoom');
    this.minResolution = mapInstance?.getView().getResolutionForZoom(maxZoom) || 1;

    this.drawPolygon();

    this.setStyles();

    this.feature = new Feature({
      geometry: this.boundingBoxPolygon,
      layer,
      elementType: LAYER_ELEMENT_TYPES.OVAL,
    });
    this.feature.setId(this.elementId);
    this.feature.set('getObjectData', this.getData.bind(this));
    this.feature.set('setCoordinates', this.setCoordinates.bind(this));
    this.feature.set('setOvalCoordinates', this.setOvalCoordinates.bind(this));
    this.feature.set('refreshPolygon', this.refreshPolygon.bind(this));
    this.feature.set('updateElement', this.updateElement.bind(this));
    this.feature.set('save', this.save.bind(this));
    this.feature.setStyle(this.getStyle.bind(this));
  }

  private getData(): LayerOvalModel {
    return {
      id: this.elementId,
      x: this.x,
      y: this.y,
      z: this.zIndex,
      width: this.width,
      height: this.height,
      layer: this.layer,
      color: this.color,
      borderColor: this.borderColor,
      lineWidth: this.lineWidth,
    };
  }

  private async save({
    modelId,
    boundingBox,
  }: {
    modelId: number;
    boundingBox: BoundingBox;
  }): Promise<void> {
    const { dispatch } = store;
    const layerOval = await dispatch(
      updateLayerOval({
        modelId,
        layerId: this.layer,
        ...this.getData(),
        ...boundingBox,
      }),
    ).unwrap();
    if (layerOval) {
      dispatch(layerUpdateOval({ modelId, layerId: layerOval.layer, layerOval }));
      dispatch(mapEditToolsSetLayerObject(layerOval));
      this.updateElement(layerOval);
    }
  }

  private drawPolygon(): void {
    const coords = getEllipseCoords({
      x: this.x + this.width / 2,
      y: this.y + this.height / 2,
      height: this.height,
      width: this.width,
      pointToProjection: this.pointToProjection,
      points: 60,
    });
    this.ovalPolygon = new Polygon([coords]);
    this.boundingBoxPolygon = getBoundingBoxPolygon({
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      pointToProjection: this.pointToProjection,
    });
  }

  private refreshPolygon(): void {
    this.drawPolygon();
    this.feature.setGeometry(this.boundingBoxPolygon);
    this.feature.changed();
  }

  private setStyles(): void {
    this.strokeStyle = getStroke({
      color: rgbToHex(this.borderColor),
      width: this.lineWidth,
    });
    this.style = getStyle({
      geometry: this.ovalPolygon,
      borderColor: this.borderColor,
      fillColor: TRANSPARENT_COLOR,
      lineWidth: this.lineWidth,
      zIndex: this.zIndex,
    });
  }

  private updateElement(layerOval: LayerOvalModel): void {
    this.elementId = layerOval.id;
    this.x = layerOval.x;
    this.y = layerOval.y;
    this.width = layerOval.width;
    this.height = layerOval.height;
    this.zIndex = layerOval.z;
    this.color = layerOval.color;
    this.borderColor = layerOval.borderColor;

    this.refreshPolygon();
    this.setStyles();
    this.feature.changed();
  }

  private setCoordinates(coords: Coordinate[][]): void {
    const boundingBoxGeometry = this.feature.getGeometry();
    if (boundingBoxGeometry) {
      boundingBoxGeometry.setCoordinates(coords);
    }
  }

  private setOvalCoordinates(ellipseCoords: Coordinate[][]): void {
    const ovalGeometry = this.style.getGeometry();
    if (ovalGeometry && ovalGeometry instanceof Polygon) {
      ovalGeometry.setCoordinates(ellipseCoords);
    }
  }

  protected getStyle(_: FeatureLike, resolution: number): Style | Array<Style> | void {
    const scale = this.minResolution / resolution;
    return getScaledElementStyle(this.style, this.strokeStyle, scale);
  }
}
