/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import './LayerOvalFactoryModal.styles.css';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { Button } from '@/shared/Button';
import { BLACK_COLOR } from '@/components/Map/MapViewer/MapViewer.constants';
import { LayerOvalForm } from '@/components/FunctionalArea/Modal/LayerOvalFactoryModal/LayerOvalForm.component';
import { LayerOvalFactoryForm } from '@/components/FunctionalArea/Modal/LayerOvalFactoryModal/LayerOvalFactory.types';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  layersDrawLayerSelector,
  maxObjectZIndexForLayerSelector,
} from '@/redux/layers/layers.selectors';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { Color } from '@/types/models';
import { layerObjectFactoryStateSelector } from '@/redux/modal/modal.selector';
import { addLayerOval } from '@/redux/layers/layers.thunks';
import { showToast } from '@/utils/showToast';
import { layerAddOval } from '@/redux/layers/layers.slice';
import drawElementOnLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/drawElementOnLayer';
import { closeModal } from '@/redux/modal/modal.slice';
import { mapEditToolsSetActiveAction } from '@/redux/mapEditTools/mapEditTools.slice';
import { SerializedError } from '@reduxjs/toolkit';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useMapInstance } from '@/utils/context/mapInstanceContext';

export const LayerOvalFactoryModal: React.FC = () => {
  const drawLayer = useAppSelector(layersDrawLayerSelector);
  const currentModelId = useAppSelector(currentModelIdSelector);
  const layerObjectFactoryState = useAppSelector(layerObjectFactoryStateSelector);
  const maxZIndex = useAppSelector(state => maxObjectZIndexForLayerSelector(state, drawLayer));
  const dispatch = useAppDispatch();
  const { mapInstance } = useMapInstance();

  const [isSending, setIsSending] = useState<boolean>(false);
  const [data, setData] = useState<LayerOvalFactoryForm>({
    color: BLACK_COLOR,
  });

  const handleSubmit = async (): Promise<void> => {
    if (!layerObjectFactoryState || !drawLayer) {
      return;
    }
    try {
      const layerOval = await dispatch(
        addLayerOval({
          modelId: currentModelId,
          layerId: drawLayer,
          boundingBox: layerObjectFactoryState,
          ovalData: data,
          z: maxZIndex + 1,
        }),
      ).unwrap();
      if (!layerOval) {
        showToast({
          type: 'error',
          message: 'An error occurred while adding a new oval.',
        });
        return;
      }
      dispatch(layerAddOval({ modelId: currentModelId, layerId: drawLayer, layerOval }));
      drawElementOnLayer({
        mapInstance,
        activeLayer: drawLayer,
        object: layerOval,
        drawFunctionKey: 'drawOval',
      });
      showToast({
        type: 'success',
        message: 'A new oval has been successfully added.',
      });
      dispatch(closeModal());
      dispatch(mapEditToolsSetActiveAction(null));
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while adding a new oval.',
      });
    } finally {
      setIsSending(false);
    }
  };

  const changeValues = (value: string | number | Color, key: string): void => {
    setData(prevData => ({ ...prevData, [key]: value }));
  };

  return (
    <div className="relative flex w-[300px] flex-col gap-4 rounded-b-lg border border-t-[#E1E0E6] bg-white p-[24px]">
      {isSending && (
        <div className="c-layer-oval-factory-modal-loader">
          <LoadingIndicator width={44} height={44} />
        </div>
      )}
      <LayerOvalForm onChange={changeValues} data={data} />
      <hr />
      <Button
        type="button"
        onClick={handleSubmit}
        className="justify-center self-end justify-self-end text-base font-medium"
      >
        Submit
      </Button>
    </div>
  );
};
