import React from 'react';
import Select, { SingleValue } from 'react-select';
import './Autocomplete.styles.css';

type AutocompleteProps<T> = {
  options: Array<T>;
  valueKey?: keyof T;
  labelKey?: keyof T;
  placeholder?: string;
  clearable?: boolean;
  onChange: (value: T | null) => void;
  initialValue?: T | null;
};

type OptionType<T> = {
  value: T[keyof T];
  label: string;
  originalOption: T;
};

export const Autocomplete = <T,>({
  options,
  valueKey = 'value' as keyof T,
  labelKey = 'label' as keyof T,
  placeholder = 'Select...',
  clearable = false,
  onChange,
  initialValue = null,
}: AutocompleteProps<T>): React.JSX.Element => {
  const formattedOptions = options.map(option => ({
    value: option[valueKey],
    label: option[labelKey] as string,
    originalOption: option,
  }));

  const initialFormattedValue = React.useMemo(() => {
    if (!initialValue) {
      return null;
    }
    return (
      formattedOptions.find(option => option.originalOption[valueKey] === initialValue[valueKey]) ||
      null
    );
  }, [initialValue, valueKey, labelKey, formattedOptions]);

  const handleChange = (selectedOption: SingleValue<OptionType<T>>): void => {
    onChange(selectedOption ? selectedOption.originalOption : null);
  };

  return (
    <div data-testid="autocomplete">
      <Select<OptionType<T>>
        value={initialFormattedValue}
        options={formattedOptions}
        onChange={handleChange}
        placeholder={placeholder}
        isClearable={clearable}
        classNamePrefix="react-select"
      />
    </div>
  );
};

Autocomplete.displayName = 'Autocomplete';
