/* eslint-disable no-magic-numbers */
import { useSelect } from 'downshift';

import { twMerge } from 'tailwind-merge';
import { Icon } from '@/shared/Icon';
import { OverlayGroup } from '@/types/models';

type OverlayGroupSelectorProps = {
  items: OverlayGroup[];
  value: OverlayGroup;
  onChange: (item: OverlayGroup) => void;
  label: string;
};

export const OverlayGroupSelector = ({
  items,
  value,
  onChange,
  label,
}: OverlayGroupSelectorProps): JSX.Element => {
  const onItemSelect = (item: OverlayGroup | undefined | null): void => {
    if (item) {
      onChange(item);
    }
  };

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useSelect({
    items,
    defaultSelectedItem: items[0],
    selectedItem: value,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => onItemSelect(newSelectedItem),
  });

  return (
    <div className="mb-2.5">
      <p className="my-2.5 text-sm">{label}</p>

      <div className={twMerge('relative rounded-t bg-cultured text-xs', !isOpen && 'rounded-b')}>
        <div className={twMerge('flex w-full flex-col rounded-t py-2 pl-4 pr-3')}>
          <div
            {...getToggleButtonProps()}
            className="flex cursor-pointer flex-row items-center justify-between bg-cultured"
          >
            <span data-testid="selector-dropdown-button-name" className="font-medium">
              {selectedItem?.name}
            </span>
            <Icon
              name="chevron-down"
              className={twMerge('arrow-button h-6 w-6 fill-primary-500', isOpen && 'rotate-180')}
            />
          </div>
        </div>
        <ul
          {...getMenuProps()}
          className={`absolute inset-x-0 z-10 max-h-80 w-full overflow-scroll rounded-b bg-cultured p-0 ${
            !isOpen && 'hidden'
          }`}
        >
          {isOpen &&
            items.map((item, index) => (
              <li
                className={twMerge(
                  'border-t',
                  highlightedIndex === index && 'text-primary-500',
                  'flex flex-col px-4 py-2',
                )}
                key={item.id}
                {...getItemProps({ item, index })}
              >
                <span>{item.name}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
