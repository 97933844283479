/* eslint-disable no-magic-numbers */
import { Style, Text } from 'ol/style';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import Polygon from 'ol/geom/Polygon';
import { Color, Modification, Shape } from '@/types/models';
import { MapInstance } from '@/types/map';
import { HorizontalAlign, VerticalAlign } from '@/components/Map/MapViewer/MapViewer.types';
import {
  ACTIVITY_INFO_BOX_HEIGHT,
  ACTIVITY_INFO_BOX_WIDTH,
  BLACK_COLOR,
  COMPLEX_SBO_TERMS,
  DEFAULT_HOMODIMER_OFFSET,
  DEFAULT_HOMODIMER_SHIFT,
  HOMODIMER_INFO_BOX_HEIGHT,
  HOMODIMER_INFO_BOX_WIDTH,
  ION_CHANNEL_SBO_TERM,
  MAP_ELEMENT_TYPES,
  TRANSPARENT_COLOR,
  WHITE_COLOR,
} from '@/components/Map/MapViewer/MapViewer.constants';
import { BioShapesDict, LineTypeDict } from '@/redux/shapes/shapes.types';
import { FEATURE_TYPE } from '@/constants/features';
import { OverlayBioEntityRender } from '@/types/OLrendering';
import { getPolygonLatitudeCoordinates } from '@/components/Map/MapViewer/utils/config/overlaysLayer/getPolygonLatitudeCoordinates';
import { ZERO } from '@/constants/common';
import { OverlayOrder } from '@/redux/overlayBioEntity/overlayBioEntity.utils';
import { GetOverlayBioEntityColorByAvailableProperties } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useGetOverlayColor';
import VectorSource from 'ol/source/Vector';
import { MapSize } from '@/redux/map/map.types';
import getShapePolygon from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/utils/getShapePolygon';
import getTextStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/text/getTextStyle';
import getTextCoords from '@/components/Map/MapViewer/utils/mapElementsRendering/text/getTextCoords';
import getStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStyle';
import BaseMultiPolygon from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/BaseMultiPolygon';
import { rgbToHex } from '@/components/Map/MapViewer/utils/mapElementsRendering/style/rgbToHex';
import getFill from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getFill';
import getStroke from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStroke';
import getBoundingBoxPolygon from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/utils/getBoundingBoxPolygon';

export type MapElementProps = {
  id: number;
  complexId?: number | null;
  compartmentId: number | null;
  pathwayId: number | null;
  sboTerm: string;
  shapes: Array<Shape>;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  fillColor?: Color;
  borderColor?: Color;
  fontColor?: Color;
  lineWidth?: number;
  lineType?: string;
  text?: string;
  fontSize?: number;
  nameX: number;
  nameY: number;
  nameHeight: number;
  nameWidth: number;
  nameVerticalAlign?: VerticalAlign;
  nameHorizontalAlign?: HorizontalAlign;
  homodimer?: number;
  activity?: boolean;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  vectorSource: VectorSource;
  bioShapes?: BioShapesDict;
  lineTypes?: LineTypeDict;
  modifications?: Array<Modification>;
  overlays?: Array<OverlayBioEntityRender>;
  overlaysOrder?: Array<OverlayOrder>;
  overlaysVisible: boolean;
  getOverlayColor: GetOverlayBioEntityColorByAvailableProperties;
  backgroundId: number;
  sbgnFormat: boolean;
  mapSize: MapSize;
};

function getActivityInfoBoxOffset(withHomodimer: boolean): number {
  if (withHomodimer) {
    return -(
      (HOMODIMER_INFO_BOX_WIDTH + ACTIVITY_INFO_BOX_WIDTH) / 2 -
      HOMODIMER_INFO_BOX_WIDTH +
      DEFAULT_HOMODIMER_OFFSET / 2
    );
  }
  return -(ACTIVITY_INFO_BOX_WIDTH / 2);
}

function getHomodimerInfoBoxOffset(withActivity: boolean): number {
  if (withActivity) {
    return -(
      (HOMODIMER_INFO_BOX_WIDTH + ACTIVITY_INFO_BOX_WIDTH) / 2 +
      DEFAULT_HOMODIMER_OFFSET / 2
    );
  }
  return -(HOMODIMER_INFO_BOX_WIDTH / 2 + DEFAULT_HOMODIMER_OFFSET / 2);
}

export default class MapElement extends BaseMultiPolygon {
  shapes: Array<Shape>;

  lineWidth: number;

  lineType: string | undefined;

  bioShapes: BioShapesDict;

  lineTypes: LineTypeDict;

  homodimer: number;

  activity: boolean | undefined;

  modifications: Array<Modification>;

  lineDash: Array<number> = [];

  sbgnFormat: boolean = false;

  overlays: Array<OverlayBioEntityRender> = [];

  overlaysOrder: Array<OverlayOrder> = [];

  getOverlayColor: GetOverlayBioEntityColorByAvailableProperties;

  constructor({
    id,
    complexId,
    compartmentId,
    pathwayId,
    sboTerm,
    shapes,
    x,
    y,
    width,
    height,
    zIndex,
    fillColor = WHITE_COLOR,
    borderColor = BLACK_COLOR,
    fontColor = BLACK_COLOR,
    lineWidth = 1,
    lineType,
    text = '',
    fontSize = 12,
    nameX,
    nameY,
    nameHeight,
    nameWidth,
    nameVerticalAlign = 'MIDDLE',
    nameHorizontalAlign = 'CENTER',
    homodimer = 1,
    activity,
    pointToProjection,
    mapInstance,
    vectorSource,
    bioShapes = {},
    lineTypes = {},
    modifications = [],
    overlays = [],
    overlaysOrder = [],
    overlaysVisible,
    getOverlayColor,
    backgroundId,
    sbgnFormat,
    mapSize,
  }: MapElementProps) {
    super({
      type: FEATURE_TYPE.ALIAS,
      sboTerm,
      id,
      complexId,
      compartmentId,
      pathwayId,
      x,
      y,
      width,
      height,
      zIndex,
      text,
      fontSize,
      nameX,
      nameY,
      nameWidth,
      nameHeight,
      fontColor,
      nameVerticalAlign,
      nameHorizontalAlign,
      fillColor,
      borderColor,
      pointToProjection,
      vectorSource,
      overlaysVisible,
      backgroundId,
      mapSize,
      mapInstance,
    });
    this.sbgnFormat = sbgnFormat;
    this.shapes = shapes;
    this.lineWidth = lineWidth;
    this.lineType = lineType;
    this.homodimer = homodimer;
    this.activity = activity;
    this.bioShapes = bioShapes;
    this.lineTypes = lineTypes;
    this.modifications = modifications;
    this.overlays = overlays;
    this.overlaysOrder = overlaysOrder;
    this.getOverlayColor = getOverlayColor;
    this.createPolygons();
    this.drawText();
    this.drawMultiPolygonFeature(mapInstance);
  }

  private getMaxZIndex(): number {
    let maxZIndex = -Infinity;
    if (this.zIndex > maxZIndex) {
      maxZIndex = this.zIndex;
    }
    this.modifications.forEach(modification => {
      if (modification.z > maxZIndex) {
        maxZIndex = modification.z;
      }
    });
    return maxZIndex;
  }

  protected createPolygons(): void {
    if (this.lineType) {
      this.lineDash = this.lineTypes[this.lineType] || [];
    }

    const showActivityInfoBox = this.sbgnFormat && this.sboTerm === ION_CHANNEL_SBO_TERM;
    const showHomodimerInfoBox = this.homodimer > 1;

    if (showHomodimerInfoBox) {
      if (showActivityInfoBox) {
        this.drawActivityInfoBox(getActivityInfoBoxOffset(true));
        this.drawHomodimerInfoBox(getHomodimerInfoBoxOffset(true));
      } else {
        if (this.activity) {
          this.drawActiveBorder(DEFAULT_HOMODIMER_SHIFT, DEFAULT_HOMODIMER_OFFSET);
          this.drawActiveBorder(0, DEFAULT_HOMODIMER_OFFSET);
        }
        this.drawHomodimerInfoBox(getHomodimerInfoBoxOffset(false));
      }
      this.drawElementPolygon(DEFAULT_HOMODIMER_SHIFT, DEFAULT_HOMODIMER_OFFSET);
      this.drawElementPolygon(0, DEFAULT_HOMODIMER_OFFSET);
    } else {
      if (showActivityInfoBox) {
        this.drawActivityInfoBox(getActivityInfoBoxOffset(false));
      } else if (this.activity) {
        this.drawActiveBorder(0, 0);
      }
      this.drawElementPolygon(0, 0);
    }
    this.drawOverlays();

    this.modifications.forEach(modification => {
      if (modification.state === null) {
        return;
      }

      const shapes = this.bioShapes[modification.sboTerm];
      if (!shapes) {
        return;
      }
      this.drawModification(modification, shapes);
    });
  }

  private drawActivityInfoBox(offset: number): void {
    const width = ACTIVITY_INFO_BOX_WIDTH;
    const height = ACTIVITY_INFO_BOX_HEIGHT;
    const x = this.x + this.width / 2 + offset;
    const y = this.y - height / 2;
    const activityInfoBoxPolygon = getBoundingBoxPolygon({
      x,
      y,
      width,
      height,
      pointToProjection: this.pointToProjection,
    });
    const activityInfoBoxStrokeStyle = getStroke({});
    const activityInfoBoxStyle = new Style({
      geometry: activityInfoBoxPolygon,
      stroke: activityInfoBoxStrokeStyle,
      fill: getFill({}),
      zIndex: this.getMaxZIndex() + 1,
      text: new Text({
        text: this.activity ? 'open' : 'closed',
        font: '12pt Arial',
        fill: getFill({ color: '#000' }),
        overflow: true,
      }),
    });
    activityInfoBoxPolygon.set('strokeStyle', activityInfoBoxStrokeStyle);
    activityInfoBoxPolygon.set('type', MAP_ELEMENT_TYPES.HOMODIMER_BOX);
    this.polygons.push(activityInfoBoxPolygon);
    this.styles.push(activityInfoBoxStyle);
  }

  private drawHomodimerInfoBox(offset: number): void {
    const width = HOMODIMER_INFO_BOX_WIDTH;
    const height = HOMODIMER_INFO_BOX_HEIGHT;
    const x = this.x + this.width / 2 + offset;
    const y = this.y - height / 2;
    const homodimerInfoBoxPolygon = getBoundingBoxPolygon({
      x,
      y,
      width,
      height,
      pointToProjection: this.pointToProjection,
    });
    const homodimerInfoBoxStrokeStyle = getStroke({});
    const homodimerInfoBoxStyle = new Style({
      geometry: homodimerInfoBoxPolygon,
      stroke: homodimerInfoBoxStrokeStyle,
      fill: getFill({}),
      zIndex: this.getMaxZIndex() + 1,
      text: new Text({
        text: `N:${this.homodimer}`,
        font: '12pt Arial',
        fill: getFill({ color: '#000' }),
        overflow: true,
      }),
    });
    homodimerInfoBoxPolygon.set('strokeStyle', homodimerInfoBoxStrokeStyle);
    homodimerInfoBoxPolygon.set('type', MAP_ELEMENT_TYPES.HOMODIMER_BOX);
    this.polygons.push(homodimerInfoBoxPolygon);
    this.styles.push(homodimerInfoBoxStyle);
  }

  drawModification(modification: Modification, shapes: Array<Shape>): void {
    shapes.forEach(shape => {
      const modificationPolygon = getShapePolygon({
        shape,
        x: modification.x,
        y: modification.y,
        width: modification.width,
        height: modification.height,
        pointToProjection: this.pointToProjection,
        mirror: modification.direction && modification.direction === 'RIGHT',
      });
      modificationPolygon.set('type', MAP_ELEMENT_TYPES.MODIFICATION);
      const modificationStrokeStyle = getStroke({ color: rgbToHex(modification.borderColor) });
      const modificationStyle = new Style({
        geometry: modificationPolygon,
        stroke: modificationStrokeStyle,
        fill: getFill({ color: rgbToHex(modification.fillColor) }),
        zIndex: modification.z,
      });
      modificationPolygon.set('strokeStyle', modificationStrokeStyle);
      this.polygons.push(modificationPolygon);
      this.styles.push(modificationStyle);
    });

    const modificationText = modification.stateAbbreviation
      ? modification.stateAbbreviation
      : modification.name;
    if (modificationText) {
      const lines = modificationText.split(/\r\n|\r|\n/).length;
      const modificationTextCoords = getTextCoords({
        x: modification.nameX,
        y: modification.nameY,
        lines,
        width: modification.nameWidth,
        height: modification.nameHeight,
        fontSize: modification.fontSize,
        verticalAlign: modification.nameVerticalAlign,
        horizontalAlign: modification.nameHorizontalAlign,
        pointToProjection: this.pointToProjection,
      });
      const modificationTextPolygon = new Polygon([
        [modificationTextCoords, modificationTextCoords],
      ]);
      modificationTextPolygon.set('type', MAP_ELEMENT_TYPES.TEXT);
      const modificationTextStyle = getTextStyle({
        text: modificationText,
        fontSize: modification.fontSize,
        color: rgbToHex(BLACK_COLOR),
        zIndex: modification.z,
        horizontalAlign: modification.nameHorizontalAlign,
      });
      modificationTextStyle.setGeometry(modificationTextPolygon);
      this.styles.push(modificationTextStyle);
      this.polygons.push(modificationTextPolygon);
    }
  }

  drawActiveBorder(homodimerShift: number, homodimerOffset: number): void {
    this.shapes.forEach(shape => {
      const activityBorderPolygon = getShapePolygon({
        shape,
        x: this.x + homodimerShift - 5,
        y: this.y + homodimerShift - 5,
        width: this.width - homodimerOffset + 10,
        height: this.height - homodimerOffset + 10,
        pointToProjection: this.pointToProjection,
      });
      activityBorderPolygon.set('type', MAP_ELEMENT_TYPES.ACTIVITY_BORDER);
      const activityBorderStyle = getStyle({
        geometry: activityBorderPolygon,
        fillColor: TRANSPARENT_COLOR,
        lineDash: [3, 5],
        zIndex: this.zIndex,
      });
      activityBorderPolygon.set(
        'strokeStyle',
        getStroke({
          lineDash: [3, 5],
        }),
      );
      this.polygons.push(activityBorderPolygon);
      this.styles.push(activityBorderStyle);
    });
  }

  drawElementPolygon(homodimerShift: number, homodimerOffset: number): void {
    this.shapes.forEach(shape => {
      const elementPolygon = getShapePolygon({
        shape,
        x: this.x + homodimerShift,
        y: this.y + homodimerShift,
        width: this.width - homodimerOffset,
        height: this.height - homodimerOffset,
        pointToProjection: this.pointToProjection,
      });
      elementPolygon.set('type', MAP_ELEMENT_TYPES.ENTITY);
      const elementStyle = getStyle({
        geometry: elementPolygon,
        borderColor: this.borderColor,
        fillColor: this.overlaysVisible ? WHITE_COLOR : this.fillColor,
        lineWidth: this.lineWidth,
        lineDash: this.lineDash,
        zIndex: this.zIndex,
      });
      const strokeStyle = getStroke({
        color: rgbToHex(this.borderColor),
        width: this.lineWidth,
        lineDash: this.lineDash,
      });
      elementPolygon.set('strokeStyle', strokeStyle);
      if (COMPLEX_SBO_TERMS.includes(this.sboTerm)) {
        this.coverStyle = new Style({
          geometry: elementPolygon,
          fill: getFill({ color: rgbToHex({ ...this.fillColor, alpha: 255 }) }),
          stroke: strokeStyle,
        });
        this.coverStrokeStyle = strokeStyle;
      }
      this.polygons.push(elementPolygon);
      this.styles.push(elementStyle);
    });
  }

  drawOverlays(): void {
    this.overlays.forEach(entity => {
      if (entity.value === Infinity) {
        return;
      }
      const { xMin, xMax } = getPolygonLatitudeCoordinates({
        width: entity.width,
        nOverlays: this.overlaysOrder.length,
        xMin: entity.x1,
        overlayIndexBasedOnOrder:
          this.overlaysOrder.find(({ id }) => id === entity.overlayId)?.index || ZERO,
      });
      const color = this.getOverlayColor(entity);
      const polygon = new Polygon([
        [
          this.pointToProjection({ x: xMin, y: entity.y1 }),
          this.pointToProjection({ x: xMax, y: entity.y1 }),
          this.pointToProjection({ x: xMax, y: entity.y2 }),
          this.pointToProjection({ x: xMin, y: entity.y2 }),
        ],
      ]);
      polygon.set('type', MAP_ELEMENT_TYPES.OVERLAY);
      const style = getStyle({
        geometry: polygon,
        borderColor: color,
        fillColor: color,
        zIndex: this.zIndex + 1,
      });
      polygon.set(
        'strokeStyle',
        getStroke({
          color,
        }),
      );
      this.overlaysPolygons.push(polygon);
      this.overlaysStyles.push(style);
    });
  }
}
