import { JSX, useEffect } from 'react';
import { IconButton } from '@/shared/IconButton';

interface LayersDrawerObjectActionsProps {
  moveToFront: () => void;
  moveToBack: () => void;
  removeObject: () => void;
  centerObject: () => void;
  editObject: () => void;
}

export const LayersDrawerObjectActions = ({
  moveToFront,
  moveToBack,
  removeObject,
  centerObject,
  editObject,
}: LayersDrawerObjectActionsProps): JSX.Element | null => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Delete') {
        removeObject();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [removeObject]);

  return (
    <div className="flex shrink-0 gap-2">
      <IconButton
        icon="center"
        className="h-auto w-auto bg-transparent p-0"
        title="Center"
        onClick={centerObject}
      />
      <IconButton
        icon="edit"
        className="h-auto w-auto bg-transparent p-0"
        title="Edit"
        onClick={editObject}
      />
      <IconButton
        icon="move-front"
        className="h-auto w-auto bg-transparent p-0"
        title="Move to front"
        onClick={moveToFront}
      />
      <IconButton
        icon="move-back"
        className="h-auto w-auto bg-transparent p-0"
        title="Move to back"
        onClick={moveToBack}
      />
      <IconButton
        icon="trash"
        className="h-auto w-auto bg-transparent p-0 "
        classNameIcon="group-hover:fill-red-700 group-active:fill-red-700 fill-red-600"
        title="Remove"
        onClick={removeObject}
      />
    </div>
  );
};
