import { Button } from '@/shared/Button';
import { Input } from '@/shared/Input';
import { Textarea } from '@/shared/Textarea';
import React from 'react';
import { OverlayGroupSelector } from '@/components/Map/Drawer/OverlaysDrawer/UserOverlayForm/OverlaySelector/OverlayGroupSelector.component';
import { useEditOverlay } from './hooks/useEditOverlay';

export const EditOverlayModal = (): React.ReactNode => {
  const {
    overlayGroups,
    description,
    name,
    group,
    handleCancelEdit,
    handleDescriptionChange,
    handleNameChange,
    handleGroupChange,
    handleRemoveOverlay,
    handleSaveEditedOverlay,
  } = useEditOverlay();

  return (
    <div className="w-full border border-t-[#E1E0E6] bg-white p-[24px]">
      <form>
        <label className="text-sm font-semibold" htmlFor="overlayName">
          Name
          <Input
            type="text"
            value={name}
            onChange={handleNameChange}
            name="overlayName"
            id="overlayName"
            className="mt-2.5 text-sm font-medium"
            data-testid="overlay-name"
          />
        </label>
        <label className="mt-5 block text-sm font-semibold" htmlFor="overlayDescription">
          Description
          <Textarea
            rows={4}
            value={description}
            onChange={handleDescriptionChange}
            name="overlayDescription"
            id="overlayDescription"
            className="mt-2.5 text-sm font-medium"
            data-testid="overlay-description"
          />
        </label>
        <OverlayGroupSelector
          value={group}
          onChange={handleGroupChange}
          items={overlayGroups}
          label="Select group"
        />
        <div className="mt-10 flex items-center justify-between gap-5 text-center">
          <Button
            type="button"
            variantStyles="ghost"
            className="flex-1 justify-center"
            onClick={handleCancelEdit}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            type="button"
            variantStyles="ghost"
            className="flex-1 justify-center"
            onClick={handleRemoveOverlay}
            data-testid="remove-button"
          >
            Remove
          </Button>

          <Button
            type="button"
            className="flex-1 justify-center"
            onClick={handleSaveEditedOverlay}
            data-testid="save-button"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
