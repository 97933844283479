/* eslint-disable no-magic-numbers */
import { ModelElement } from '@/types/models';

type AddedNames = { [key: string]: number };

type CheckboxElement = { id: string; label: string };

type CheckboxElements = CheckboxElement[];

export const getCompartmentPathwaysCheckboxElements = (
  prefix: string,
  items: ModelElement[],
): CheckboxElements => {
  const addedNames: AddedNames = {};

  const setNameToIdIfUndefined = (item: ModelElement): void => {
    if (addedNames[item.name] === undefined) {
      addedNames[item.name] = item.id;
    }
  };

  items.forEach(setNameToIdIfUndefined);

  const parseIdAndLabel = ([name, id]: [name: string, id: number]): CheckboxElement => ({
    id: `${prefix}-${id}`,
    label: name,
  });

  const sortByLabel = (a: CheckboxElement, b: CheckboxElement): number => {
    if (a.label > b.label) return 1;
    return -1;
  };

  return Object.entries(addedNames).map(parseIdAndLabel).sort(sortByLabel);
};
