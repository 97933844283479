import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '@/redux/root/root.selectors';

export const shapesSelector = createSelector(rootSelector, state => state.shapes);

export const bioShapesSelector = createSelector(
  shapesSelector,
  shapes => shapes.bioShapesState.data,
);

export const bioShapesLoadingSelector = createSelector(
  shapesSelector,
  shapes => shapes.bioShapesState.loading,
);

export const lineTypesSelector = createSelector(
  shapesSelector,
  shapes => shapes.lineTypesState.data || {},
);

export const lineTypesKeysSelector = createSelector(shapesSelector, shapes =>
  Object.keys(shapes.lineTypesState?.data || {}),
);

export const lineTypesLoadingSelector = createSelector(
  shapesSelector,
  shapes => shapes.lineTypesState.loading,
);

export const arrowTypesSelector = createSelector(
  shapesSelector,
  shapes => shapes.arrowTypesState.data || {},
);

export const arrowTypesKeysSelector = createSelector(shapesSelector, shapes =>
  Object.keys(shapes.arrowTypesState?.data || {}),
);

export const arrowTypesLoadingSelector = createSelector(
  shapesSelector,
  shapes => shapes.arrowTypesState.loading,
);
