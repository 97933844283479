/* eslint-disable no-magic-numbers */
import { Point } from '@/types/map';

export default function isPointInEllipse({
  point,
  centerX,
  centerY,
  radiusX,
  radiusY,
}: {
  point: Point;
  centerX: number;
  centerY: number;
  radiusX: number;
  radiusY: number;
}): boolean {
  return (point.x - centerX) ** 2 / radiusX ** 2 + (point.y - centerY) ** 2 / radiusY ** 2 <= 1;
}
