import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';

export default function isFeatureInPathway(
  parentPathwayId: number,
  vectorSource: VectorSource,
  feature: Feature,
): boolean {
  const pathwayId: undefined | null | number = feature.get('pathwayId');

  if (!pathwayId) {
    return false;
  }
  if (pathwayId === parentPathwayId || pathwayId === feature.get('id')) {
    return true;
  }
  const compartmentFeature = vectorSource.getFeatureById(pathwayId);
  if (!compartmentFeature) {
    return false;
  }
  return isFeatureInPathway(parentPathwayId, vectorSource, compartmentFeature);
}
