/* eslint-disable no-magic-numbers */
import { IconButton } from '@/shared/IconButton';
import { JSX } from 'react';
import { LayerDrawerLayerContextMenu } from '@/components/Map/Drawer/LayersDrawer/LayerDrawerLayerContextMenu.component';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { hasPrivilegeToWriteProjectSelector } from '@/redux/user/user.selectors';

type LayersDrawerLayerActionsProps = {
  editLayer: () => void;
  removeLayer: () => void;
  isVisible: boolean;
  isActive: boolean;
  zIndex: number;
  toggleVisibility: () => void;
  toggleActiveLayer: (value: boolean) => void;
  addImage: () => void;
  addText: () => void;
  addRect: () => void;
  addOval: () => void;
  addLine: () => void;
  moveToFront: () => void;
  moveToBack: () => void;
  moveAboveDiagram: () => void;
  moveBelowDiagram: () => void;
};

export const LayersDrawerLayerActions = ({
  editLayer,
  removeLayer,
  isVisible,
  isActive,
  zIndex,
  toggleVisibility,
  toggleActiveLayer,
  addImage,
  addText,
  addRect,
  addOval,
  addLine,
  moveToFront,
  moveToBack,
  moveAboveDiagram,
  moveBelowDiagram,
}: LayersDrawerLayerActionsProps): JSX.Element => {
  const hasPrivilegeToWriteProject = useAppSelector(hasPrivilegeToWriteProjectSelector);

  return (
    <div className="flex gap-2">
      <IconButton
        icon={isVisible ? 'eye' : 'crossed-eye'}
        title="Switch visibility"
        className="h-auto w-auto bg-transparent p-0"
        onClick={toggleVisibility}
      />
      {hasPrivilegeToWriteProject && (
        <>
          <IconButton
            icon={isActive ? 'padlock-open' : 'padlock-locked'}
            title="Lock"
            className="h-auto w-auto bg-transparent p-0"
            onClick={() => toggleActiveLayer(!isActive)}
          />
          {isActive && (
            <>
              <IconButton
                title="Move to front"
                icon="move-front"
                className="h-auto w-auto bg-transparent p-0"
                onClick={moveToFront}
              />
              <IconButton
                title="Move to back"
                icon="move-back"
                className="h-auto w-auto bg-transparent p-0"
                onClick={moveToBack}
              />
              {zIndex < 0 && (
                <IconButton
                  title="Move above the diagram"
                  icon="layer-arrow-up"
                  className="h-auto w-auto bg-transparent p-0"
                  onClick={moveAboveDiagram}
                />
              )}
              {zIndex > 0 && (
                <IconButton
                  title="Move below the diagram"
                  icon="layer-arrow-down"
                  className="h-auto w-auto bg-transparent p-0"
                  onClick={moveBelowDiagram}
                />
              )}
              <LayerDrawerLayerContextMenu
                removeLayer={removeLayer}
                editLayer={editLayer}
                addImage={addImage}
                addText={addText}
                addRect={addRect}
                addOval={addOval}
                addLine={addLine}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
