/* eslint-disable @next/next/no-img-element */
import { getCanvasIcon } from '@/components/Map/MapViewer/utils/config/getCanvasIcon';
import { PINS_COLOR_WITH_NONE } from '@/constants/canvas';
import { SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { DEFAULT_MAX_ZOOM } from '@/constants/map';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapModelIdSelector, mapOpenedMapsSelector } from '@/redux/map/map.selectors';
import { openMapAndSetActive, setActiveMap, setMapPosition } from '@/redux/map/map.slice';
import { modelsDataSelector, modelsNameMapSelector } from '@/redux/models/models.selectors';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { ModelElement, PinDetailsItem } from '@/types/models';
import { AvailableSubmaps, PinTypeWithNone } from '../PinsList.types';
import { getListOfAvailableSubmaps } from './PinsListItem.component.utils';

interface PinsListItemProps {
  name: string;
  type: PinTypeWithNone;
  pin: PinDetailsItem;
  element: ModelElement;
  number: number;
}

export const PinsListItem = ({
  name,
  type,
  pin,
  element,
  number,
}: PinsListItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const openedMaps = useAppSelector(mapOpenedMapsSelector);
  const models = useAppSelector(modelsDataSelector);
  const availableSubmaps = getListOfAvailableSubmaps(pin, models);
  const currentModelId = useAppSelector(mapModelIdSelector);
  const modelsNames = useAppSelector(modelsNameMapSelector);
  const pinIconCanvas = getCanvasIcon({
    color: PINS_COLOR_WITH_NONE[type],
    value: number,
  });

  const isMapAlreadyOpened = (modelId: number): boolean =>
    openedMaps.some(map => map.modelId === modelId);

  const onSubmapOpen = (map: AvailableSubmaps): void => {
    if (isMapAlreadyOpened(map.modelId)) {
      dispatch(setActiveMap({ modelId: map.modelId }));
    } else {
      dispatch(openMapAndSetActive({ modelId: map.modelId, modelName: map.name }));
    }
    if (currentModelId !== map.modelId) {
      PluginsEventBus.dispatchEvent('onSubmapClose', currentModelId);
      PluginsEventBus.dispatchEvent('onSubmapOpen', map.modelId);
    }
  };

  const handleCenterMapToPin = (): void => {
    if (currentModelId !== element.model) {
      onSubmapOpen({
        id: element.model,
        modelId: element.model,
        name: modelsNames[element.model],
      });
    }

    dispatch(
      setMapPosition({
        x: element.x,
        y: element.y,
        z: DEFAULT_MAX_ZOOM,
      }),
    );
  };

  return (
    <div className="mb-4 flex w-full flex-col gap-3 rounded-lg border-[1px] border-solid border-greyscale-500 p-4">
      <div className="flex w-full flex-row items-center gap-2">
        <button
          type="button"
          className="mr-2 shrink-0"
          onClick={handleCenterMapToPin}
          data-testid="center-to-pin"
        >
          <img src={pinIconCanvas.toDataURL()} alt={`${number}`} title={`${number}`} />
        </button>
        <p>
          Full name: <span className="w-full font-bold">{name}</span>
        </p>
      </div>
      <ul className="leading-6">
        <div className="font-bold">Elements:</div>
        {'targetParticipants' in pin &&
          pin.targetParticipants.map(participant => {
            return (
              // participant.id is almost always = 0
              <li
                key={`${participant.id}-${participant.type}-${participant.resource}`}
                className="my-2 px-2"
              >
                <a
                  href={participant.link ? participant.link : undefined}
                  target="_blank"
                  className="cursor-pointer text-primary-500 underline"
                >
                  {participant.type} ({participant.resource})
                </a>
              </li>
            );
          })}
      </ul>
      <ul className="leading-6">
        <div className="font-bold">References:</div>
        {pin.references.map(reference => {
          return (
            // reference.id is almost always = 0
            <li key={`${reference.id}-${reference.resource}`} className="my-2 px-2">
              <a
                href={reference.link ? reference.link : undefined}
                target="_blank"
                className="cursor-pointer text-primary-500 underline"
              >
                {reference.type} ({reference.resource})
              </a>
            </li>
          );
        })}
      </ul>
      {availableSubmaps.length > SIZE_OF_EMPTY_ARRAY && (
        <ul className="leading-6">
          <div className="mb-2 font-bold">Available in submaps:</div>
          {availableSubmaps.map(submap => {
            return (
              <button
                onClick={(): void => onSubmapOpen(submap)}
                className="mb-2 mr-2 rounded border border-solid border-greyscale-500 p-2 font-normal text-[#6A6977] hover:border-[#6A6977]"
                type="button"
                key={submap.id}
              >
                {submap.name}
              </button>
            );
          })}
        </ul>
      )}
    </div>
  );
};
