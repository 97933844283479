/* eslint-disable no-magic-numbers */
import { MapSize } from '@/redux/map/map.types';
import { Coordinate } from 'ol/coordinate';
import { Segment } from '@/types/models';
import { toLonLat } from 'ol/proj';
import { latLngToPoint } from '@/utils/map/latLngToPoint';

export default function getLineSegmentsFromCoords({
  mapSize,
  coords,
}: {
  mapSize: MapSize;
  coords: Coordinate[];
}): Array<Segment> {
  const segments: Array<Segment> = [];
  let x1: number | undefined;
  let y1: number | undefined;
  coords.forEach(pointCoords => {
    const [startLng, startLat] = toLonLat([pointCoords[0], pointCoords[1]]);
    const point = latLngToPoint([startLat, startLng], mapSize);
    if (x1 && y1) {
      if (x1 === point.x && y1 === point.y) {
        return;
      }
      segments.push({
        x1,
        y1,
        x2: point.x,
        y2: point.y,
      });
    }
    x1 = point.x;
    y1 = point.y;
  });
  return segments;
}
