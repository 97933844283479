import { DrawerHeading } from '@/shared/DrawerHeading';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { CommentItem } from '@/components/Map/Drawer/BioEntityDrawer/Comments/CommentItem.component';
import { ZERO } from '@/constants/common';
import ReactionTypeEnum from '@/utils/reaction/ReactionTypeEnum';
import React from 'react';
import { AnnotationItemList } from '@/components/Map/Drawer/BioEntityDrawer/AnnotationItem/AnnotationItemList.component';
import {
  currentDrawerNewReactionSelector,
  currentDrawerReactionCommentsSelector,
} from '@/redux/newReactions/newReactions.selectors';
import { ConnectedBioEntitiesList } from './ConnectedBioEntitiesList';

export const ReactionDrawer = (): React.ReactNode => {
  const reaction = useAppSelector(currentDrawerNewReactionSelector);
  const commentsData = useAppSelector(currentDrawerReactionCommentsSelector);

  if (!reaction) {
    return null;
  }

  const isCommentAvailable = commentsData.length > ZERO;
  type ReactionTypeKey = keyof typeof ReactionTypeEnum;
  const type = ReactionTypeEnum[reaction.sboTerm as ReactionTypeKey];

  return (
    <div className="h-full max-h-full" data-testid="reaction-drawer">
      <DrawerHeading
        title={
          <>
            <span className="font-normal">Reaction:</span>&nbsp;{reaction.idReaction}
          </>
        }
      />
      <div className="flex h-[calc(100%-93px)] max-h-[calc(100%-93px)] flex-col gap-6 overflow-y-auto p-6">
        <div className="text-sm font-normal">
          Type: <b className="font-semibold">{type}</b>
        </div>
        <hr className="border-b border-b-divide" />
        {reaction.notes && (
          <div
            className="text-sm font-normal"
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: reaction.notes }}
          />
        )}

        <AnnotationItemList references={reaction.references} />
        <hr className="border-b border-b-divide" />
        <ConnectedBioEntitiesList />
        {isCommentAvailable && <div className="font-bold"> Comments</div>}
        {isCommentAvailable &&
          commentsData.map(comment => <CommentItem key={comment.id} comment={comment} />)}
      </div>
    </div>
  );
};
