import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { MapInstance } from '@/types/map';
import { MapSize } from '@/redux/map/map.types';
import { LayerImage as LayerImageModel } from '@/types/models';
import Glyph from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/Glyph/Glyph';
import { store } from '@/redux/store';
import { updateLayerImageObject } from '@/redux/layers/layers.thunks';
import { layerUpdateImage } from '@/redux/layers/layers.slice';
import { mapEditToolsSetLayerObject } from '@/redux/mapEditTools/mapEditTools.slice';
import { BoundingBox } from '@/components/Map/MapViewer/MapViewer.types';
import { LAYER_ELEMENT_TYPES } from '@/components/Map/MapViewer/MapViewer.constants';

export type LayerImageProps = {
  elementId: number;
  glyphId: number | null;
  x: number;
  y: number;
  width: number;
  height: number;
  layer: number;
  zIndex: number;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  mapSize: MapSize;
};

export default class LayerImage extends Glyph {
  layer: number;

  constructor({
    elementId,
    glyphId,
    x,
    y,
    width,
    height,
    layer,
    zIndex,
    pointToProjection,
    mapInstance,
    mapSize,
  }: LayerImageProps) {
    super({
      elementId,
      glyphId,
      x,
      y,
      width,
      height,
      zIndex,
      pointToProjection,
      mapInstance,
      mapSize,
    });
    this.layer = layer;
    this.feature.set('getObjectData', this.getData.bind(this));
    this.feature.set('save', this.save.bind(this));
    this.feature.set('layer', layer);
    this.feature.set('elementType', LAYER_ELEMENT_TYPES.IMAGE);
  }

  private async save({
    modelId,
    boundingBox,
  }: {
    modelId: number;
    boundingBox: BoundingBox;
  }): Promise<void> {
    const { dispatch } = store;
    const layerImage = await dispatch(
      updateLayerImageObject({
        modelId,
        layerId: this.layer,
        ...this.getData(),
        ...boundingBox,
      }),
    ).unwrap();
    if (layerImage) {
      dispatch(layerUpdateImage({ modelId, layerId: layerImage.layer, layerImage }));
      dispatch(mapEditToolsSetLayerObject(layerImage));
      this.updateElement(layerImage);
    }
  }

  private getData(): LayerImageModel {
    return {
      id: this.elementId,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      glyph: this.glyphId,
      z: this.zIndex,
      layer: this.layer,
    };
  }
}
