import { EntityNumber } from '@/redux/entityNumber/entityNumber.types';
import { MultiPinModelElement } from '@/types/modelElement';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { getMultipinSingleFeature } from './getMultipinSingleFeature';

export const getMultipinFeatures = (
  multipins: MultiPinModelElement[],
  {
    pointToProjection,
    entityNumber,
    activeIds,
  }: {
    pointToProjection: UsePointToProjectionResult;
    entityNumber: EntityNumber;
    activeIds: (string | number)[];
  },
): Feature[] => {
  return multipins.map(multipin =>
    getMultipinSingleFeature(multipin, {
      pointToProjection,
      entityNumber,
      activeIds,
    }),
  );
};
