import { bioEntityIsContentTabOpenedSelector } from '@/redux/bioEntity/bioEntity.selectors';
import { toggleIsContentTabOpened } from '@/redux/bioEntity/bioEntity.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from '@/shared/Accordion';
import {
  numberOfSearchModelElementsSelector,
  searchModelElementsLoadingStatusSelector,
  searchModelElementsPerModelSelector,
} from '@/redux/modelElements/modelElements.selector';
import { BioEntitiesSubmapItem } from './BioEntitiesSubmapItem';

export const BioEntitiesAccordion = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const searchModelElementsNumber = useAppSelector(numberOfSearchModelElementsSelector);
  const searchModelElementsLoadingStatus = useAppSelector(searchModelElementsLoadingStatusSelector);
  const searchModelElementsPerModel = useAppSelector(searchModelElementsPerModelSelector);
  const isContentTabOpened = useAppSelector(bioEntityIsContentTabOpenedSelector);

  const toggleTabOpened = (): void => {
    dispatch(toggleIsContentTabOpened(!isContentTabOpened));
  };

  return (
    <AccordionItem dangerouslySetExpanded={isContentTabOpened}>
      <AccordionItemHeading>
        <AccordionItemButton onClick={toggleTabOpened}>
          Content {searchModelElementsLoadingStatus === 'pending' && ' (Loading...)'}
          {searchModelElementsLoadingStatus === 'succeeded' && ` (${searchModelElementsNumber})`}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {searchModelElementsPerModel.map(model => (
          <BioEntitiesSubmapItem
            key={model.modelName}
            mapName={model.modelName}
            mapId={model.modelId}
            numberOfModelElements={model.numberOfModelElements}
            modelElements={model.modelElements}
          />
        ))}
      </AccordionItemPanel>
    </AccordionItem>
  );
};
