/* eslint-disable no-magic-numbers */
import { MapInstance, Point } from '@/types/map';
import { Coordinate } from 'ol/coordinate';
import { FeatureLike } from 'ol/Feature';
import { FEATURE_TYPE } from '@/constants/features';
import { LAYER_TYPE } from '@/components/Map/MapViewer/MapViewer.constants';

function isFeatureFilledCompartment(feature: FeatureLike): boolean {
  return feature.get('type') === FEATURE_TYPE.COMPARTMENT && feature.get('filled');
}

function isFeatureNotCompartment(feature: FeatureLike): boolean {
  return (
    [...Object.values(FEATURE_TYPE)].includes(feature.get('type')) &&
    feature.get('type') !== FEATURE_TYPE.COMPARTMENT
  );
}

function isFeatureCompartmentBorderClicked({
  feature,
  point,
}: {
  feature: FeatureLike;
  point: Point;
}): boolean {
  const featureIsCompartmentClicked = feature.get('isCompartmentClicked');
  if (featureIsCompartmentClicked && featureIsCompartmentClicked instanceof Function) {
    return featureIsCompartmentClicked({
      point,
      hitTolerance: 5,
    });
  }
  return false;
}

export default function getFeatureAtCoordinate({
  mapInstance,
  coordinate,
  point,
  hitTolerance = 10,
}: {
  mapInstance: MapInstance;
  coordinate: Coordinate;
  point: Point;
  hitTolerance?: number;
}): FeatureLike | undefined {
  let featureAtPixel: FeatureLike | undefined;
  if (!mapInstance) {
    return featureAtPixel;
  }
  const pixel = mapInstance.getPixelFromCoordinate(coordinate);
  mapInstance.forEachFeatureAtPixel(
    pixel,
    (feature, layer) => {
      const featureZIndex = feature.get('zIndex');
      if (
        layer &&
        layer.get('type') !== LAYER_TYPE.ADDITIONAL_LAYER &&
        (isFeatureFilledCompartment(feature) ||
          isFeatureNotCompartment(feature) ||
          isFeatureCompartmentBorderClicked({ feature, point })) &&
        (featureZIndex === undefined || featureZIndex >= 0)
      ) {
        featureAtPixel = feature;
        return true;
      }
      return false;
    },
    { hitTolerance },
  );
  return featureAtPixel;
}
