import { MapInstance } from '@/types/map';
import { useMapInstance } from '@/utils/context/mapInstanceContext';
import Map from 'ol/Map';
import { Zoom } from 'ol/control';
import React, { MutableRefObject, useEffect } from 'react';
import { useOlMapLayers } from '@/components/Map/MapViewer/utils/config/useOlMapLayers';
import { defaults, MouseWheelZoom } from 'ol/interaction';
import { useOlMapView } from './config/useOlMapView';
import { useOlMapListeners } from './listeners/useOlMapListeners';

interface UseOlMapInput {
  target?: HTMLElement;
}
interface UseOlMapOutput {
  mapRef: MutableRefObject<null | HTMLDivElement>;
  mapInstance: MapInstance;
}

type UseOlMap = (input?: UseOlMapInput) => UseOlMapOutput;

export const useOlMap: UseOlMap = ({ target } = {}) => {
  const mapRef = React.useRef<null | HTMLDivElement>(null);
  const { mapInstance, handleSetMapInstance } = useMapInstance();
  const view = useOlMapView({ mapInstance });

  const mapLayers = useOlMapLayers({ mapInstance });

  useOlMapListeners({ view, mapInstance });

  useEffect(() => {
    // checking if innerHTML is empty due to possibility of target element cloning by OpenLayers map instance
    if (!mapRef.current || mapRef.current.innerHTML !== '') {
      return;
    }

    const map = new Map({
      interactions: defaults({
        mouseWheelZoom: false,
      }).extend([
        new MouseWheelZoom({
          duration: 250,
          timeout: 80,
        }),
      ]),
      target: target || mapRef.current,
    });

    // remove zoom controls as we are using our own
    map.getControls().forEach(mapControl => {
      if (mapControl instanceof Zoom) {
        map.removeControl(mapControl);
      }
    });

    handleSetMapInstance(map);
  }, [target, handleSetMapInstance]);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    mapInstance.setLayers(mapLayers);
  }, [mapInstance, mapLayers]);

  return {
    mapRef,
    mapInstance,
  };
};
