/* eslint-disable no-magic-numbers */
import Style from 'ol/style/Style';
import { Feature } from 'ol';
import { MultiPolygon } from 'ol/geom';
import { Arrow, Color } from '@/types/models';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import Polygon from 'ol/geom/Polygon';
import {
  LAYER_ELEMENT_TYPES,
  SELECT_COLOR,
  WHITE_COLOR,
} from '@/components/Map/MapViewer/MapViewer.constants';
import { ArrowTypeDict } from '@/redux/shapes/shapes.types';
import getShapePolygon from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/utils/getShapePolygon';
import getStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStyle';
import getStroke from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStroke';
import { rgbToHex } from '@/components/Map/MapViewer/utils/mapElementsRendering/style/rgbToHex';
import { Stroke } from 'ol/style';

export default function getArrowFeature({
  arrowTypes,
  arrow,
  x,
  y,
  zIndex,
  rotation,
  lineWidth,
  color,
  pointToProjection,
}: {
  arrowTypes: ArrowTypeDict;
  arrow: Arrow;
  x: number;
  y: number;
  zIndex: number;
  rotation: number;
  lineWidth: number;
  color: Color;
  pointToProjection: UsePointToProjectionResult;
}):
  | undefined
  | {
      feature: Feature<MultiPolygon>;
      styles: Array<{
        style: Style;
        strokeStyle: Stroke;
        selectStyle: Style;
        selectStrokeStyle: Stroke;
      }>;
    } {
  const arrowShapes = arrowTypes[arrow.arrowType];
  if (!arrowShapes) {
    return undefined;
  }
  const styles: Array<{
    style: Style;
    strokeStyle: Stroke;
    selectStyle: Style;
    selectStrokeStyle: Stroke;
  }> = [];
  const arrowPolygons: Array<Polygon> = [];
  arrowShapes.forEach(shape => {
    const arrowPolygon = getShapePolygon({
      shape,
      x,
      y: y - arrow.length / 2,
      width: arrow.length,
      height: arrow.length,
      pointToProjection,
    });
    const style = getStyle({
      geometry: arrowPolygon,
      zIndex,
      borderColor: color,
      fillColor: shape.fill === false ? WHITE_COLOR : color,
      lineWidth,
    });
    const strokeStyle = getStroke({
      color: rgbToHex(color),
      width: lineWidth,
    });
    const selectStyle = getStyle({
      geometry: arrowPolygon,
      zIndex: 99999,
      borderColor: SELECT_COLOR,
      fillColor: WHITE_COLOR,
      lineWidth,
    });
    const selectStrokeStyle = getStroke({
      color: rgbToHex(SELECT_COLOR),
      width: lineWidth,
    });
    arrowPolygon.rotate(rotation, pointToProjection({ x, y }));
    styles.push({
      style,
      strokeStyle,
      selectStyle,
      selectStrokeStyle,
    });
    arrowPolygons.push(arrowPolygon);
  });

  const arrowFeature = new Feature({
    geometry: new MultiPolygon(arrowPolygons),
    elementType: LAYER_ELEMENT_TYPES.ARROW,
    zIndex,
  });
  return { feature: arrowFeature, styles };
}
