import { NewReaction, Segment } from '@/types/models';

export function getReactionLineSegments(reaction: NewReaction): Segment[] {
  const result: Segment[] = [];
  result.push(...reaction.line.segments);
  reaction.reactants.forEach(reactant => {
    result.push(...reactant.line.segments);
  });
  reaction.products.forEach(product => {
    result.push(...product.line.segments);
  });
  reaction.modifiers.forEach(modifier => {
    result.push(...modifier.line.segments);
  });
  reaction.operators.forEach(operator => {
    result.push(...operator.line.segments);
  });
  return result;
}
