/* eslint-disable no-magic-numbers */
import Draw from 'ol/interaction/Draw';
import { LineString } from 'ol/geom';
import { MapSize } from '@/redux/map/map.types';
import { AppDispatch } from '@/redux/store';
import { openLayerLineFactoryModal } from '@/redux/modal/modal.slice';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import getLineSegmentsFromCoords from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/getLineSegmentsFromCoords';
import { never } from 'ol/events/condition';

export default function getDrawLineInteraction({
  mapSize,
  dispatch,
  restrictionExtent,
}: {
  mapSize: MapSize;
  dispatch: AppDispatch;
  restrictionExtent: Extent;
}): Draw {
  const drawLineInteraction = new Draw({
    type: 'LineString',
    freehand: false,
    condition: (mapBrowserEvent): boolean => {
      const coords = mapBrowserEvent.coordinate;
      return (
        coords[0] >= restrictionExtent[0] &&
        coords[0] <= restrictionExtent[2] &&
        coords[1] >= restrictionExtent[1] &&
        coords[1] <= restrictionExtent[3]
      );
    },
    freehandCondition: never,
    geometryFunction: (coordinates, geom): SimpleGeometry => {
      let geometry = geom;
      if (!geom) {
        geometry = new LineString([]);
      }
      if (!coordinates.length) {
        geometry.setCoordinates(coordinates);
        return geometry;
      }
      const lastCoordinate = coordinates.at(-1) as Coordinate;
      const lastCoordinateX = Math.min(
        restrictionExtent[2],
        Math.max(restrictionExtent[0], lastCoordinate[0]),
      );
      const lastCoordinateY = Math.min(
        restrictionExtent[3],
        Math.max(restrictionExtent[1], lastCoordinate[1]),
      );
      coordinates.splice(coordinates.length - 1, 1, [lastCoordinateX, lastCoordinateY]);
      geometry.setCoordinates(coordinates);
      return geometry;
    },
  });

  drawLineInteraction.on('drawend', event => {
    const coords = (event.feature.getGeometry() as LineString).getCoordinates();
    const segments = getLineSegmentsFromCoords({ mapSize, coords });
    dispatch(openLayerLineFactoryModal(segments));
  });

  return drawLineInteraction;
}
