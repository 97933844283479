import { OverlayBioEntityRender } from '@/types/OLrendering';
import { GetOverlayBioEntityColorByAvailableProperties } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useGetOverlayColor';
import groupOverlayEntities from '@/components/Map/MapViewer/utils/mapElementsRendering/overlay/groupOverlayEntities';
import processOverlayGroupedElements from '@/components/Map/MapViewer/utils/mapElementsRendering/overlay/processOverlayGroupedElements';

export default function getOverlays(
  groupedOverlays: Record<string, Array<OverlayBioEntityRender>>,
  getColor: GetOverlayBioEntityColorByAvailableProperties,
): Record<string, Array<OverlayBioEntityRender>> {
  const resultEntityOverlays: Record<string, Array<OverlayBioEntityRender>> = {};

  Object.entries(groupedOverlays).forEach(([key, overlayBioEntities]) => {
    const entityOverlays: Array<OverlayBioEntityRender> = [];
    if (!resultEntityOverlays[key]) {
      resultEntityOverlays[key] = [];
    }

    const groupedElements = groupOverlayEntities(overlayBioEntities);

    processOverlayGroupedElements(groupedElements, entityOverlays, getColor);

    resultEntityOverlays[key].push(...entityOverlays);
  });

  return resultEntityOverlays;
}
