import { useOpenSubmap } from '@/hooks/useOpenSubmaps';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { Button } from '@/shared/Button';
import { currentDrawerModelElementRelatedSubmapSelector } from '@/redux/modelElements/modelElements.selector';

export const AssociatedSubmap = (): React.ReactNode => {
  const relatedSubmap = useAppSelector(currentDrawerModelElementRelatedSubmapSelector);
  const { openSubmap } = useOpenSubmap({
    modelId: relatedSubmap?.id,
    modelName: relatedSubmap?.name,
  });

  if (!relatedSubmap) {
    return null;
  }

  return (
    <div
      data-testid="associated-submap"
      className="flex flex-row flex-nowrap items-center justify-between"
    >
      <p>Associated Submap: {relatedSubmap.name}</p>
      <Button className="max-h-8" variantStyles="ghost" onClick={openSubmap}>
        Open submap
      </Button>
    </div>
  );
};
