/* eslint-disable no-magic-numbers */
import getCoordsX from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getCoordsX';
import getCoordsY from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getCoordsY';
import { Coordinate } from 'ol/coordinate';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { ShapeRelAbs } from '@/types/models';

export default function getEllipseCoords({
  x,
  y,
  center,
  radius,
  height,
  width,
  pointToProjection,
  points = 30,
}: {
  x: number;
  y: number;
  center?: ShapeRelAbs;
  radius?: ShapeRelAbs;
  height: number;
  width: number;
  pointToProjection?: UsePointToProjectionResult;
  points?: number;
}): Array<Coordinate> {
  let centerX = x;
  let centerY = y;
  let radiusX = width / 2;
  let radiusY = height / 2;
  if (center) {
    centerX = getCoordsX(
      x,
      center.absoluteX,
      center.relativeX,
      center.relativeHeightForX,
      height,
      width,
    );
    centerY = getCoordsY(
      y,
      center.absoluteY,
      center.relativeY,
      center.relativeWidthForY,
      height,
      width,
    );
  }
  if (radius) {
    radiusX =
      radius.absoluteX +
      (radius.relativeX * width) / 100 +
      (height * (radius.relativeHeightForX || 0)) / 100;
    radiusY =
      radius.absoluteY +
      (radius.relativeY * height) / 100 +
      (width * (radius.relativeWidthForY || 0)) / 100;
  }
  let angle;
  let coordsX;
  let coordsY;
  const coordinates: Array<Coordinate> = [];

  for (let i = 0; i < points; i += 1) {
    angle = (i / points) * 2 * Math.PI;
    coordsX = centerX + radiusX * Math.cos(angle);
    coordsY = centerY + radiusY * Math.sin(angle);
    if (pointToProjection) {
      coordinates.push(pointToProjection({ x: coordsX, y: coordsY }));
    } else {
      coordinates.push([coordsX, coordsY]);
    }
  }
  coordinates.push(coordinates[0]);

  return coordinates;
}
