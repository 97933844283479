import { LoadingIndicator } from '@/shared/LoadingIndicator';
import './MapLoader.styles.css';
import { isDrawerOpenSelector } from '@/redux/drawer/drawer.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { modelLoadingSelector } from '@/redux/selectors';

export const MapLoader = (): JSX.Element => {
  const isModelLoading = useAppSelector(modelLoadingSelector);
  const isDrawerOpen = useAppSelector(isDrawerOpenSelector);

  return (
    <div className={`map-loader transition-all duration-500 ${isDrawerOpen ? 'move-right' : ''}`}>
      {isModelLoading && <LoadingIndicator width={48} height={48} />}
    </div>
  );
};
