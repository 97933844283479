import { BASE_MAP_IMAGES_URL } from '@/constants';
import {
  currentOverviewImageSelector,
  projectDirectorySelector,
} from '@/redux/project/project.selectors';
import { useSelector } from 'react-redux';

export const useOverviewImageUrl = (): string => {
  const currentImage = useSelector(currentOverviewImageSelector);
  const directory = useSelector(projectDirectorySelector);
  if (!currentImage || !directory) {
    return '';
  }

  return `${BASE_MAP_IMAGES_URL}/map_images/${directory}/${currentImage.filename}`;
};
