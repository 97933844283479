/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import './LayerLineFactoryModal.styles.css';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { Button } from '@/shared/Button';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { Color } from '@/types/models';
import { showToast } from '@/utils/showToast';
import { closeModal } from '@/redux/modal/modal.slice';
import { mapEditToolsSetLayerLine } from '@/redux/mapEditTools/mapEditTools.slice';
import { SerializedError } from '@reduxjs/toolkit';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useMapInstance } from '@/utils/context/mapInstanceContext';
import { mapEditToolsLayerLineSelector } from '@/redux/mapEditTools/mapEditTools.selectors';
import updateElement from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/updateElement';
import { updateLayerLine } from '@/redux/layers/layers.thunks';
import { layerUpdateLine } from '@/redux/layers/layers.slice';
import {
  LayerLineFactoryForm,
  LayerLineFactoryPayload,
} from '@/components/FunctionalArea/Modal/LayerLineFactoryModal/LayerLineFactory.types';
import {
  DEFAULT_ARROW_ANGLE,
  DEFAULT_ARROW_LENGTH,
  DEFAULT_ARROW_LINE_TYPE,
} from '@/components/FunctionalArea/Modal/LayerLineFactoryModal/LayerLineFactory.constants';
import { LayerLineForm } from '@/components/FunctionalArea/Modal/LayerLineFactoryModal/LayerLineForm.component';
import { arrowTypesKeysSelector, lineTypesKeysSelector } from '@/redux/shapes/shapes.selectors';

export const LayerLineEditFactoryModal: React.FC = () => {
  const layerLine = useAppSelector(mapEditToolsLayerLineSelector);
  const currentModelId = useAppSelector(currentModelIdSelector);
  const dispatch = useAppDispatch();
  const { mapInstance } = useMapInstance();
  const lineTypes = useAppSelector(lineTypesKeysSelector).map(lineType => ({
    id: lineType,
    name: lineType,
  }));
  const arrowTypes = useAppSelector(arrowTypesKeysSelector).map(arrowType => ({
    id: arrowType,
    name: arrowType,
  }));

  if (!layerLine) {
    throw new Error('No layer line object');
  }

  const [isSending, setIsSending] = useState<boolean>(false);
  const [data, setData] = useState<LayerLineFactoryForm>({
    color: layerLine.color,
    lineType: layerLine.lineType,
    width: layerLine.width,
    startArrow: layerLine.startArrow.arrowType,
    startArrowScale: +(layerLine.startArrow.length / DEFAULT_ARROW_LENGTH).toFixed(2),
    endArrow: layerLine.endArrow.arrowType,
    endArrowScale: +(layerLine.endArrow.length / DEFAULT_ARROW_LENGTH).toFixed(2),
  });

  const getDataToSend = (): LayerLineFactoryPayload => {
    return {
      color: data.color,
      lineType: data.lineType,
      width: data.width,
      startArrow: {
        arrowType: data.startArrow,
        angle: DEFAULT_ARROW_ANGLE,
        lineType: DEFAULT_ARROW_LINE_TYPE,
        length: DEFAULT_ARROW_LENGTH * data.startArrowScale,
      },
      endArrow: {
        arrowType: data.endArrow,
        angle: DEFAULT_ARROW_ANGLE,
        lineType: DEFAULT_ARROW_LINE_TYPE,
        length: DEFAULT_ARROW_LENGTH * data.endArrowScale,
      },
      segments: layerLine.segments,
      z: layerLine.z,
    };
  };

  const handleSubmit = async (): Promise<void> => {
    if (!layerLine) {
      return;
    }
    try {
      const updatedLayerLine = await dispatch(
        updateLayerLine({
          modelId: currentModelId,
          layerId: layerLine.layer,
          lineId: layerLine.id,
          payload: getDataToSend(),
        }),
      ).unwrap();

      if (!updatedLayerLine) {
        showToast({
          type: 'error',
          message: 'An error occurred while editing the line.',
        });
        return;
      }

      dispatch(
        layerUpdateLine({
          modelId: currentModelId,
          layerId: updatedLayerLine.layer,
          layerLine: updatedLayerLine,
        }),
      );
      dispatch(mapEditToolsSetLayerLine(updatedLayerLine));
      updateElement(mapInstance, updatedLayerLine.layer, updatedLayerLine);
      showToast({
        type: 'success',
        message: 'The line has been successfully updated.',
      });
      dispatch(closeModal());
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while editing the line.',
      });
    } finally {
      setIsSending(false);
    }
  };

  const changeValues = (value: string | number | Color, key: string): void => {
    setData(prevData => ({ ...prevData, [key]: value }));
  };

  return (
    <div className="relative flex w-[550px] flex-col gap-4 rounded-b-lg border border-t-[#E1E0E6] bg-white p-[24px]">
      {isSending && (
        <div className="c-layer-line-factory-modal-loader">
          <LoadingIndicator width={44} height={44} />
        </div>
      )}
      <LayerLineForm
        onChange={changeValues}
        data={data}
        lineTypes={lineTypes}
        arrowTypes={arrowTypes}
      />
      <hr />
      <Button
        type="button"
        onClick={handleSubmit}
        className="justify-center self-end justify-self-end text-base font-medium"
      >
        Submit
      </Button>
    </div>
  );
};
