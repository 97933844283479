import { SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { rootSelector } from '@/redux/root/root.selectors';
import { ElementId, ElementIdTabObj, Tab } from '@/types/elements';
import { MultiSearchData } from '@/types/fetchDataState';
import { Drug, ModelElement } from '@/types/models';
import { createSelector } from '@reduxjs/toolkit';
import { currentSelectedSearchElement } from '../drawer/drawer.selectors';
import { currentModelIdSelector } from '../models/models.selectors';

export const drugsSelector = createSelector(rootSelector, state => state.drugs);

export const drugsDataSelector = createSelector(drugsSelector, drugs => drugs.data);

export const drugsDataListSelector = createSelector(drugsDataSelector, drugsData =>
  drugsData.map(d => d.data || []).flat(),
);

export const drugsForSelectedSearchElementSelector = createSelector(
  drugsSelector,
  currentSelectedSearchElement,
  (drugsState, currentSearchElement): MultiSearchData<Drug[]> | undefined =>
    drugsState.data.find(({ searchQueryElement }) => searchQueryElement === currentSearchElement),
);

export const loadingDrugsStatusSelector = createSelector(
  drugsForSelectedSearchElementSelector,
  state => state?.loading,
);

export const numberOfDrugsSelector = createSelector(
  drugsForSelectedSearchElementSelector,
  state => {
    if (!state || !state?.data) {
      return SIZE_OF_EMPTY_ARRAY;
    }

    return state.data.length && state?.data.map(e => e.targets.length)?.reduce((a, b) => a + b);
  },
);

export const drugsElementsForSelectedSearchElementSelector = createSelector(
  drugsSelector,
  currentSelectedSearchElement,
  (drugsState, currentSearchElement): ModelElement[] => {
    return (drugsState?.data || [])
      .filter(({ searchQueryElement }) =>
        currentSearchElement ? searchQueryElement === currentSearchElement : true,
      )
      .map(({ data }) => data || [])
      .flat()
      .map(({ targets }) => targets.map(({ targetElements }) => targetElements))
      .flat()
      .flat();
  },
);

export const searchedDrugsElementsOfCurrentMapSelector = createSelector(
  drugsElementsForSelectedSearchElementSelector,
  currentModelIdSelector,
  (drugsElements, currentModelId): ModelElement[] => {
    return (drugsElements || []).filter(element => element.model === currentModelId);
  },
);

export const allDrugsElementsOfCurrentMapSelector = createSelector(
  drugsSelector,
  currentModelIdSelector,
  (drugsState, currentModelId): ModelElement[] => {
    return (drugsState?.data || [])
      .map(({ data }) => data || [])
      .flat()
      .map(({ targets }) => targets.map(({ targetElements }) => targetElements))
      .flat()
      .flat()
      .filter(element => element.model === currentModelId);
  },
);

export const allDrugsIdTabSelectorOfCurrentMap = createSelector(
  drugsSelector,
  currentModelIdSelector,
  (drugsState, currentModelId): ElementIdTabObj => {
    if (!drugsState) {
      return {};
    }

    return Object.fromEntries(
      (drugsState?.data || [])
        .map(({ data, searchQueryElement }): [typeof data, string] => [data, searchQueryElement])
        .map(([data, tab]) =>
          (data || []).map(({ targets }): [ElementId, Tab][] =>
            targets
              .map(({ targetElements }) => targetElements)
              .flat()
              .flat()
              .filter(element => element.model === currentModelId)
              .map(element => [element.id, tab]),
          ),
        )
        .flat()
        .flat(),
    );
  },
);
