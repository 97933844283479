import { DrawerHeadingBackwardButton } from '@/shared/DrawerHeadingBackwardButton';
import { Input } from '@/shared/Input';
import { Button } from '@/shared/Button';
import { openOverlaysDrawer } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useOverlayGroupForm } from '@/components/Map/Drawer/OverlayGroupDrawer/hooks/useOverlayGroupForm';

export const OverlayGroupDrawer = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigateToOverlays = (): void => {
    dispatch(openOverlaysDrawer());
  };

  const { name, handleChangeName, handleSubmit } = useOverlayGroupForm();

  return (
    <>
      <DrawerHeadingBackwardButton backwardFunction={navigateToOverlays}>
        Add overlay group
      </DrawerHeadingBackwardButton>
      <form className="flex h-[calc(100%-93px)] max-h-[calc(100%-93px)] flex-col overflow-y-auto p-6">
        <label className="mb-2.5 text-sm" htmlFor="name">
          Name
          <Input
            type="text"
            name="name"
            id="name"
            data-testid="overlay-name"
            value={name}
            onChange={handleChangeName}
            placeholder="Fancy group name"
            sizeVariant="medium"
            className="mt-2.5 text-xs"
          />
        </label>

        <Button
          className="mt-2.5 items-center justify-center self-start"
          onClick={handleSubmit}
          aria-label="add overlay"
        >
          Add
        </Button>
      </form>
    </>
  );
};
