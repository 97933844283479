import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapBackgroundSelector } from '@/redux/map/map.selectors';
import { MAP_BACKGROUND_TYPES } from '@/redux/map/map.constants';
import { setMapBackground } from '@/redux/map/map.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import './MapBackgroundSwitch.styles.css';
import { JSX } from 'react';

export const MapBackgroundSwitchComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const backgroundId = useAppSelector(mapBackgroundSelector);

  const handleChange = (selectedBackgroundId: string | number): void => {
    dispatch(setMapBackground(+selectedBackgroundId));
  };

  return (
    <div role="group" className="c-map-background-switch">
      {MAP_BACKGROUND_TYPES.map(background => (
        <button
          className={`${
            background.id === backgroundId && 'c-map-background-switch__button--active'
          } c-map-background-switch__button`}
          type="button"
          key={background.id}
          onClick={() => handleChange(background.id)}
        >
          {background.name}
        </button>
      ))}
    </div>
  );
};
