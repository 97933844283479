import { store } from '@/redux/store';
import { getOverlayBioEntityForAllModels } from '@/redux/overlayBioEntity/overlayBioEntity.thunk';
import { isOverlayActiveSelector } from '@/redux/overlayBioEntity/overlayBioEntity.selector';
import { overlaySelector, userOverlaySelector } from '@/redux/overlays/overlays.selectors';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import {
  ERROR_OVERLAY_ID_ALREADY_ACTIVE,
  ERROR_OVERLAY_ID_NOT_FOUND,
} from '@/services/pluginsManager/errorMessages';
import { setMapBackground } from '@/redux/map/map.slice';
import MapBackgroundsEnum from '@/redux/map/map.enums';

export const showDataOverlay = (overlayId: number, setBackgroundEmpty?: boolean): void => {
  const { dispatch, getState } = store;
  const state = getState();
  const isOverlayActive = isOverlayActiveSelector(state, overlayId);
  const overlay = overlaySelector(state, overlayId);
  const userOverlay = userOverlaySelector(state, overlayId);

  const matchingOverlay = overlay || userOverlay;

  if (!matchingOverlay) throw new Error(ERROR_OVERLAY_ID_NOT_FOUND);

  if (isOverlayActive) {
    throw new Error(ERROR_OVERLAY_ID_ALREADY_ACTIVE);
  }

  if (setBackgroundEmpty) {
    dispatch(setMapBackground(MapBackgroundsEnum.SEMANTIC));
  }

  dispatch(getOverlayBioEntityForAllModels({ overlayId }));
  PluginsEventBus.dispatchEvent('onShowOverlay', matchingOverlay);
};
