/* eslint-disable no-magic-numbers */
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  layerByIdSelector,
  maxObjectZIndexForLayerSelector,
  minObjectZIndexForLayerSelector,
} from '@/redux/layers/layers.selectors';
import { JSX, useState } from 'react';
import { LayersDrawerImageItem } from '@/components/Map/Drawer/LayersDrawer/LayersDrawerImageItem.component';
import { LayersDrawerTextItem } from '@/components/Map/Drawer/LayersDrawer/LayerDrawerTextItem.component';
import QuestionModal from '@/components/FunctionalArea/Modal/QuestionModal/QustionModal.component';
import {
  removeLayerImage,
  removeLayerLine,
  removeLayerOval,
  removeLayerRect,
  removeLayerText,
  updateLayerImageObject,
  updateLayerLine,
  updateLayerOval,
  updateLayerRect,
  updateLayerText,
} from '@/redux/layers/layers.thunks';
import {
  layerDeleteImage,
  layerDeleteLine,
  layerDeleteOval,
  layerDeleteRect,
  layerDeleteText,
  layerUpdateImage,
  layerUpdateLine,
  layerUpdateOval,
  layerUpdateRect,
  layerUpdateText,
} from '@/redux/layers/layers.slice';
import removeElementFromLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/removeElementFromLayer';
import { showToast } from '@/utils/showToast';
import { SerializedError } from '@reduxjs/toolkit';
import { LayerImage, LayerLine, LayerOval, LayerRect, LayerText } from '@/types/models';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useMapInstance } from '@/utils/context/mapInstanceContext';
import { mapModelIdSelector } from '@/redux/map/map.selectors';
import {
  mapEditToolsSetLayerLine,
  mapEditToolsSetLayerObject,
} from '@/redux/mapEditTools/mapEditTools.slice';
import updateElement from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/updateElement';
import { useSetBounds } from '@/utils/map/useSetBounds';
import { mapEditToolsLayerObjectSelector } from '@/redux/mapEditTools/mapEditTools.selectors';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import { Coordinate } from 'ol/coordinate';
import {
  openLayerImageObjectEditFactoryModal,
  openLayerLineEditFactoryModal,
  openLayerOvalEditFactoryModal,
  openLayerRectEditFactoryModal,
  openLayerTextEditFactoryModal,
} from '@/redux/modal/modal.slice';
import { LayersDrawerRectItem } from '@/components/Map/Drawer/LayersDrawer/LayerDrawerRectItem.component';
import { LayersDrawerOvalItem } from '@/components/Map/Drawer/LayersDrawer/LayersDrawerOvalItem.component';
import { LayersDrawerLineItem } from '@/components/Map/Drawer/LayersDrawer/LayersDrawerLineItem.component';
import { LayerLineEditFactoryPayload } from '@/components/FunctionalArea/Modal/LayerLineFactoryModal/LayerLineFactory.types';
import getLayerLineBoundingBoxCoords from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/getLayerLineBoundingBoxCoords';

interface LayersDrawerObjectsListProps {
  layerId: number;
  isLayerVisible: boolean;
  isLayerActive: boolean;
}

const removeObjectConfig = {
  image: {
    question: 'Are you sure you want to remove the image?',
    successMessage: 'The layer image has been successfully removed.',
    errorMessage: 'An error occurred while removing the layer text.',
  },
  text: {
    question: 'Are you sure you want to remove the text?',
    successMessage: 'The layer text has been successfully removed.',
    errorMessage: 'An error occurred while removing the layer text.',
  },
  rect: {
    question: 'Are you sure you want to remove the rectangle?',
    successMessage: 'The layer rectangle has been successfully removed.',
    errorMessage: 'An error occurred while removing the layer rectangle.',
  },
  oval: {
    question: 'Are you sure you want to remove the oval?',
    successMessage: 'The layer oval has been successfully removed.',
    errorMessage: 'An error occurred while removing the layer oval.',
  },
  line: {
    question: 'Are you sure you want to remove the line?',
    successMessage: 'The layer line has been successfully removed.',
    errorMessage: 'An error occurred while removing the layer line.',
  },
};

export const LayersDrawerObjectsList = ({
  layerId,
  isLayerVisible,
  isLayerActive,
}: LayersDrawerObjectsListProps): JSX.Element | null => {
  const currentModelId = useAppSelector(mapModelIdSelector);
  const maxZIndex = useAppSelector(state => maxObjectZIndexForLayerSelector(state, layerId));
  const minZIndex = useAppSelector(state => minObjectZIndexForLayerSelector(state, layerId));
  const layer = useAppSelector(state => layerByIdSelector(state, layerId));
  const mapEditToolsLayerObject = useAppSelector(mapEditToolsLayerObjectSelector);
  const [removeModalState, setRemoveModalState] = useState<
    undefined | 'text' | 'image' | 'rect' | 'oval' | 'line'
  >(undefined);
  const [layerObjectToRemove, setLayerObjectToRemove] = useState<
    LayerImage | LayerText | LayerRect | LayerOval | LayerLine | null
  >(null);
  const dispatch = useAppDispatch();
  const setBounds = useSetBounds();
  const pointToProjection = usePointToProjection();
  const { mapInstance } = useMapInstance();

  const removeObject = (
    layerObject: LayerImage | LayerText | LayerRect | LayerOval | LayerLine,
  ): void => {
    setLayerObjectToRemove(layerObject);
    if ('glyph' in layerObject) {
      setRemoveModalState('image');
    } else if ('notes' in layerObject) {
      setRemoveModalState('text');
    } else if ('fillColor' in layerObject && 'borderColor' in layerObject) {
      setRemoveModalState('rect');
    } else if ('segments' in layerObject) {
      setRemoveModalState('line');
    } else {
      setRemoveModalState('oval');
    }
  };

  const rejectRemove = (): void => {
    setRemoveModalState(undefined);
  };

  const confirmRemove = async (): Promise<void> => {
    if (!layerObjectToRemove || !removeModalState) {
      return;
    }

    try {
      if (removeModalState === 'text') {
        await dispatch(
          removeLayerText({
            modelId: currentModelId,
            layerId: layerObjectToRemove.layer,
            textId: layerObjectToRemove.id,
          }),
        ).unwrap();
        dispatch(
          layerDeleteText({
            modelId: currentModelId,
            layerId: layerObjectToRemove.layer,
            textId: layerObjectToRemove.id,
          }),
        );
      } else if (removeModalState === 'image') {
        await dispatch(
          removeLayerImage({
            modelId: currentModelId,
            layerId: layerObjectToRemove.layer,
            imageId: layerObjectToRemove.id,
          }),
        ).unwrap();
        dispatch(
          layerDeleteImage({
            modelId: currentModelId,
            layerId: layerObjectToRemove.layer,
            imageId: layerObjectToRemove.id,
          }),
        );
      } else if (removeModalState === 'rect') {
        await dispatch(
          removeLayerRect({
            modelId: currentModelId,
            layerId: layerObjectToRemove.layer,
            rectId: layerObjectToRemove.id,
          }),
        ).unwrap();
        dispatch(
          layerDeleteRect({
            modelId: currentModelId,
            layerId: layerObjectToRemove.layer,
            rectId: layerObjectToRemove.id,
          }),
        );
      } else if (removeModalState === 'oval') {
        await dispatch(
          removeLayerOval({
            modelId: currentModelId,
            layerId: layerObjectToRemove.layer,
            ovalId: layerObjectToRemove.id,
          }),
        ).unwrap();
        dispatch(
          layerDeleteOval({
            modelId: currentModelId,
            layerId: layerObjectToRemove.layer,
            ovalId: layerObjectToRemove.id,
          }),
        );
      } else {
        await dispatch(
          removeLayerLine({
            modelId: currentModelId,
            layerId: layerObjectToRemove.layer,
            lineId: layerObjectToRemove.id,
          }),
        ).unwrap();
        dispatch(
          layerDeleteLine({
            modelId: currentModelId,
            layerId: layerObjectToRemove.layer,
            lineId: layerObjectToRemove.id,
          }),
        );
      }
      removeElementFromLayer({
        mapInstance,
        layerId: layerObjectToRemove.layer,
        featureId: layerObjectToRemove.id,
      });
      showToast({
        type: 'success',
        message: removeObjectConfig[removeModalState].successMessage,
      });
      setRemoveModalState(undefined);
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || removeObjectConfig[removeModalState].errorMessage,
      });
    }
  };

  const updateImageZIndex = async ({
    zIndex,
    layerImage,
  }: {
    zIndex: number;
    layerImage: LayerImage;
  }): Promise<void> => {
    const newLayerImage = await dispatch(
      updateLayerImageObject({
        modelId: currentModelId,
        layerId: layerImage.layer,
        ...layerImage,
        z: zIndex,
      }),
    ).unwrap();
    if (newLayerImage) {
      dispatch(
        layerUpdateImage({
          modelId: currentModelId,
          layerId: newLayerImage.layer,
          layerImage: newLayerImage,
        }),
      );
      dispatch(mapEditToolsSetLayerObject(newLayerImage));
      updateElement(mapInstance, newLayerImage.layer, newLayerImage);
    }
  };

  const moveImageToFront = async (layerImage: LayerImage): Promise<void> => {
    await updateImageZIndex({ zIndex: maxZIndex + 1, layerImage });
  };

  const moveImageToBack = async (layerImage: LayerImage): Promise<void> => {
    await updateImageZIndex({ zIndex: minZIndex - 1, layerImage });
  };

  const updateTextZIndex = async ({
    zIndex,
    layerText,
  }: {
    zIndex: number;
    layerText: LayerText;
  }): Promise<void> => {
    const newLayerText = await dispatch(
      updateLayerText({
        modelId: currentModelId,
        layerId: layerText.layer,
        ...layerText,
        z: zIndex,
      }),
    ).unwrap();
    if (newLayerText) {
      dispatch(
        layerUpdateText({
          modelId: currentModelId,
          layerId: newLayerText.layer,
          layerText: newLayerText,
        }),
      );
      dispatch(mapEditToolsSetLayerObject(newLayerText));
      updateElement(mapInstance, newLayerText.layer, newLayerText);
    }
  };

  const moveTextToFront = async (layerText: LayerText): Promise<void> => {
    await updateTextZIndex({ zIndex: maxZIndex + 1, layerText });
  };

  const moveTextToBack = async (layerText: LayerText): Promise<void> => {
    await updateTextZIndex({ zIndex: minZIndex - 1, layerText });
  };

  const updateRectZIndex = async ({
    zIndex,
    layerRect,
  }: {
    zIndex: number;
    layerRect: LayerRect;
  }): Promise<void> => {
    const newLayerRect = await dispatch(
      updateLayerRect({
        modelId: currentModelId,
        layerId: layerRect.layer,
        id: layerRect.id,
        x: layerRect.x,
        y: layerRect.y,
        z: zIndex,
        width: layerRect.width,
        height: layerRect.height,
        fillColor: layerRect.fillColor,
        borderColor: layerRect.borderColor,
      }),
    ).unwrap();
    if (newLayerRect) {
      dispatch(
        layerUpdateRect({
          modelId: currentModelId,
          layerId: newLayerRect.layer,
          layerRect: newLayerRect,
        }),
      );
      dispatch(mapEditToolsSetLayerObject(newLayerRect));
      updateElement(mapInstance, newLayerRect.layer, newLayerRect);
    }
  };

  const moveRectToFront = async (layerRect: LayerRect): Promise<void> => {
    await updateRectZIndex({ zIndex: maxZIndex + 1, layerRect });
  };

  const moveRectToBack = async (layerRect: LayerRect): Promise<void> => {
    await updateRectZIndex({ zIndex: minZIndex - 1, layerRect });
  };

  const updateOvalZIndex = async ({
    zIndex,
    layerOval,
  }: {
    zIndex: number;
    layerOval: LayerOval;
  }): Promise<void> => {
    const newLayerOval = await dispatch(
      updateLayerOval({
        modelId: currentModelId,
        layerId: layerOval.layer,
        id: layerOval.id,
        x: layerOval.x,
        y: layerOval.y,
        z: zIndex,
        width: layerOval.width,
        height: layerOval.height,
        color: layerOval.color,
      }),
    ).unwrap();
    if (newLayerOval) {
      dispatch(
        layerUpdateOval({
          modelId: currentModelId,
          layerId: newLayerOval.layer,
          layerOval: newLayerOval,
        }),
      );
      dispatch(mapEditToolsSetLayerObject(newLayerOval));
      updateElement(mapInstance, newLayerOval.layer, newLayerOval);
    }
  };

  const moveOvalToFront = async (layerOval: LayerOval): Promise<void> => {
    await updateOvalZIndex({ zIndex: maxZIndex + 1, layerOval });
  };

  const moveOvalToBack = async (layerOval: LayerOval): Promise<void> => {
    await updateOvalZIndex({ zIndex: minZIndex - 1, layerOval });
  };

  const updateLineZIndex = async ({
    zIndex,
    layerLine,
  }: {
    zIndex: number;
    layerLine: LayerLine;
  }): Promise<void> => {
    const payload = {
      color: layerLine.color,
      lineType: layerLine.lineType,
      width: layerLine.width,
      startArrow: layerLine.startArrow,
      endArrow: layerLine.endArrow,
      segments: layerLine.segments,
      z: zIndex,
    } as LayerLineEditFactoryPayload;
    const newLayerLine = await dispatch(
      updateLayerLine({
        modelId: currentModelId,
        layerId: layerLine.layer,
        lineId: layerLine.id,
        payload,
      }),
    ).unwrap();
    if (newLayerLine) {
      dispatch(
        layerUpdateLine({
          modelId: currentModelId,
          layerId: newLayerLine.layer,
          layerLine: newLayerLine,
        }),
      );
      dispatch(mapEditToolsSetLayerLine(newLayerLine));
      updateElement(mapInstance, newLayerLine.layer, newLayerLine);
    }
  };

  const moveLineToFront = async (layerLine: LayerLine): Promise<void> => {
    await updateLineZIndex({ zIndex: maxZIndex + 1, layerLine });
  };

  const moveLineToBack = async (layerLine: LayerLine): Promise<void> => {
    await updateLineZIndex({ zIndex: minZIndex - 1, layerLine });
  };

  const centerObject = (layerObject: LayerImage | LayerText | LayerRect | LayerOval): void => {
    if (mapEditToolsLayerObject && mapEditToolsLayerObject.id === layerObject.id) {
      const point1 = pointToProjection({ x: layerObject.x, y: layerObject.y });
      const point2 = pointToProjection({
        x: layerObject.x + layerObject.width,
        y: layerObject.y + layerObject.height,
      });
      setBounds([point1, point2] as Coordinate[]);
    }
  };

  const centerLayerLineObject = (layerLine: LayerLine): void => {
    if (mapEditToolsLayerObject && mapEditToolsLayerObject.id === layerLine.id) {
      const coordinates = getLayerLineBoundingBoxCoords({
        segments: layerLine.segments,
        pointToProjection,
      });
      setBounds(coordinates);
    }
  };

  const editImage = (): void => {
    dispatch(openLayerImageObjectEditFactoryModal());
  };

  const editText = (): void => {
    dispatch(openLayerTextEditFactoryModal());
  };

  const editRect = (): void => {
    dispatch(openLayerRectEditFactoryModal());
  };

  const editOval = (): void => {
    dispatch(openLayerOvalEditFactoryModal());
  };

  const editLine = (): void => {
    dispatch(openLayerLineEditFactoryModal());
  };

  if (!layer) {
    return null;
  }

  return (
    <div className={`${isLayerVisible ? 'opacity-100' : 'opacity-40'} flex flex-col gap-1 ps-3`}>
      <QuestionModal
        isOpen={Boolean(removeModalState)}
        onClose={rejectRemove}
        onConfirm={confirmRemove}
        question={
          removeModalState
            ? removeObjectConfig[removeModalState]?.question
            : 'Are you sure you want to remove the object'
        }
      />
      {Object.values(layer.texts).map(layerText => (
        <LayersDrawerTextItem
          layerText={layerText}
          key={layerText.id}
          moveToFront={() => moveTextToFront(layerText)}
          moveToBack={() => moveTextToBack(layerText)}
          removeObject={() => removeObject(layerText)}
          centerObject={() => centerObject(layerText)}
          editObject={() => editText()}
          isLayerVisible={isLayerVisible}
          isLayerActive={isLayerActive}
        />
      ))}
      {Object.values(layer.images).map(layerImage => (
        <LayersDrawerImageItem
          layerImage={layerImage}
          key={layerImage.id}
          moveToFront={() => moveImageToFront(layerImage)}
          moveToBack={() => moveImageToBack(layerImage)}
          removeObject={() => removeObject(layerImage)}
          centerObject={() => centerObject(layerImage)}
          editObject={() => editImage()}
          isLayerVisible={isLayerVisible}
          isLayerActive={isLayerActive}
        />
      ))}
      {Object.values(layer.rects).map(layerRect => (
        <LayersDrawerRectItem
          layerRect={layerRect}
          key={layerRect.id}
          moveToFront={() => moveRectToFront(layerRect)}
          moveToBack={() => moveRectToBack(layerRect)}
          removeObject={() => removeObject(layerRect)}
          centerObject={() => centerObject(layerRect)}
          editObject={() => editRect()}
          isLayerVisible={isLayerVisible}
          isLayerActive={isLayerActive}
        />
      ))}
      {Object.values(layer.ovals).map(layerOval => (
        <LayersDrawerOvalItem
          layerOval={layerOval}
          key={layerOval.id}
          moveToFront={() => moveOvalToFront(layerOval)}
          moveToBack={() => moveOvalToBack(layerOval)}
          removeObject={() => removeObject(layerOval)}
          centerObject={() => centerObject(layerOval)}
          editObject={() => editOval()}
          isLayerVisible={isLayerVisible}
          isLayerActive={isLayerActive}
        />
      ))}
      {Object.values(layer.lines).map(layerLine => (
        <LayersDrawerLineItem
          layerLine={layerLine}
          key={layerLine.id}
          moveToFront={() => moveLineToFront(layerLine)}
          moveToBack={() => moveLineToBack(layerLine)}
          removeObject={() => removeObject(layerLine)}
          centerObject={() => centerLayerLineObject(layerLine)}
          editObject={() => editLine()}
          isLayerVisible={isLayerVisible}
          isLayerActive={isLayerActive}
        />
      ))}
    </div>
  );
};
