import { MapInstance } from '@/types/map';
import { Pixel } from 'ol/pixel';

export default function isEditableElementAtPixel({
  mapInstance,
  pixel,
  activeLayers,
}: {
  mapInstance: MapInstance;
  pixel: Pixel;
  activeLayers: number[];
}): boolean {
  if (!mapInstance) {
    return false;
  }
  let isActiveLayerElementAtPixel = false;
  mapInstance.forEachFeatureAtPixel(
    pixel,
    (_, layer): boolean => {
      if (!layer) {
        return false;
      }
      const layerId = layer.get('id');
      if (!layerId) {
        return false;
      }
      if (activeLayers.includes(layerId)) {
        isActiveLayerElementAtPixel = true;
        return true;
      }
      return false;
    },
    { hitTolerance: 0 },
  );
  return isActiveLayerElementAtPixel;
}
