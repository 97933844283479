import { PINS_COLORS, TEXT_COLOR } from '@/constants/canvas';
import { EntityNumber } from '@/redux/entityNumber/entityNumber.types';
import { ModelElementWithPinType } from '@/types/modelElement';
import { addAlphaToHexString } from '@/utils/convert/addAlphaToHexString';
import { mix } from 'polished';
import { GetCanvasIconArgs } from '../getCanvasIcon';

const INACTIVE_ELEMENT_OPACITY = 0.5;
const DARK_COLOR_MIX_RATIO = 0.25;

interface Options {
  entityNumber: EntityNumber;
  activeIds: (string | number)[];
  isDarkColor?: boolean;
}

export const getMultipinCanvasArgs = (
  { type, ...element }: ModelElementWithPinType,
  { entityNumber, activeIds, isDarkColor }: Options,
): GetCanvasIconArgs => {
  const value = entityNumber?.[element.elementId];
  const isActive = activeIds.includes(element.id);
  const baseColor = isDarkColor
    ? mix(DARK_COLOR_MIX_RATIO, '#000', PINS_COLORS[type])
    : PINS_COLORS[type];

  const color = isActive ? baseColor : addAlphaToHexString(baseColor, INACTIVE_ELEMENT_OPACITY);

  const textColor = isActive
    ? TEXT_COLOR
    : addAlphaToHexString(TEXT_COLOR, INACTIVE_ELEMENT_OPACITY);

  return {
    color,
    textColor,
    value,
  };
};
