import { MapInstance } from '@/types/map';

export default function removeLayer({
  mapInstance,
  layerId,
}: {
  mapInstance: MapInstance;
  layerId: number;
}): void {
  mapInstance?.getAllLayers().forEach(layer => {
    if (layer.get('id') === layerId) {
      mapInstance?.removeLayer(layer);
    }
  });
}
