/* eslint-disable no-magic-numbers */
import { DrawerHeadingBackwardButton } from '@/shared/DrawerHeadingBackwardButton';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { openOverlaysDrawer } from '@/redux/drawer/drawer.slice';
import { Button } from '@/shared/Button';
import { Input } from '@/shared/Input';
import { Textarea } from '@/shared/Textarea';
import { OverlayGroupSelector } from '@/components/Map/Drawer/OverlaysDrawer/UserOverlayForm/OverlaySelector/OverlayGroupSelector.component';
import { OverlaySelector } from './OverlaySelector';
import { OVERLAY_TYPES } from './UserOverlayForm.constants';
import { FileUpload } from './FileUpload';
import { useUserOverlayForm } from './hooks/useUserOverlayForm';

export const UserOverlayForm = (): React.ReactNode => {
  const dispatch = useAppDispatch();
  const {
    name,
    type,
    group,
    overlayGroups,
    description,
    uploadedFile,
    elementsList,
    isPending,
    handleChangeName,
    handleChangeDescription,
    handleChangeType,
    handleChangeGroup,
    handleChangeElementsList,
    handleSubmit,
    updateUserOverlayForm,
    handleChangeUploadedFile,
    handleChangeOverlayContent,
  } = useUserOverlayForm();

  const navigateToOverlays = (): void => {
    dispatch(openOverlaysDrawer());
  };

  return (
    <>
      <DrawerHeadingBackwardButton backwardFunction={navigateToOverlays}>
        Add overlay
      </DrawerHeadingBackwardButton>
      <form className="flex h-[calc(100%-93px)] max-h-[calc(100%-93px)] flex-col overflow-y-auto p-6">
        <div className="mb-2.5">
          <p className="mb-2.5 text-sm">Upload file</p>
          <FileUpload
            uploadedFile={uploadedFile}
            updateUserOverlayForm={updateUserOverlayForm}
            handleChangeFile={handleChangeUploadedFile}
            handleChangeOverlayContent={handleChangeOverlayContent}
          />
          <p className="my-5 text-center">or</p>
          <label className="text-sm" htmlFor="elementsList">
            Provide list of elements here
            <Textarea
              id="elementsList"
              name="elementsList"
              data-testid="overlay-elements-list"
              value={elementsList}
              onChange={handleChangeElementsList}
              rows={6}
              placeholder="Type here"
              className="mt-2.5"
            />
          </label>
        </div>

        <label className="mb-2.5 text-sm" htmlFor="name">
          Name
          <Input
            type="text"
            name="name"
            id="name"
            data-testid="overlay-name"
            value={name}
            onChange={handleChangeName}
            placeholder="Overlays 11/07/2022"
            sizeVariant="medium"
            className="mt-2.5 text-xs"
          />
        </label>

        <OverlaySelector
          value={type}
          onChange={handleChangeType}
          items={OVERLAY_TYPES}
          label="Type"
        />

        <OverlayGroupSelector
          value={group}
          onChange={handleChangeGroup}
          items={overlayGroups}
          label="Select group"
        />

        <label className="mt-2.5 text-sm" htmlFor="description">
          Description
          <Textarea
            id="description"
            name="description"
            value={description}
            data-testid="overlay-description"
            onChange={handleChangeDescription}
            rows={4}
            placeholder="Type Description"
            className="mt-2.5"
          />
        </label>
        <Button
          className="mt-2.5 items-center justify-center self-start"
          onClick={handleSubmit}
          disabled={isPending}
          aria-label="upload overlay"
        >
          Upload
        </Button>
      </form>
    </>
  );
};
