import { useGetOverlayColor } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useGetOverlayColor';
import { ONE } from '@/constants/common';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  overlaysBioEntityForCurrentBioEntityAndCurrentModelSelector,
  overlaysOpenedSelector,
} from '@/redux/overlayBioEntity/overlayBioEntity.selector';
import { useSelector } from 'react-redux';
import { OverlayDataAxis } from '../OverlayData.types';

interface Options {
  isShowOverlayBioEntityName?: boolean;
}

export const useOverlaysAxes = ({
  isShowOverlayBioEntityName = false,
}: Options = {}): OverlayDataAxis[] => {
  const openedOverlays = useSelector(overlaysOpenedSelector);
  const currentBioEntityOverlaysForCurrentBioEntity = useAppSelector(
    overlaysBioEntityForCurrentBioEntityAndCurrentModelSelector,
  );

  const { getOverlayBioEntityColorByAvailableProperties } = useGetOverlayColor({
    forceOpacityValue: ONE,
  });

  return currentBioEntityOverlaysForCurrentBioEntity.map((overlayBioEntity): OverlayDataAxis => {
    const overlay = openedOverlays.find(o => o.id === overlayBioEntity.overlayId);

    return {
      id: overlayBioEntity.id,
      title: (isShowOverlayBioEntityName ? overlayBioEntity?.name : '') || overlay?.name || '',
      value: overlayBioEntity.value || undefined,
      color: getOverlayBioEntityColorByAvailableProperties(overlayBioEntity),
      geneVariants: overlayBioEntity?.geneVariants,
      overlayId: overlayBioEntity.overlayId,
    };
  });
};
