import { store } from '@/redux/store';
import { searchModelElementsListSelector } from '@/redux/modelElements/modelElements.selector';
import { SearchModelElementDataState } from '@/redux/modelElements/modelElements.types';

export const getAllBioEntities = (): SearchModelElementDataState[] => {
  const { getState } = store;
  const bioEntities = searchModelElementsListSelector(getState());

  return bioEntities || [];
};
