/* eslint-disable no-param-reassign */
import { ConnectDragSource, ConnectDropTarget, useDrag, useDrop } from 'react-dnd';
import { MapOverlay } from '@/types/models';

const ITEM_TYPE = 'card';

type UseDragAndDropProps = {
  index: number;
  overlay: MapOverlay;
  groupId: number | null;
  onHover: (dragIndex: number, hoverIndex: number) => void;
  onDrop: (overlay: MapOverlay, targetGroupId: number | null) => void;
};

type UseDragAndDropReturn = {
  isDragging: boolean;
  dragRef: ConnectDragSource;
  dropRef: ConnectDropTarget;
};

export const useDragAndDrop = ({
  index,
  onDrop,
  onHover,
  groupId,
  overlay,
}: UseDragAndDropProps): UseDragAndDropReturn => {
  const [{ isDragging }, dragRef] = useDrag({
    type: ITEM_TYPE,
    item: { index, overlay, groupId },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: ITEM_TYPE,
    hover: (item: { index: number; groupId: number | null; overlay: MapOverlay }) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      onHover(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
    drop(item: { index: number; groupId: number | null; overlay: MapOverlay }) {
      onDrop(item.overlay, groupId);
    },
  });

  return {
    isDragging,
    dragRef,
    dropRef,
  };
};
