import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { overlaysDataSelector } from '@/redux/overlays/overlays.selectors';
import { userRoleSelector } from '@/redux/user/user.selectors';
import { USER_ROLE } from '@/constants/user';
import { UserOverlaysGroup } from '@/components/Map/Drawer/OverlaysDrawer/UserOverlays/UserOverlaysGroup';
import React from 'react';
import { OverlayListItem } from './OverlayListItem';

export const GeneralOverlays = (): JSX.Element => {
  const generalPublicOverlays = useAppSelector(overlaysDataSelector);
  const role = useAppSelector(userRoleSelector);

  const isAdmin = role === USER_ROLE.ADMIN;

  return (
    <div>
      {isAdmin && (
        <UserOverlaysGroup
          key={0}
          groupName="Shared overlays"
          groupId={null}
          overlays={generalPublicOverlays}
          dangerouslySetExpanded
        />
      )}
      {!isAdmin && (
        <div className="border-b border-b-divide p-6">
          <p className="mb-5 text-sm font-semibold">Shared Overlays:</p>
          <ul>
            {generalPublicOverlays.map(overlay => (
              <OverlayListItem key={overlay.id} name={overlay.name} overlayId={overlay.id} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
