import { openReactionDrawerById, selectTab } from '@/redux/drawer/drawer.slice';
import { AppDispatch } from '@/redux/store';
import { searchFitBounds } from '@/services/pluginsManager/map/triggerSearch/searchFitBounds';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { ModelElement, NewReaction } from '@/types/models';
import { FEATURE_TYPE } from '@/constants/features';
import { addNumbersToEntityNumberData } from '@/redux/entityNumber/entityNumber.slice';
import { setReactions } from '@/redux/reactions/reactions.slice';
import { mapReactionToModelElement } from '@/utils/bioEntity/mapReactionToModelElement';
import getModelElementsIdsFromReaction from '@/components/Map/MapViewer/utils/listeners/mouseClick/getModelElementsIdsFromReaction';
import { setMultipleModelElementSearch } from '@/redux/modelElements/modelElements.slice';

/* prettier-ignore */
export const clickHandleReaction =
  (dispatch: AppDispatch, hasFitBounds = false) =>
    ( modelElements: Array<ModelElement>, reactions: Array<NewReaction>, reactionId: number, modelId: number): void => {

      const reactionModelElements: Array<ModelElement> = [];
      const reaction = reactions.find(newReaction => newReaction.id === reactionId);
      if(!reaction) {
        return;
      }
      const modelElementsIds = getModelElementsIdsFromReaction(reaction);
      modelElementsIds.forEach(modelElementId => {
        const modelElement = modelElements.find(element =>
          element.id === modelElementId
        );
        if(!modelElement) {
          return;
        }
        reactionModelElements.push(modelElement);
      });

      dispatch(openReactionDrawerById(reactionId));

      dispatch(selectTab(''));

      const reactionModelElement = mapReactionToModelElement(reaction);
      dispatch(setMultipleModelElementSearch(reactionModelElements));
      dispatch(addNumbersToEntityNumberData(reactionModelElements.map(modelElement => modelElement.elementId)));
      dispatch(setReactions([reaction]));

      const result = reactionModelElements.map((modelElement) => {return { modelElement, perfect: true };});
      result.push({ modelElement: reactionModelElement, perfect: true });
      PluginsEventBus.dispatchEvent('onSearch', {
        type: 'reaction',
        searchValues: [{ id: reactionId, modelId, type: FEATURE_TYPE.REACTION }],
        results: [result]
      });

      if (hasFitBounds) {
        searchFitBounds();
      }
    };
