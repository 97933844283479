import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { currentEditedOverlayGroupSelector } from '@/redux/modal/modal.selector';
import { closeModal } from '@/redux/modal/modal.slice';
import { loginUserSelector } from '@/redux/user/user.selectors';
import { OverlayGroup } from '@/types/models';
import React, { useState } from 'react';
import { ONE } from '@/constants/common';
import {
  getOverlayGroups,
  removeOverlayGroup,
  updateOverlayGroups,
} from '@/redux/overlayGroup/overlayGroup.thunks';

type UseEditOverlayGroupReturn = {
  name: string | undefined;
  order: number | undefined;
  handleCancelEdit: () => void;
  handleRemoveOverlayGroup: () => void;
  handleSaveEditedOverlayGroup: () => Promise<void>;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOrderChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type UpdatedOverlayGroup = {
  editedOverlayGroup: OverlayGroup;
  overlayName: string;
  overlayOrder: number;
};

export const useEditOverlayGroup = (): UseEditOverlayGroupReturn => {
  const currentEditedOverlayGroup = useAppSelector(currentEditedOverlayGroupSelector);
  const login = useAppSelector(loginUserSelector);
  const dispatch = useAppDispatch();
  const [name, setName] = useState(currentEditedOverlayGroup?.name);
  const [order, setOrder] = useState(currentEditedOverlayGroup?.order);

  const handleCancelEdit = (): void => {
    dispatch(closeModal());
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };

  const handleOrderChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setOrder(Number(e.target.value));
  };

  const handleRemoveOverlayGroup = (): void => {
    if (!login || !currentEditedOverlayGroup || !currentEditedOverlayGroup.id) return;
    dispatch(removeOverlayGroup({ overlayGroupId: currentEditedOverlayGroup.id }));
  };

  const handleUpdateOverlay = async ({
    overlayName,
    overlayOrder,
    editedOverlayGroup,
  }: UpdatedOverlayGroup): Promise<void> => {
    await dispatch(
      updateOverlayGroups([
        {
          ...editedOverlayGroup,
          name: overlayName,
          order: overlayOrder,
        },
      ]),
    );
  };

  const refreshOverlayGroups = async (): Promise<void> => {
    await dispatch(getOverlayGroups());
  };

  const handleCloseModal = (): void => {
    dispatch(closeModal());
  };

  const handleSaveEditedOverlayGroup = async (): Promise<void> => {
    if (!currentEditedOverlayGroup || !name || !login) return;
    await handleUpdateOverlay({
      editedOverlayGroup: currentEditedOverlayGroup,
      overlayName: name,
      overlayOrder: order || ONE,
    });

    await refreshOverlayGroups();

    handleCloseModal();
  };

  return {
    handleCancelEdit,
    handleRemoveOverlayGroup,
    handleSaveEditedOverlayGroup,
    handleNameChange,
    handleOrderChange,
    name,
    order,
  };
};
