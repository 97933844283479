import { MapInstance } from '@/types/map';
import { Layer } from '@/types/models';

export default function updateLayer({
  mapInstance,
  updatedLayer,
}: {
  mapInstance: MapInstance;
  updatedLayer: Layer;
}): void {
  mapInstance?.getAllLayers().forEach(layer => {
    if (layer.get('id') === updatedLayer.id) {
      layer.setZIndex(updatedLayer.z);
    }
  });
}
