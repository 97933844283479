import { setCurrentSelectedBioEntityId } from '@/redux/contextMenu/contextMenu.slice';
import { AppDispatch } from '@/redux/store';
import { ModelElement } from '@/types/models';
import { addNumbersToEntityNumberData } from '@/redux/entityNumber/entityNumber.slice';
import { setModelElementSearch } from '@/redux/modelElements/modelElements.slice';

/* prettier-ignore */
export const rightClickHandleAlias =
  (dispatch: AppDispatch) =>
    async (id: number, modelElement: ModelElement): Promise<void> => {
      dispatch(setModelElementSearch({ modelElement, perfect: true }));
      dispatch(addNumbersToEntityNumberData([modelElement.elementId]));
      dispatch(setCurrentSelectedBioEntityId(id));
    };
