/* eslint-disable no-magic-numbers */
import { Color } from '@/types/models';

export default function hexToRgbIntAlpha(hex: string): Color {
  const sanitizedHex = hex.replace(/^#/, '');

  if (sanitizedHex.length !== 6 && sanitizedHex.length !== 8) {
    throw new Error('Invalid HEX color. Must be 6 or 8 characters.');
  }

  const r = parseInt(sanitizedHex.slice(0, 2), 16);
  const g = parseInt(sanitizedHex.slice(2, 4), 16);
  const b = parseInt(sanitizedHex.slice(4, 6), 16);

  let alpha = 255;
  if (sanitizedHex.length === 8) {
    alpha = parseInt(sanitizedHex.slice(6, 8), 16);
  }

  const rgb = r * 65536 + g * 256 + b;
  return { rgb, alpha };
}
