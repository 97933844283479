import { SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { MapOverlay } from '@/types/models';
import { useMemo } from 'react';
import { OverlayAxis } from '../OverlayAxis';
import { OverlayDataAxis } from '../OverlayData.types';
import { getAxesSortedByValue } from '../utils/getAxesSortedByValue';

interface Props {
  overlay: MapOverlay;
  axes: OverlayDataAxis[];
}

export const GroupedOverlayAxes = ({ overlay, axes }: Props): JSX.Element | null => {
  const { id, name } = overlay;
  const overlayAxes = axes.filter(axis => axis.overlayId === id);
  const sortedAxes = useMemo(() => getAxesSortedByValue(overlayAxes), [overlayAxes]);

  if (overlayAxes.length === SIZE_OF_EMPTY_ARRAY) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 rounded-lg border border-divide p-4">
      <div className="font-bold" data-testid="grouped-overlay-title">
        {name}
      </div>
      <div className="my-1 h-[1px] w-full bg-divide" />
      {sortedAxes.map(axis => (
        <OverlayAxis key={axis.title} axis={axis} />
      ))}
    </div>
  );
};
