import { WebSocketEntityUpdateInterface } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.types';
import { store } from '@/redux/store';
import { ENTITY_OPERATION_TYPES } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.constants';
import { getLayerOval } from '@/redux/layers/layers.thunks';
import { MapInstance } from '@/types/map';
import drawElementOnLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/drawElementOnLayer';
import { layerDeleteOval } from '@/redux/layers/layers.slice';
import removeElementFromLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/removeElementFromLayer';
import updateElement from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/updateElement';

export default async function processLayerOval({
  data,
  mapInstance,
}: {
  data: WebSocketEntityUpdateInterface;
  mapInstance: MapInstance;
}): Promise<void> {
  const { dispatch } = store;
  if (
    data.type === ENTITY_OPERATION_TYPES.ENTITY_CREATED ||
    data.type === ENTITY_OPERATION_TYPES.ENTITY_UPDATED
  ) {
    const resultOval = await dispatch(
      getLayerOval({
        modelId: data.mapId,
        layerId: data.layerId,
        ovalId: data.entityId,
      }),
    ).unwrap();
    if (!resultOval) {
      return;
    }
    if (data.type === ENTITY_OPERATION_TYPES.ENTITY_CREATED) {
      drawElementOnLayer({
        mapInstance,
        activeLayer: data.layerId,
        object: resultOval,
        drawFunctionKey: 'drawOval',
      });
    } else {
      updateElement(mapInstance, data.layerId, resultOval);
    }
  } else if (data.type === ENTITY_OPERATION_TYPES.ENTITY_DELETED) {
    dispatch(
      layerDeleteOval({
        modelId: data.mapId,
        layerId: data.layerId,
        ovalId: data.entityId,
      }),
    );
    removeElementFromLayer({ mapInstance, layerId: data.layerId, featureId: data.entityId });
  }
}
