import { BioEntityContent } from '@/types/models';
import { SearchModelElementDataState } from '@/redux/modelElements/modelElements.types';
import { BioEntitiesPinsListItem } from './BioEntitiesPinsListItem';

interface BioEntitiesPinsListProps {
  bioEnititesPins: Array<BioEntityContent | SearchModelElementDataState>;
}

export const BioEntitiesPinsList = ({ bioEnititesPins }: BioEntitiesPinsListProps): JSX.Element => {
  return (
    <ul className="h-[calc(100%-224px)] max-h-[calc(100%-224px)] overflow-auto px-6 py-2">
      {bioEnititesPins &&
        bioEnititesPins.map(result =>
          'bioEntity' in result ? (
            <BioEntitiesPinsListItem
              key={result.bioEntity.name}
              name={result.bioEntity.name}
              pin={result.bioEntity}
            />
          ) : (
            <BioEntitiesPinsListItem
              key={result.modelElement.name}
              name={result.modelElement.name}
              pin={result.modelElement}
            />
          ),
        )}
    </ul>
  );
};
