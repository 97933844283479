/* eslint-disable no-magic-numbers */
import { Select, Snap } from 'ol/interaction';
import { Collection, Feature } from 'ol';
import { Geometry, LineString } from 'ol/geom';
import getLineSegmentsFromCoords from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/getLineSegmentsFromCoords';
import { MapSize } from '@/redux/map/map.types';
import { SelectEvent } from 'ol/interaction/Select';
import { AppDispatch } from '@/redux/store';
import { mapEditToolsSetLayerLine } from '@/redux/mapEditTools/mapEditTools.slice';
import { openDrawer } from '@/redux/drawer/drawer.slice';
import { Extent } from 'ol/extent';
import ModifyFeature from 'ol-ext/interaction/ModifyFeature';
import BaseEvent from 'ol/events/Event';
import { Coordinate } from 'ol/coordinate';

function includesFeature(featuresCollection: Collection<Feature>, searchFeature: Feature): boolean {
  const foundFeature = featuresCollection.getArray().find(feature => {
    return feature.getId() === searchFeature.getId();
  });
  return Boolean(foundFeature);
}

export default function getModifyLineInteraction({
  mapSize,
  dispatch,
  modelId,
  featuresToSelectCollection,
  modifyFeatures,
  restrictionExtent,
}: {
  mapSize: MapSize;
  modelId: number;
  dispatch: AppDispatch;
  featuresToSelectCollection: Collection<Feature<Geometry>>;
  modifyFeatures: Collection<Feature<Geometry>>;
  restrictionExtent: Extent;
}): { modify: ModifyFeature; snap: Snap; select: Select } {
  const snap = new Snap({
    features: featuresToSelectCollection,
    pixelTolerance: 5,
    edge: false,
  });
  const modify = new ModifyFeature({
    features: modifyFeatures,
    pixelTolerance: 10,
  });
  const select = new Select({
    hitTolerance: 3,
    filter: (feature): boolean => {
      return includesFeature(featuresToSelectCollection, feature);
    },
  });

  select.on('select', (event: SelectEvent) => {
    modifyFeatures.clear();
    if (!event.selected.length) {
      if (event.deselected.length) {
        event.deselected[0].set('selected', false);
      }
      dispatch(mapEditToolsSetLayerLine(null));
      return;
    }
    const selected = event.selected[0];
    selected.set('selected', true);
    modifyFeatures.push(selected);

    const getObjectData = selected.get('getObjectData');
    if (getObjectData && getObjectData instanceof Function) {
      const objectData = getObjectData();
      dispatch(mapEditToolsSetLayerLine(objectData));
      dispatch(openDrawer('layers'));
    }
  });

  modify.on('modifying', (event: Event | BaseEvent) => {
    const transformEvent = event as unknown as { features: Array<Feature>; coordinate: Coordinate };
    const { features, coordinate } = transformEvent;
    if (!features.length) {
      return;
    }
    const feature = features[0];
    const geometry = feature.getGeometry();
    if (!geometry || !(geometry instanceof LineString)) {
      return;
    }

    const [x, y] = coordinate;
    const coords = geometry.getCoordinates();
    let vertexIndex = -1;
    coords.forEach((pt: number[], index: number) => {
      if (pt[0] === x || pt[1] === y) {
        vertexIndex = index;
      }
    });
    if (vertexIndex === -1) {
      return;
    }
    const [minX, minY, maxX, maxY] = restrictionExtent;
    const correctedX = Math.max(minX, Math.min(maxX, x));
    const correctedY = Math.max(minY, Math.min(maxY, y));
    if (correctedX !== x || correctedY !== y) {
      coords[vertexIndex] = [correctedX, correctedY];
      const setCoordinates = feature.get('setCoordinates');
      if (setCoordinates instanceof Function) {
        setCoordinates(coords);
      }
    }
  });

  modify.on('modifyend', (event: Event | BaseEvent) => {
    const transformEvent = event as unknown as { features: Array<Feature> };
    if (!transformEvent.features.length) {
      return;
    }
    const feature = transformEvent.features[0];
    if (!feature) {
      return;
    }
    const geometry = feature.getGeometry();
    if (!geometry) {
      return;
    }
    const coords = (geometry as LineString).getCoordinates();
    const firstPoint = coords.at(0);
    const lastPoint = coords.at(-1);
    if (!firstPoint || !lastPoint) {
      return;
    }
    const segments = getLineSegmentsFromCoords({ mapSize, coords });

    const save = feature.get('save');
    const drawLayerLine = feature.get('drawLayerLine');
    if (save instanceof Function) {
      try {
        save({ modelId, segments, firstPoint, lastPoint });
      } catch {
        if (drawLayerLine instanceof Function) {
          drawLayerLine();
        }
      }
    }
  });

  return { modify, snap, select };
}
