import { createSelector } from '@reduxjs/toolkit';
import { newReactionsLoadingSelector } from '@/redux/newReactions/newReactions.selectors';
import { modelElementsCurrentModelLoadingSelector } from '@/redux/modelElements/modelElements.selector';
import {
  arrowTypesLoadingSelector,
  bioShapesLoadingSelector,
  lineTypesLoadingSelector,
} from '@/redux/shapes/shapes.selectors';
import { layersLoadingSelector } from '@/redux/layers/layers.selectors';

export const modelLoadingSelector = createSelector(
  [
    newReactionsLoadingSelector,
    modelElementsCurrentModelLoadingSelector,
    bioShapesLoadingSelector,
    lineTypesLoadingSelector,
    arrowTypesLoadingSelector,
    layersLoadingSelector,
  ],
  (...loadingStates) => loadingStates.includes('pending'),
);

export const modelLoadedSuccessfullySelector = createSelector(
  [
    newReactionsLoadingSelector,
    modelElementsCurrentModelLoadingSelector,
    bioShapesLoadingSelector,
    lineTypesLoadingSelector,
    arrowTypesLoadingSelector,
    layersLoadingSelector,
  ],
  (...loadingStates) => loadingStates.every(state => state === 'succeeded'),
);
