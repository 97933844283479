import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import {
  initDataLoadingFinishedSelector,
  initDataLoadingInitialized,
} from '@/redux/root/init.selectors';
import { fetchInitialAppData } from '@/redux/root/init.thunks';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { parseQueryToTypes } from '../parseQueryToTypes';

/**
 * 1. Initialise all required data before app starts: Project info, available Overlays, available Models (maps,submaps)
 * 2. Based on that set required map data to correctly display view. If query params are available -> use them to set map data
 */

export const useInitializeStore = (): void => {
  const dispatch = useAppDispatch();
  const isInitialized = useAppSelector(initDataLoadingInitialized);
  const isInitDataLoadingFinished = useAppSelector(initDataLoadingFinishedSelector);
  const { query, isReady: isRouterReady } = useRouter();
  const isQueryReady = useMemo(() => query && isRouterReady, [query, isRouterReady]);

  useEffect(() => {
    if (isInitialized || !isQueryReady) {
      return;
    }
    dispatch(fetchInitialAppData({ queryData: parseQueryToTypes(query) }));
  }, [dispatch, isInitialized, query, isQueryReady, isInitDataLoadingFinished]);
};
