/* eslint-disable no-magic-numbers */
import { ColorTilePicker } from '@/shared/ColorPicker/ColorTilePicker.component';
import hexToRgbIntAlpha from '@/utils/convert/hexToRgbIntAlpha';
import { Color } from '@/types/models';
import { LayerRectFactoryForm } from '@/components/FunctionalArea/Modal/LayerRectFactoryModal/LayerRectFactory.types';

type LayerRectFormProps = {
  data: LayerRectFactoryForm;
  onChange: (value: number | string | Color, key: string) => void;
};

export const LayerRectForm = ({ data, onChange }: LayerRectFormProps): React.JSX.Element => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <span>Fill color:</span>
        <ColorTilePicker
          initialColor={data.fillColor}
          colorChange={color => onChange(hexToRgbIntAlpha(color), 'fillColor')}
        />
      </div>
      <div>
        <span>Border color:</span>
        <ColorTilePicker
          initialColor={data.borderColor}
          colorChange={color => onChange(hexToRgbIntAlpha(color), 'borderColor')}
        />
      </div>
    </div>
  );
};
