/* eslint-disable no-magic-numbers */
import React, { useRef, useState } from 'react';
import './ElementGlyphModal.styles.css';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { elementGlyphStateSelector } from '@/redux/modal/modal.selector';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { addGlyph } from '@/redux/glyphs/glyphs.thunks';
import { SerializedError } from '@reduxjs/toolkit';
import { showToast } from '@/utils/showToast';
import { closeModal } from '@/redux/modal/modal.slice';
import {
  getModelElementsForModel,
  updateElementGlyph,
} from '@/redux/modelElements/modelElements.thunks';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { Button } from '@/shared/Button';
import {
  ElementGlyphForm,
  ElementGlyphFormHandles,
} from '@/components/FunctionalArea/Modal/ElementGlyphModal/ElementGlyphForm.component';

export const ElementGlyphModal: React.FC = () => {
  const elementGlyphState = useAppSelector(elementGlyphStateSelector);

  if (!elementGlyphState) {
    throw new Error('Invalid element');
  }

  const dispatch = useAppDispatch();

  const [selectedGlyph, setSelectedGlyph] = useState<number | null>(elementGlyphState.glyph);
  const [file, setFile] = useState<File | null>(null);
  const [isSending, setIsSending] = useState<boolean>(false);
  const formRef = useRef<ElementGlyphFormHandles>(null);

  const handleSubmit = async (): Promise<void> => {
    if (!elementGlyphState) {
      return;
    }
    setIsSending(true);
    try {
      let glyphId = selectedGlyph;
      if (file) {
        const data = await dispatch(addGlyph(file)).unwrap();
        if (!data) {
          return;
        }
        glyphId = data.id;
      }
      await dispatch(
        updateElementGlyph({
          modelId: elementGlyphState.model,
          elementId: elementGlyphState.id,
          glyphId,
        }),
      ).unwrap();
      showToast({
        type: 'success',
        message: 'The element glyph has been successfully updated.',
      });
      dispatch(getModelElementsForModel(elementGlyphState.model));
      dispatch(closeModal());
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while updating a glyph.',
      });
    } finally {
      setIsSending(false);
    }
  };

  const clearGlyph = (): void => {
    setSelectedGlyph(null);
    setFile(null);
    formRef.current?.clearFileInput();
  };

  return (
    <div className="relative flex w-[800px] flex-col gap-3 border border-t-[#E1E0E6] bg-white p-[24px]">
      {isSending && (
        <div className="c-element-glyph-modal-loader">
          <LoadingIndicator width={44} height={44} />
        </div>
      )}
      <ElementGlyphForm
        ref={formRef}
        file={file}
        selectedGlyph={selectedGlyph}
        setFile={setFile}
        setSelectedGlyph={setSelectedGlyph}
      />
      <div className="flex justify-end gap-2">
        <Button type="button" onClick={clearGlyph} className="justify-center text-base font-medium">
          Clear
        </Button>
        <Button
          type="button"
          onClick={handleSubmit}
          className="justify-center text-base font-medium"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
