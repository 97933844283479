/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import {
  getDefaultSearchTab,
  getSearchValuesArrayAndTrimToSeven,
} from '@/components/FunctionalArea/TopBar/SearchBar/SearchBar.utils';
import { openSearchDrawerWithSelectedTab } from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapModelIdSelector, mapOpenedMapsSelector } from '@/redux/map/map.selectors';
import { openMapAndSetActive, setActiveMap } from '@/redux/map/map.slice';
import { closeModal } from '@/redux/modal/modal.slice';
import { modelsNameMapSelector } from '@/redux/models/models.selectors';
import { getSearchData } from '@/redux/search/search.thunks';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { PublicationElement } from '@/types/models';
import { isReactionElement } from '@/redux/reactions/isReactionElement';

interface Props {
  target: PublicationElement;
}

export const ElementLink = ({ target }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const openedMaps = useAppSelector(mapOpenedMapsSelector);
  const currentModelId = useAppSelector(mapModelIdSelector);
  const mapsNames = useAppSelector(modelsNameMapSelector);

  const isReaction = isReactionElement(target);

  const isMapAlreadyOpened = (modelId: number): boolean =>
    openedMaps.some(map => map.modelId === modelId);

  const searchForElementAndOpenDrawer = (): void => {
    let query = '';
    if (isReaction) {
      query = `reaction:${target.id}`;
    } else {
      query = `element:${target.id}`;
    }
    const searchValues = getSearchValuesArrayAndTrimToSeven(query);
    dispatch(getSearchData({ searchQueries: searchValues, isPerfectMatch: true }));
    dispatch(openSearchDrawerWithSelectedTab(getDefaultSearchTab(searchValues)));
  };

  const openSubmap = (): void => {
    if (isMapAlreadyOpened(target.model)) {
      dispatch(setActiveMap({ modelId: target.model }));
    } else {
      dispatch(openMapAndSetActive({ modelId: target.model, modelName: mapsNames[target.model] }));
    }

    if (currentModelId !== target.model) {
      PluginsEventBus.dispatchEvent('onSubmapClose', currentModelId);
      PluginsEventBus.dispatchEvent('onSubmapOpen', target.model);
    }
  };

  const handleElementLinkClick = (): void => {
    dispatch(closeModal());
    searchForElementAndOpenDrawer();
    openSubmap();
  };

  return (
    <button
      type="button"
      className="inline-block cursor-pointer underline"
      onClick={handleElementLinkClick}
    >
      {isReaction && 'Reaction: '}
      {!isReaction && 'Element: '}
      {target.elementId}
    </button>
  );
};
