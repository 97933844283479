/* eslint-disable no-magic-numbers */
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { Style, Text } from 'ol/style';
import Icon from 'ol/style/Icon';
import { FeatureLike } from 'ol/Feature';
import { MapInstance } from '@/types/map';
import { apiPath } from '@/redux/apiPath';
import { BASE_NEW_API_URL } from '@/constants';
import Polygon from 'ol/geom/Polygon';
import { Point } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { FEATURE_TYPE } from '@/constants/features';
import { WHITE_COLOR } from '@/components/Map/MapViewer/MapViewer.constants';
import { MapSize } from '@/redux/map/map.types';
import { LayerImage } from '@/types/models';
import getStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStyle';
import getFill from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getFill';
import getScaledElementStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getScaledElementStyle';
import getBoundingBoxPolygon from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/utils/getBoundingBoxPolygon';

export type GlyphProps = {
  elementId: number;
  glyphId: number | null;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  mapSize: MapSize;
};

export default class Glyph {
  feature: Feature<Polygon>;

  style: Style = new Style();

  noGlyphStyle: Style = new Style();

  imageScale: number = 1;

  polygonStyle: Style = new Style();

  polygon: Polygon = new Polygon([]);

  elementId: number;

  width: number;

  height: number;

  x: number;

  y: number;

  zIndex: number;

  glyphId: number | null;

  widthOnMap: number;

  heightOnMap: number;

  pixelRatio: number = 1;

  minResolution: number;

  imageWidth: number = 1;

  imageHeight: number = 1;

  imageWidthOnMap: number = 1;

  imageHeightOnMap: number = 1;

  mapInstance: MapInstance;

  pointToProjection: UsePointToProjectionResult;

  mapSize: MapSize;

  constructor({
    elementId,
    glyphId,
    x,
    y,
    width,
    height,
    zIndex,
    pointToProjection,
    mapInstance,
    mapSize,
  }: GlyphProps) {
    this.elementId = elementId;
    this.width = width;
    this.height = height;
    this.mapSize = mapSize;
    this.glyphId = glyphId;
    this.x = x;
    this.y = y;
    this.zIndex = zIndex;
    this.mapInstance = mapInstance;
    this.pointToProjection = pointToProjection;
    const point1 = this.pointToProjection({ x: 0, y: 0 });
    const point2 = this.pointToProjection({ x: this.width, y: this.height });
    this.widthOnMap = Math.abs(point2[0] - point1[0]);
    this.heightOnMap = Math.abs(point2[1] - point1[1]);

    const maxZoom = mapInstance?.getView().get('originalMaxZoom');
    this.minResolution = mapInstance?.getView().getResolutionForZoom(maxZoom) || 1;
    this.pixelRatio = this.widthOnMap / this.minResolution / this.width;

    this.polygon = getBoundingBoxPolygon({
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      pointToProjection: this.pointToProjection,
    });

    this.setStyles();

    this.feature = new Feature({
      geometry: this.polygon,
      id: this.elementId,
      type: FEATURE_TYPE.GLYPH,
      zIndex: this.zIndex,
      getAnchorAndCoords: (coords: Coordinate): { anchor: Array<number>; coords: Coordinate } => {
        const center = this.mapInstance?.getView().getCenter();
        let anchorX = 0;
        let anchorY = 0;
        if (center) {
          anchorX = (center[0] - coords[0]) / this.widthOnMap;
          anchorY = -(center[1] - coords[1]) / this.heightOnMap;
        }
        return { anchor: [anchorX, anchorY], coords: center || [0, 0] };
      },
    });

    this.feature.set('setCoordinates', this.setCoordinates.bind(this));
    this.feature.set('refreshPolygon', this.refreshPolygon.bind(this));
    this.feature.set('updateElement', this.updateElement.bind(this));
    this.feature.setId(this.elementId);
    this.feature.setStyle(this.getStyle.bind(this));

    this.drawImage();
  }

  private setStyles(): void {
    this.polygonStyle = getStyle({
      geometry: this.polygon,
      zIndex: this.zIndex,
      borderColor: { ...WHITE_COLOR, alpha: 0 },
      fillColor: { ...WHITE_COLOR, alpha: 0 },
    });

    this.noGlyphStyle = getStyle({
      geometry: this.polygon,
      zIndex: this.zIndex,
      fillColor: '#E7E7E7',
    });
    this.noGlyphStyle.setText(
      new Text({
        text: 'No image',
        font: '12pt Arial',
        fill: getFill({ color: '#000' }),
        overflow: true,
      }),
    );
  }

  private refreshPolygon(): void {
    this.polygon = getBoundingBoxPolygon({
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      pointToProjection: this.pointToProjection,
    });
    this.polygonStyle.setGeometry(this.polygon);
    this.feature.setGeometry(this.polygon);
  }

  private refreshZIndex(): void {
    this.polygonStyle.setZIndex(this.zIndex);
    this.noGlyphStyle.setZIndex(this.zIndex);
    this.style.setZIndex(this.zIndex);
    this.feature.changed();
  }

  protected updateElement(imageObject: LayerImage): void {
    this.elementId = imageObject.id;
    this.x = imageObject.x;
    this.y = imageObject.y;
    this.zIndex = imageObject.z;
    this.width = imageObject.width;
    this.height = imageObject.height;
    this.glyphId = imageObject.glyph;

    this.refreshPolygon();
    this.setStyles();
    this.drawImage();
    this.feature.changed();
  }

  protected setImageScaleAndDimensions(height: number, width: number): void {
    this.widthOnMap = width;
    this.heightOnMap = height;
    const heightScale = height / this.imageHeightOnMap;
    const widthScale = width / this.imageWidthOnMap;
    if (heightScale < widthScale) {
      this.imageScale = heightScale;
      this.widthOnMap = (this.heightOnMap * this.imageWidth) / this.imageHeight;
    } else {
      this.imageScale = widthScale;
      this.heightOnMap = (this.widthOnMap * this.imageHeight) / this.imageWidth;
    }
  }

  private setCoordinates(coords: Coordinate[][]): void {
    const geometry = this.polygonStyle.getGeometry();
    if (geometry && geometry instanceof Polygon) {
      geometry.setCoordinates(coords);
    }
  }

  private drawImage(): void {
    if (!this.glyphId) {
      return;
    }
    const img = new Image();
    img.onload = (): void => {
      this.imageWidth = img.naturalWidth;
      this.imageHeight = img.naturalHeight;
      const imagePoint1 = this.pointToProjection({ x: 0, y: 0 });
      const imagePoint2 = this.pointToProjection({ x: this.imageWidth, y: this.imageHeight });
      this.imageWidthOnMap = Math.abs(imagePoint2[0] - imagePoint1[0]);
      this.imageHeightOnMap = Math.abs(imagePoint2[1] - imagePoint1[1]);
      this.setImageScaleAndDimensions(this.heightOnMap, this.widthOnMap);
      this.style = new Style({
        image: new Icon({
          anchor: [0, 0],
          img,
          size: [this.imageWidth, this.imageHeight],
        }),
        zIndex: this.zIndex,
      });
      this.feature.changed();
    };
    img.src = `${BASE_NEW_API_URL}${apiPath.getGlyphImage(this.glyphId)}`;
  }

  protected getStyle(feature: FeatureLike, resolution: number): Style | Array<Style> | void {
    const scale = this.minResolution / resolution;
    const getAnchorAndCoords = feature.get('getAnchorAndCoords');
    let anchor = [0, 0];
    let coords = [this.x, this.y];

    const geometry = feature.getGeometry();
    if (geometry && geometry instanceof Polygon) {
      const polygonExtent = geometry.getExtent();
      if (polygonExtent) {
        coords = [polygonExtent[0], polygonExtent[3]];
        const width = Math.abs(polygonExtent[0] - polygonExtent[2]);
        const height = Math.abs(polygonExtent[1] - polygonExtent[3]);
        this.setImageScaleAndDimensions(height, width);
      }
    } else {
      return [];
    }
    if (getAnchorAndCoords instanceof Function) {
      const anchorAndCoords = getAnchorAndCoords(coords);
      anchor = anchorAndCoords.anchor;
      coords = anchorAndCoords.coords;
    }
    if (this.style.getImage()) {
      this.style.getImage()?.setScale(scale * this.pixelRatio * this.imageScale);
      (this.style.getImage() as Icon).setAnchor(anchor);
      this.style.setGeometry(new Point(coords));
      return [this.style, this.polygonStyle];
    }
    return getScaledElementStyle(this.noGlyphStyle, undefined, scale);
  }
}
