import { OPTIONS } from '@/constants/map';
import { openedDrawerSelector, resultDrawerOpen } from '@/redux/drawer/drawer.selectors';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { MapInstance } from '@/types/map';
import { unByKey } from 'ol/Observable';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { allCommentsSelectorOfCurrentMap } from '@/redux/comment/comment.selectors';
import { onMapLeftClick } from '@/components/Map/MapViewer/utils/listeners/mouseClick/mouseLeftClick/onMapLeftClick';
import { Coordinate } from 'ol/coordinate';
import { Pixel } from 'ol/pixel';
import { onMapRightClick } from '@/components/Map/MapViewer/utils/listeners/mouseClick/mouseRightClick/onMapRightClick';
import { modelElementsForCurrentModelSelector } from '@/redux/modelElements/modelElements.selector';
import { newReactionsForCurrentModelSelector } from '@/redux/newReactions/newReactions.selectors';
import { useHandlePinIconClick } from '@/components/Map/MapViewer/utils/listeners/pinIconClick/useHandlePinIconClick';
import { onMapPositionChange } from '@/components/Map/MapViewer/utils/listeners/onMapPositionChange';
import { onPointerMove } from '@/components/Map/MapViewer/utils/listeners/onPointerMove';
import { View } from 'ol';
import { isMapEditToolsActiveSelector } from '@/redux/mapEditTools/mapEditTools.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  layersActiveLayersSelector,
  layersVisibilityForCurrentModelSelector,
} from '@/redux/layers/layers.selectors';
import { hasPrivilegeToWriteProjectSelector } from '@/redux/user/user.selectors';

interface UseOlMapListenersInput {
  view: View;
  mapInstance: MapInstance;
}

export const useOlMapListeners = ({ view, mapInstance }: UseOlMapListenersInput): void => {
  const mapSize = useSelector(mapDataSizeSelector);
  const modelId = useSelector(currentModelIdSelector);
  const isResultDrawerOpen = useSelector(resultDrawerOpen);
  const modelElementsForCurrentModel = useSelector(modelElementsForCurrentModelSelector);
  const newReactionsForCurrentModel = useSelector(newReactionsForCurrentModelSelector);
  const isMapEditToolsActive = useSelector(isMapEditToolsActiveSelector);
  const activeLayers = useSelector(layersActiveLayersSelector);
  const layersVisibilityForCurrentModel = useSelector(layersVisibilityForCurrentModelSelector);
  const hasPrivilegeToWriteProject = useAppSelector(hasPrivilegeToWriteProjectSelector);
  const openedDrawer = useAppSelector(openedDrawerSelector);
  const isLayersDrawerOpen = useMemo(() => {
    return openedDrawer === 'layers';
  }, [openedDrawer]);

  const showPointerMoveOnLine = useMemo(() => {
    return (layerId: number): boolean => {
      return (
        hasPrivilegeToWriteProject &&
        activeLayers.includes(layerId) &&
        layersVisibilityForCurrentModel[layerId]
      );
    };
  }, [activeLayers, hasPrivilegeToWriteProject, layersVisibilityForCurrentModel]);

  const dispatch = useAppDispatch();
  const coordinate = useRef<Coordinate>([]);
  const pixel = useRef<Pixel>([]);

  const comments = useSelector(allCommentsSelectorOfCurrentMap);

  useHandlePinIconClick();

  const handleChangeCenter = useDebouncedCallback(
    onMapPositionChange(mapSize, dispatch),
    OPTIONS.queryPersistTime,
    { leading: false },
  );

  const handleMapLeftClick = useDebouncedCallback(
    onMapLeftClick(
      mapSize,
      modelId,
      dispatch,
      isResultDrawerOpen,
      comments,
      modelElementsForCurrentModel || [],
      newReactionsForCurrentModel,
      activeLayers,
      hasPrivilegeToWriteProject,
    ),
    OPTIONS.clickPersistTime,
    { leading: false },
  );

  const handleRightClick = useDebouncedCallback(
    onMapRightClick(
      mapSize,
      modelId,
      dispatch,
      modelElementsForCurrentModel || [],
      newReactionsForCurrentModel,
    ),
    OPTIONS.clickPersistTime,
    {
      leading: false,
    },
  );

  useEffect(() => {
    const key = view.on('change:center', handleChangeCenter);
    return () => unByKey(key);
  }, [view, handleChangeCenter]);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }

    const key = mapInstance.on('pointermove', event =>
      onPointerMove(mapInstance, event, showPointerMoveOnLine),
    );

    // eslint-disable-next-line consistent-return
    return () => unByKey(key);
  }, [mapInstance, showPointerMoveOnLine]);

  useEffect(() => {
    if (!mapInstance || isMapEditToolsActive) {
      return;
    }

    const key = mapInstance.on('singleclick', event =>
      handleMapLeftClick({ coordinate: event.coordinate, pixel: event.pixel }, mapInstance),
    );

    // eslint-disable-next-line consistent-return
    return () => unByKey(key);
  }, [mapInstance, handleMapLeftClick, isMapEditToolsActive, isLayersDrawerOpen]);

  useEffect(() => {
    if (!mapInstance || isMapEditToolsActive) {
      return;
    }

    const rightClickEvent = (e: MouseEvent): Promise<void> | undefined => {
      e.preventDefault();

      coordinate.current = mapInstance.getEventCoordinate(e);
      pixel.current = mapInstance.getEventPixel(e);

      return handleRightClick(
        { coordinate: coordinate.current, pixel: pixel.current },
        mapInstance,
      );
    };

    mapInstance.getViewport().addEventListener('contextmenu', rightClickEvent);

    // eslint-disable-next-line consistent-return
    return () => mapInstance.getViewport().removeEventListener('contextmenu', rightClickEvent);
  }, [mapInstance, handleRightClick, isMapEditToolsActive, isLayersDrawerOpen]);
};
