/* eslint-disable no-magic-numbers */
import { OverlayGroup } from '@/types/models';
import { ZERO } from '@/constants/common';

export const OVERLAY_TYPES = [
  {
    id: 'GENERIC',
    label: 'GENERIC',
  },
  {
    id: 'GENETIC_VARIANT',
    label: 'GENETIC_VARIANT',
  },
];

export const DEFAULT_GROUP: OverlayGroup = {
  id: null,
  name: 'Without group',
  order: ZERO,
};

export const DEFAULT_TYPE = OVERLAY_TYPES[0];
