/* eslint-disable no-magic-numbers */
import { Collection, Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource, { VectorSourceEvent } from 'ol/source/Vector';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { getLayersForModel } from '@/redux/layers/layers.thunks';
import {
  layersActiveLayersSelector,
  layersDrawLayerSelector,
  layersForCurrentModelSelector,
  layersLoadingSelector,
  layersVisibilityForCurrentModelSelector,
} from '@/redux/layers/layers.selectors';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import { MapInstance } from '@/types/map';
import { Geometry, LineString, MultiPolygon } from 'ol/geom';
import Polygon from 'ol/geom/Polygon';
import Layer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/Layer';
import { arrowTypesSelector, lineTypesSelector } from '@/redux/shapes/shapes.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import {
  mapEditToolsActiveActionSelector,
  mapEditToolsLayerLineSelector,
  mapEditToolsLayerNonLineObjectSelector,
} from '@/redux/mapEditTools/mapEditTools.selectors';
import { MAP_EDIT_ACTIONS } from '@/redux/mapEditTools/mapEditTools.constants';
import getDrawBoundingBoxInteraction from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/interaction/getDrawBoundingBoxInteraction';
import {
  openLayerImageObjectFactoryModal,
  openLayerRectFactoryModal,
  openLayerTextFactoryModal,
} from '@/redux/modal/modal.slice';
import { Extent } from 'ol/extent';
import {
  mapEditToolsSetActiveAction,
  mapEditToolsSetLayerLine,
  mapEditToolsSetLayerObject,
} from '@/redux/mapEditTools/mapEditTools.slice';
import getTransformInteraction from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/interaction/getTransformInteraction';
import { useWebSocketEntityUpdatesContext } from '@/utils/websocket-entity-updates/webSocketEntityUpdatesProvider';
import processMessage from '@/components/Map/MapViewer/utils/websocket/processMessage';
import { hasPrivilegeToWriteProjectSelector } from '@/redux/user/user.selectors';
import getDrawOvalInteraction from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/interaction/getDrawOvalInteraction';
import getDrawLineInteraction from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/interaction/getDrawLineInteraction';
import getModifyLineInteraction from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/interaction/getModifyLineInteraction';
import { LAYER_ELEMENT_TYPES } from '@/components/Map/MapViewer/MapViewer.constants';

export const useOlMapAdditionalLayers = (mapInstance: MapInstance): void => {
  const activeAction = useAppSelector(mapEditToolsActiveActionSelector);
  const dispatch = useAppDispatch();
  const mapSize = useSelector(mapDataSizeSelector);
  const currentModelId = useSelector(currentModelIdSelector);

  const layersForCurrentModel = useAppSelector(layersForCurrentModelSelector);
  const layersLoading = useAppSelector(layersLoadingSelector);
  const layersVisibilityForCurrentModel = useAppSelector(layersVisibilityForCurrentModelSelector);
  const activeLayers = useAppSelector(layersActiveLayersSelector);
  const prevActiveLayersRef = useRef<number[]>([]);
  const drawLayer = useAppSelector(layersDrawLayerSelector);
  const hasPrivilegeToWriteProject = useAppSelector(hasPrivilegeToWriteProjectSelector);
  const mapEditToolsLayerNonLineObject = useAppSelector(mapEditToolsLayerNonLineObjectSelector);
  const mapEditToolsLayerLine = useAppSelector(mapEditToolsLayerLineSelector);

  const lineTypes = useSelector(lineTypesSelector);
  const arrowTypes = useSelector(arrowTypesSelector);
  const pointToProjection = usePointToProjection();

  const vectorLayersRef = useRef(
    new Map<
      number,
      VectorLayer<VectorSource<Feature<Polygon> | Feature<LineString> | Feature<MultiPolygon>>>
    >(),
  );
  const featuresToTransformRef = useRef<Collection<Feature<Geometry>>>(new Collection());
  const linesFeaturesToSelectRef = useRef<Collection<Feature<Geometry>>>(new Collection());
  const modifyFeaturesRef = useRef<Collection<Feature<Geometry>>>(new Collection());

  const getLayerFeaturesToTransform = (vectorLayer: VectorLayer): Feature<Geometry>[] => {
    const features: Array<Feature<Geometry>> = [];
    features.push(...vectorLayer.get('imagesFeatures'));
    features.push(...vectorLayer.get('rectsFeatures'));
    features.push(...vectorLayer.get('textsFeatures'));
    features.push(...vectorLayer.get('ovalsFeatures'));
    return features;
  };
  const getLayerFeaturesToModify = (vectorLayer: VectorLayer): Feature<Geometry>[] => {
    const features: Array<Feature<Geometry>> = [];
    features.push(...vectorLayer.get('linesFeatures'));
    return features;
  };

  const { lastJsonMessage } = useWebSocketEntityUpdatesContext();

  useEffect(() => {
    if (!lastJsonMessage || !('entityType' in lastJsonMessage)) {
      return;
    }

    processMessage({ jsonMessage: lastJsonMessage, mapInstance });
  }, [lastJsonMessage, mapInstance]);

  const restrictionExtent: Extent = useMemo(() => {
    const restrictionMinPoint = pointToProjection({ x: 0, y: 0 });
    const restrictionMaxPoint = pointToProjection({ x: mapSize.width, y: mapSize.height });
    return [
      restrictionMinPoint[0],
      restrictionMaxPoint[1],
      restrictionMaxPoint[0],
      restrictionMinPoint[1],
    ];
  }, [mapSize, pointToProjection]);

  const drawImageInteraction = useMemo(() => {
    if (!mapSize || !dispatch) {
      return null;
    }
    return getDrawBoundingBoxInteraction(
      mapSize,
      dispatch,
      restrictionExtent,
      openLayerImageObjectFactoryModal,
    );
  }, [mapSize, dispatch, restrictionExtent]);

  const addTextInteraction = useMemo(() => {
    if (!mapSize || !dispatch) {
      return null;
    }
    return getDrawBoundingBoxInteraction(
      mapSize,
      dispatch,
      restrictionExtent,
      openLayerTextFactoryModal,
    );
  }, [mapSize, dispatch, restrictionExtent]);

  const drawOvalInteraction = useMemo(() => {
    if (!mapSize || !dispatch) {
      return null;
    }
    return getDrawOvalInteraction(mapSize, dispatch, restrictionExtent);
  }, [mapSize, dispatch, restrictionExtent]);

  const drawLineInteraction = useMemo(() => {
    return getDrawLineInteraction({ mapSize, dispatch, restrictionExtent });
  }, [mapSize, dispatch, restrictionExtent]);

  const drawRectInteraction = useMemo(() => {
    if (!mapSize || !dispatch) {
      return null;
    }
    return getDrawBoundingBoxInteraction(
      mapSize,
      dispatch,
      restrictionExtent,
      openLayerRectFactoryModal,
    );
  }, [mapSize, dispatch, restrictionExtent]);

  useEffect(() => {
    if (!currentModelId) {
      return;
    }
    if (!['succeeded', 'pending'].includes(layersLoading)) {
      dispatch(getLayersForModel(currentModelId));
    }
  }, [currentModelId, dispatch, layersLoading]);

  useEffect(() => {
    Object.values(layersForCurrentModel).forEach(layerState => {
      const { id: layerId } = layerState.details;
      if (!vectorLayersRef.current.has(layerId)) {
        const additionalLayer = new Layer({
          zIndex: layerState.details.z,
          texts: layerState.texts,
          rects: layerState.rects,
          ovals: layerState.ovals,
          lines: layerState.lines,
          images: layerState.images,
          visible: layerState.details.visible,
          layerId,
          lineTypes,
          arrowTypes,
          mapInstance,
          mapSize,
          pointToProjection,
        });
        const { vectorLayer } = additionalLayer;
        if (!layerState.details.locked) {
          const features = getLayerFeaturesToTransform(vectorLayer);
          featuresToTransformRef.current.extend(features);
          const modifyFeatures = getLayerFeaturesToModify(vectorLayer);
          linesFeaturesToSelectRef.current.extend(modifyFeatures);
        }
        vectorLayersRef.current.set(layerId, vectorLayer);
        mapInstance?.addLayer(vectorLayer);
      }
      vectorLayersRef.current.forEach((vectorLayer, id) => {
        if (!layersForCurrentModel[id]) {
          mapInstance?.removeLayer(vectorLayer);
          vectorLayersRef.current.delete(id);
        }
      });
    });
  }, [arrowTypes, layersForCurrentModel, lineTypes, mapInstance, mapSize, pointToProjection]);

  const transformInteraction = useMemo(() => {
    if (!dispatch || !currentModelId) {
      return null;
    }
    return getTransformInteraction(
      dispatch,
      mapSize,
      currentModelId,
      featuresToTransformRef.current,
      restrictionExtent,
    );
  }, [dispatch, currentModelId, mapSize, restrictionExtent]);
  const transformRef = useRef(transformInteraction);

  useEffect(() => {
    transformRef.current = transformInteraction;
  }, [transformInteraction]);

  const modifyLineInteraction = useMemo(() => {
    if (!currentModelId) {
      return null;
    }
    return getModifyLineInteraction({
      mapSize,
      dispatch,
      modelId: currentModelId,
      featuresToSelectCollection: linesFeaturesToSelectRef.current,
      modifyFeatures: modifyFeaturesRef.current,
      restrictionExtent,
    });
  }, [currentModelId, mapSize, dispatch, restrictionExtent]);

  useEffect(() => {
    vectorLayersRef.current.forEach(layer => {
      const layerId = layer.get('id');
      if (layerId && layersVisibilityForCurrentModel[layerId] !== undefined) {
        layer.setVisible(layersVisibilityForCurrentModel[layerId]);
      }
    });
  }, [layersVisibilityForCurrentModel]);

  useEffect(() => {
    const selectedFeature = transformInteraction?.getFeatures().item(0);
    if (!selectedFeature) {
      return;
    }
    if (!layersVisibilityForCurrentModel[selectedFeature.get('layer')]) {
      transformInteraction?.setSelection(new Collection<Feature>());
    }
  }, [layersVisibilityForCurrentModel, transformInteraction]);

  useEffect(() => {
    if (!modifyLineInteraction) {
      return;
    }
    const { select } = modifyLineInteraction;
    const selectedFeature = select.getFeatures().item(0);
    if (!selectedFeature) {
      return;
    }
    if (!layersVisibilityForCurrentModel[selectedFeature.get('layer')]) {
      modifyFeaturesRef.current.clear();
      select.getFeatures().clear();
      dispatch(mapEditToolsSetLayerLine(null));
    }
  }, [dispatch, layersVisibilityForCurrentModel, modifyLineInteraction]);

  // Selecting non line feature using the layers panel
  useEffect(() => {
    if (!transformRef.current) {
      return;
    }
    const transformFeatures = transformRef.current.getFeatures();
    if (
      mapEditToolsLayerNonLineObject &&
      (!transformFeatures.getLength() ||
        transformFeatures.item(0).getId() !== mapEditToolsLayerNonLineObject.id)
    ) {
      const layer = vectorLayersRef.current.get(mapEditToolsLayerNonLineObject.layer);
      if (!layer) {
        return;
      }
      const source = layer.getSource();
      if (!source) {
        return;
      }
      const feature = source.getFeatureById(mapEditToolsLayerNonLineObject.id);
      if (!feature) {
        return;
      }
      modifyFeaturesRef.current.clear();
      modifyLineInteraction?.select.getFeatures().clear();
      transformRef.current.setSelection(new Collection<Feature>([feature]));
    }
  }, [mapEditToolsLayerNonLineObject, modifyLineInteraction]);

  // Selecting line feature using the layers panel
  useEffect(() => {
    const modifyFeatures = modifyFeaturesRef.current;
    if (
      mapEditToolsLayerLine &&
      (!modifyFeatures.getLength() || modifyFeatures.item(0).getId() !== mapEditToolsLayerLine.id)
    ) {
      const layer = vectorLayersRef.current.get(mapEditToolsLayerLine.layer);
      if (!layer) {
        return;
      }
      const source = layer.getSource();
      if (!source) {
        return;
      }
      const feature = source.getFeatureById(mapEditToolsLayerLine.id);
      if (!feature) {
        return;
      }
      transformRef.current?.setSelection(new Collection<Feature>());
      modifyFeatures.clear();
      modifyFeatures.push(feature);
      modifyLineInteraction?.select.getFeatures().clear();
      modifyLineInteraction?.select.getFeatures().push(feature);
    }
  }, [mapEditToolsLayerLine, modifyLineInteraction]);

  useEffect(() => {
    const activeVectorLayers = [...vectorLayersRef.current.values()].filter(layer => {
      const layerId = layer.get('id');
      return activeLayers.includes(layerId);
    });
    if (!activeVectorLayers.length) {
      return () => {};
    }
    const removeFeatureHandler = (): void => {
      transformInteraction?.setSelection(new Collection<Feature>());
      modifyFeaturesRef.current.clear();
      modifyLineInteraction?.select.getFeatures().clear();
      dispatch(mapEditToolsSetLayerLine(null));
    };
    const addFeatureHandler = (event: VectorSourceEvent): void => {
      const newFeature = event.feature;
      if (!newFeature) {
        return;
      }
      const elementType = newFeature.get('elementType');
      if (elementType === LAYER_ELEMENT_TYPES.LINE) {
        linesFeaturesToSelectRef.current.push(newFeature);
      } else if (
        [
          LAYER_ELEMENT_TYPES.RECT,
          LAYER_ELEMENT_TYPES.TEXT,
          LAYER_ELEMENT_TYPES.OVAL,
          LAYER_ELEMENT_TYPES.IMAGE,
        ].includes(elementType)
      ) {
        featuresToTransformRef.current.push(newFeature);
      }
    };

    activeVectorLayers.forEach(activeVectorLayer => {
      const source = activeVectorLayer.getSource();
      source?.on('removefeature', removeFeatureHandler);
      source?.on('addfeature', addFeatureHandler);
    });

    return () => {
      activeVectorLayers.forEach(activeVectorLayer => {
        const source = activeVectorLayer.getSource();
        source?.un('removefeature', removeFeatureHandler);
        source?.un('addfeature', addFeatureHandler);
      });
    };
  }, [activeLayers, transformInteraction, modifyLineInteraction, dispatch]);

  // update transform features after change active layers (lock)
  useEffect(() => {
    const prevActiveLayers = prevActiveLayersRef.current;
    if (prevActiveLayers.length > activeLayers.length) {
      const removedLayers = prevActiveLayers.filter(layer => !activeLayers.includes(layer));
      removedLayers.forEach(layer => {
        const removedLayer = vectorLayersRef.current.get(layer);
        if (removedLayer) {
          const features = getLayerFeaturesToTransform(removedLayer);
          features.forEach((feature: Feature) => {
            featuresToTransformRef.current.remove(feature);
          });
        }
      });
    } else if (prevActiveLayers.length < activeLayers.length) {
      const addedLayers = activeLayers.filter(layer => !prevActiveLayers.includes(layer));
      addedLayers.forEach(layer => {
        const addedLayer = vectorLayersRef.current.get(layer);
        if (addedLayer) {
          const features = getLayerFeaturesToTransform(addedLayer);
          features.forEach((feature: Feature) => {
            if (!featuresToTransformRef.current.getArray().includes(feature)) {
              featuresToTransformRef.current.push(feature);
            }
          });
        }
      });
    }
    prevActiveLayersRef.current = activeLayers;
  }, [activeLayers]);

  useEffect(() => {
    if (
      !transformInteraction ||
      !activeLayers.length ||
      !hasPrivilegeToWriteProject ||
      activeAction
    ) {
      return () => {};
    }
    mapInstance?.addInteraction(transformInteraction);
    return () => {
      dispatch(mapEditToolsSetLayerObject(null));
      mapInstance?.removeInteraction(transformInteraction);
    };
  }, [
    activeAction,
    activeLayers,
    dispatch,
    hasPrivilegeToWriteProject,
    mapInstance,
    transformInteraction,
  ]);

  useEffect(() => {
    if (
      !modifyLineInteraction ||
      !activeLayers.length ||
      !hasPrivilegeToWriteProject ||
      activeAction
    ) {
      return () => {};
    }
    const { modify, snap, select } = modifyLineInteraction;
    mapInstance?.addInteraction(modify);
    mapInstance?.addInteraction(snap);
    mapInstance?.addInteraction(select);
    return () => {
      dispatch(mapEditToolsSetLayerObject(null));
      mapInstance?.removeInteraction(modify);
      mapInstance?.removeInteraction(snap);
      mapInstance?.removeInteraction(select);
    };
  }, [
    activeAction,
    activeLayers,
    dispatch,
    hasPrivilegeToWriteProject,
    mapInstance,
    modifyLineInteraction,
  ]);

  useEffect(() => {
    if (!drawImageInteraction || !hasPrivilegeToWriteProject) {
      return;
    }
    mapInstance?.removeInteraction(drawImageInteraction);
    if (!drawLayer || activeAction !== MAP_EDIT_ACTIONS.DRAW_IMAGE) {
      return;
    }
    mapInstance?.addInteraction(drawImageInteraction);
  }, [
    activeAction,
    drawLayer,
    currentModelId,
    drawImageInteraction,
    mapInstance,
    hasPrivilegeToWriteProject,
  ]);

  useEffect(() => {
    const turnOffDrawInteraction = (): void => {
      dispatch(mapEditToolsSetActiveAction(null));
    };
    mapInstance?.getViewport()?.addEventListener('contextmenu', turnOffDrawInteraction);
    return () => {
      mapInstance?.getViewport()?.removeEventListener('contextmenu', turnOffDrawInteraction);
    };
  }, [dispatch, mapInstance]);

  useEffect(() => {
    if (!addTextInteraction || !hasPrivilegeToWriteProject) {
      return;
    }
    mapInstance?.removeInteraction(addTextInteraction);
    if (!drawLayer || activeAction !== MAP_EDIT_ACTIONS.ADD_TEXT) {
      return;
    }
    mapInstance?.addInteraction(addTextInteraction);
  }, [
    activeAction,
    drawLayer,
    currentModelId,
    addTextInteraction,
    mapInstance,
    hasPrivilegeToWriteProject,
  ]);

  useEffect(() => {
    if (!drawRectInteraction || !hasPrivilegeToWriteProject) {
      return;
    }
    mapInstance?.removeInteraction(drawRectInteraction);
    if (!drawLayer || activeAction !== MAP_EDIT_ACTIONS.ADD_RECT) {
      return;
    }
    mapInstance?.addInteraction(drawRectInteraction);
  }, [
    activeAction,
    drawLayer,
    currentModelId,
    drawRectInteraction,
    mapInstance,
    hasPrivilegeToWriteProject,
  ]);

  useEffect(() => {
    if (!drawOvalInteraction || !hasPrivilegeToWriteProject) {
      return;
    }
    mapInstance?.removeInteraction(drawOvalInteraction);
    if (!drawLayer || activeAction !== MAP_EDIT_ACTIONS.ADD_OVAL) {
      return;
    }
    mapInstance?.addInteraction(drawOvalInteraction);
  }, [
    activeAction,
    drawLayer,
    currentModelId,
    drawOvalInteraction,
    mapInstance,
    hasPrivilegeToWriteProject,
  ]);

  useEffect(() => {
    if (!drawLineInteraction || !hasPrivilegeToWriteProject) {
      return;
    }
    mapInstance?.removeInteraction(drawLineInteraction);
    if (!drawLayer || activeAction !== MAP_EDIT_ACTIONS.ADD_LINE) {
      return;
    }
    mapInstance?.addInteraction(drawLineInteraction);
  }, [
    activeAction,
    drawLayer,
    currentModelId,
    drawLineInteraction,
    mapInstance,
    hasPrivilegeToWriteProject,
  ]);
};
