import { OverlayBioEntityRender } from '@/types/OLrendering';
import { GetOverlayBioEntityColorByAvailableProperties } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useGetOverlayColor';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import getStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStyle';
import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import Style from 'ol/style/Style';
import { MapInstance } from '@/types/map';
import Polygon from 'ol/geom/Polygon';

export type MarkerOverlayProps = {
  markerOverlay: OverlayBioEntityRender;
  getOverlayColor: GetOverlayBioEntityColorByAvailableProperties;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
};

export default class MarkerOverlay {
  markerOverlay: OverlayBioEntityRender;

  getOverlayColor: GetOverlayBioEntityColorByAvailableProperties;

  pointToProjection: UsePointToProjectionResult;

  mapInstance: MapInstance;

  markerFeature: Feature;

  constructor({
    markerOverlay,
    getOverlayColor,
    pointToProjection,
    mapInstance,
  }: MarkerOverlayProps) {
    this.markerOverlay = markerOverlay;
    this.getOverlayColor = getOverlayColor;
    this.pointToProjection = pointToProjection;
    this.mapInstance = mapInstance;
    this.markerFeature = this.drawOverlay();
  }

  drawOverlay(): Feature {
    const color = this.getOverlayColor(this.markerOverlay);
    const polygon = new Polygon([
      [
        this.pointToProjection({ x: this.markerOverlay.x1, y: this.markerOverlay.y1 }),
        this.pointToProjection({ x: this.markerOverlay.x2, y: this.markerOverlay.y1 }),
        this.pointToProjection({ x: this.markerOverlay.x2, y: this.markerOverlay.y2 }),
        this.pointToProjection({ x: this.markerOverlay.x1, y: this.markerOverlay.y2 }),
      ],
    ]);
    const style = getStyle({
      geometry: polygon,
      fillColor: this.markerOverlay.hexColor || color,
      lineWidth: 1,
      zIndex: 99999,
    });
    const markerFeature = new Feature<Polygon>({
      geometry: polygon,
      style,
      lineWidth: 1,
    });
    markerFeature.setStyle(this.getStyle.bind(this));
    return markerFeature;
  }

  protected getStyle(feature: FeatureLike, resolution: number): Style | Array<Style> | void {
    const maxZoom = this.mapInstance?.getView().get('originalMaxZoom');
    const minResolution = this.mapInstance?.getView().getResolutionForZoom(maxZoom);
    const style = feature.get('style');
    if (!minResolution || !style) {
      return [];
    }

    const scale = minResolution / resolution;
    const lineWidth = feature.get('lineWidth') * scale;

    if (style instanceof Style && style.getStroke()) {
      style.getStroke()?.setWidth(lineWidth);
    }
    return style;
  }
}
