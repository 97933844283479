/* eslint-disable no-magic-numbers */
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import {
  layersActiveLayersSelector,
  layersVisibilityForCurrentModelSelector,
  maxLayerZIndexAboveDiagramSelector,
  maxLayerZIndexBelowDiagramSelector,
  minLayerZIndexAboveDiagramSelector,
  minLayerZIndexBelowDiagramSelector,
} from '@/redux/layers/layers.selectors';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { openLayerFactoryModal } from '@/redux/modal/modal.slice';
import QuestionModal from '@/components/FunctionalArea/Modal/QuestionModal/QustionModal.component';
import { useState, JSX, useMemo } from 'react';
import { getLayer, removeLayer, updateLayer } from '@/redux/layers/layers.thunks';
import { showToast } from '@/utils/showToast';
import { SerializedError } from '@reduxjs/toolkit';
import { LayersDrawerLayerActions } from '@/components/Map/Drawer/LayersDrawer/LayersDrawerLayerActions.component';
import {
  setDrawLayer,
  setLayerToActive,
  setLayerToInactive,
  setLayerVisibility,
} from '@/redux/layers/layers.slice';
import { LayersDrawerObjectsList } from '@/components/Map/Drawer/LayersDrawer/LayersDrawerObjectsList.component';
import { mapEditToolsSetActiveAction } from '@/redux/mapEditTools/mapEditTools.slice';
import { MAP_EDIT_ACTIONS } from '@/redux/mapEditTools/mapEditTools.constants';
import { Layer } from '@/types/models';

interface LayersDrawerLayerProps {
  layerDetails: Layer;
}

export const LayersDrawerLayer = ({ layerDetails }: LayersDrawerLayerProps): JSX.Element => {
  const layersVisibilityForCurrentModel = useAppSelector(layersVisibilityForCurrentModelSelector);
  const activeLayers = useAppSelector(layersActiveLayersSelector);
  const currentModelId = useAppSelector(currentModelIdSelector);
  const maxLayerZIndexAboveDiagram = useAppSelector(maxLayerZIndexAboveDiagramSelector);
  const maxLayerZIndexBelowDiagram = useAppSelector(maxLayerZIndexBelowDiagramSelector);
  const minLayerZIndexAboveDiagram = useAppSelector(minLayerZIndexAboveDiagramSelector);
  const minLayerZIndexBelowDiagram = useAppSelector(minLayerZIndexBelowDiagramSelector);
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isLayerVisible = useMemo(() => {
    return layersVisibilityForCurrentModel[layerDetails.id];
  }, [layerDetails.id, layersVisibilityForCurrentModel]);

  const isLayerActive = useMemo(() => {
    return activeLayers.includes(layerDetails.id);
  }, [activeLayers, layerDetails.id]);

  const editLayer = (): void => {
    dispatch(openLayerFactoryModal(layerDetails.id));
  };

  const addImage = (): void => {
    dispatch(setDrawLayer({ modelId: currentModelId, layerId: layerDetails.id }));
    dispatch(mapEditToolsSetActiveAction(MAP_EDIT_ACTIONS.DRAW_IMAGE));
  };

  const addText = (): void => {
    dispatch(setDrawLayer({ modelId: currentModelId, layerId: layerDetails.id }));
    dispatch(mapEditToolsSetActiveAction(MAP_EDIT_ACTIONS.ADD_TEXT));
  };

  const addRect = (): void => {
    dispatch(setDrawLayer({ modelId: currentModelId, layerId: layerDetails.id }));
    dispatch(mapEditToolsSetActiveAction(MAP_EDIT_ACTIONS.ADD_RECT));
  };

  const addOval = (): void => {
    dispatch(setDrawLayer({ modelId: currentModelId, layerId: layerDetails.id }));
    dispatch(mapEditToolsSetActiveAction(MAP_EDIT_ACTIONS.ADD_OVAL));
  };

  const addLine = (): void => {
    dispatch(setDrawLayer({ modelId: currentModelId, layerId: layerDetails.id }));
    dispatch(mapEditToolsSetActiveAction(MAP_EDIT_ACTIONS.ADD_LINE));
  };

  const rejectRemove = (): void => {
    setIsModalOpen(false);
  };

  const confirmRemove = async (): Promise<void> => {
    if (!layerDetails.id) {
      return;
    }
    try {
      await dispatch(removeLayer({ modelId: currentModelId, layerId: layerDetails.id })).unwrap();
      showToast({
        type: 'success',
        message: 'The layer has been successfully removed.',
      });
      setIsModalOpen(false);
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while removing the layer',
      });
    }
  };

  const onRemoveLayer = (): void => {
    setIsModalOpen(true);
  };

  const toggleActiveLayer = (value: boolean): void => {
    if (value) {
      dispatch(setLayerToActive({ modelId: currentModelId, layerId: layerDetails.id }));
    } else {
      dispatch(setLayerToInactive({ modelId: currentModelId, layerId: layerDetails.id }));
    }
  };

  const updateLayerZIndex = async (zIndex: number): Promise<void> => {
    try {
      await dispatch(
        updateLayer({
          name: layerDetails.name,
          visible: layerDetails.visible,
          locked: layerDetails.locked,
          modelId: currentModelId,
          layerId: layerDetails.id,
          zIndex,
        }),
      );
      await dispatch(getLayer({ modelId: currentModelId, layerId: layerDetails.id }));
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while updating the layer',
      });
    }
  };

  const moveToFront = (): void => {
    if (layerDetails.z > 0) {
      updateLayerZIndex(maxLayerZIndexAboveDiagram + 1);
    } else if (layerDetails.z < 0) {
      const zIndex = Math.min(maxLayerZIndexBelowDiagram + 1, -1);
      updateLayerZIndex(zIndex);
    }
  };

  const moveToBack = (): void => {
    if (layerDetails.z > 0) {
      const zIndex = Math.max(minLayerZIndexAboveDiagram - 1, 1);
      updateLayerZIndex(zIndex);
    } else if (layerDetails.z < 0) {
      updateLayerZIndex(minLayerZIndexBelowDiagram - 1);
    }
  };

  const moveAboveDiagram = (): void => {
    const zIndex = Math.max(maxLayerZIndexAboveDiagram + 1, 1);
    updateLayerZIndex(zIndex);
  };

  const moveBelowDiagram = (): void => {
    const zIndex = Math.min(minLayerZIndexBelowDiagram - 1, -1);
    updateLayerZIndex(zIndex);
  };

  return (
    <div>
      <QuestionModal
        isOpen={isModalOpen}
        onClose={rejectRemove}
        onConfirm={confirmRemove}
        question="Are you sure you want to remove the layer?"
      />
      <div className="flex items-center justify-between pb-3">
        <span
          className={`font-semibold ${
            isLayerVisible ? 'opacity-100' : 'opacity-40'
          } min-w-0 flex-1 truncate `}
        >
          {layerDetails.name}
        </span>
        <LayersDrawerLayerActions
          toggleVisibility={() =>
            dispatch(
              setLayerVisibility({
                modelId: currentModelId,
                visible: !isLayerVisible,
                layerId: layerDetails.id,
              }),
            )
          }
          toggleActiveLayer={toggleActiveLayer}
          editLayer={editLayer}
          removeLayer={onRemoveLayer}
          addImage={addImage}
          addText={addText}
          addRect={addRect}
          addOval={addOval}
          addLine={addLine}
          moveToFront={moveToFront}
          moveToBack={moveToBack}
          moveAboveDiagram={moveAboveDiagram}
          moveBelowDiagram={moveBelowDiagram}
          zIndex={layerDetails.z}
          isVisible={isLayerVisible}
          isActive={isLayerActive}
        />
      </div>
      <LayersDrawerObjectsList
        layerId={layerDetails.id}
        isLayerVisible={isLayerVisible}
        isLayerActive={isLayerActive}
      />
    </div>
  );
};
