import { createSelector } from '@reduxjs/toolkit';
import { DEFAULT_GROUP } from '@/components/Map/Drawer/OverlaysDrawer/UserOverlayForm/UserOverlayForm.constants';
import { OverlayGroup } from '@/types/models';
import { rootSelector } from '../root/root.selectors';

const overlayGroupsDataSelector = createSelector(rootSelector, state => state.overlayGroups);

export const overlayGroupsSelector = createSelector(overlayGroupsDataSelector, overlayGroup => {
  let result: OverlayGroup[] = [DEFAULT_GROUP];
  if (overlayGroup?.data) {
    result = result.concat(overlayGroup?.data);
  }
  return result;
});

export const overlayGroupSelector = createSelector(
  [overlayGroupsSelector, (_, overlayGroupId: number | null): number | null => overlayGroupId],
  (groups, overlayGroupId) => groups.find(group => group.id === overlayGroupId),
);
