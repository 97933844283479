import { DRAWER_ROLE } from '@/components/Map/Drawer/Drawer.constants';
import { drawerSelector } from '@/redux/drawer/drawer.selectors';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { twMerge } from 'tailwind-merge';
import { CommentDrawer } from '@/components/Map/Drawer/CommentDrawer';
import { LayersDrawer } from '@/components/Map/Drawer/LayersDrawer/LayersDrawer.component';
import { OverlayGroupDrawer } from '@/components/Map/Drawer/OverlayGroupDrawer';
import { AvailablePluginsDrawer } from './AvailablePluginsDrawer';
import { BioEntityDrawer } from './BioEntityDrawer/BioEntityDrawer.component';
import { ExportDrawer } from './ExportDrawer';
import { OverlaysDrawer } from './OverlaysDrawer';
import { ProjectInfoDrawer } from './ProjectInfoDrawer';
import { ReactionDrawer } from './ReactionDrawer';
import { SearchDrawerWrapper as SearchDrawerContent } from './SearchDrawerWrapper';
import { SubmapsDrawer } from './SubmapsDrawer';

export const Drawer = (): JSX.Element => {
  const { isOpen, drawerName } = useAppSelector(drawerSelector);

  return (
    <div
      className={twMerge(
        'absolute bottom-0 left-[88px] top-[104px] z-10 h-calc-drawer w-[432px] -translate-x-full transform border border-divide bg-white-pearl text-font-500 transition-all duration-500',
        isOpen && 'translate-x-0',
      )}
      role={DRAWER_ROLE}
    >
      {isOpen && drawerName === 'search' && <SearchDrawerContent />}
      {isOpen && drawerName === 'submaps' && <SubmapsDrawer />}
      {isOpen && drawerName === 'reaction' && <ReactionDrawer />}
      {isOpen && drawerName === 'overlays' && <OverlaysDrawer />}
      {isOpen && drawerName === 'bio-entity' && <BioEntityDrawer />}
      {isOpen && drawerName === 'overlay-group' && <OverlayGroupDrawer />}
      {isOpen && drawerName === 'project-info' && <ProjectInfoDrawer />}
      {isOpen && drawerName === 'export' && <ExportDrawer />}
      {isOpen && drawerName === 'available-plugins' && <AvailablePluginsDrawer />}
      {isOpen && drawerName === 'comment' && <CommentDrawer />}
      {isOpen && drawerName === 'layers' && <LayersDrawer />}
    </div>
  );
};
