/* eslint-disable no-magic-numbers */
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { loadingUserOverlaysSelector } from '@/redux/overlays/overlays.selectors';
import { getAllUserOverlaysByCreator, updateOverlays } from '@/redux/overlays/overlays.thunks';
import { MapOverlay } from '@/types/models';
import { useEffect, useState } from 'react';
import { moveArrayElement } from '../UserOverlaysGroup.utils';

type UseUserOverlaysReturn = {
  isPending: boolean;
  userOverlaysList: MapOverlay[];
  moveUserOverlayListItem: (dragIndex: number, hoverIndex: number) => void;
  updateUserOverlaysOrder: (overlay: MapOverlay, targetGroupId: number | null) => void;
};

export const useUserOverlays = (
  userOverlays: MapOverlay[],
  groupId: number | null,
): UseUserOverlaysReturn => {
  const dispatch = useAppDispatch();
  const [userOverlaysList, setUserOverlaysList] = useState<MapOverlay[]>([]);
  const loadingUserOverlays = useAppSelector(loadingUserOverlaysSelector);
  const isPending = loadingUserOverlays === 'pending';

  useEffect(() => {
    if (userOverlays) {
      setUserOverlaysList(userOverlays);
    }
  }, [userOverlays]);

  const moveUserOverlayListItem = (dragIndex: number, hoverIndex: number): void => {
    // eslint-disable-next-line no-console
    const updatedUserOverlays = moveArrayElement(userOverlaysList, dragIndex, hoverIndex);
    setUserOverlaysList(updatedUserOverlays);
  };

  const updateUserOverlaysOrder = async (
    overlay: MapOverlay,
    targetGroupId: number | null,
  ): Promise<void> => {
    const reorderedUserOverlays = [];
    if (!userOverlays) return;

    if (targetGroupId !== overlay.group) {
      const newOverlay = {
        ...overlay,
        group: targetGroupId,
      };
      reorderedUserOverlays.push(newOverlay);
    }
    for (let index = 0; index < userOverlays.length; index += 1) {
      const userOverlay = userOverlays[index];
      const newOrderedUserOverlay = {
        ...userOverlaysList[index],
        order: index + 1,
        group: groupId,
      };

      if (userOverlay.id !== newOrderedUserOverlay.id) {
        reorderedUserOverlays.push(newOrderedUserOverlay);
      }
    }
    await dispatch(updateOverlays(reorderedUserOverlays));
    dispatch(getAllUserOverlaysByCreator());
  };

  return {
    moveUserOverlayListItem,
    updateUserOverlaysOrder,
    isPending,
    userOverlaysList,
  };
};
