import { useState, ChangeEvent } from 'react';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { overlayGroupsSelector } from '@/redux/overlayGroup/overlayGroup.selectors';
import { addOverlayGroup } from '@/redux/overlayGroup/overlayGroup.thunks';

type ReturnType = {
  name: string;
  handleChangeName: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => Promise<void>;
};

export const useOverlayGroupForm = (): ReturnType => {
  const dispatch = useAppDispatch();
  const overlayGroups = useAppSelector(overlayGroupsSelector);

  const [name, setName] = useState('');

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };
  const handleSubmit = async (): Promise<void> => {
    if (!name) return;

    dispatch(
      addOverlayGroup({
        name,
        order: overlayGroups.length,
      }),
    );

    setName('');
  };

  return {
    name,
    handleChangeName,
    handleSubmit,
  };
};
