import { MODEL_ELEMENT, DRUGS_CHEMICALS } from '@/constants';
import { STEP } from '@/constants/searchDrawer';
import {
  currentStepDrawerStateSelector,
  stepTypeDrawerSelector,
} from '@/redux/drawer/drawer.selectors';
import { useSelector } from 'react-redux';
import { ResultsList } from '@/components/Map/Drawer/SearchDrawerWrapper/ResultsList';
import { GroupedSearchResults } from '@/components/Map/Drawer/SearchDrawerWrapper/GroupedSearchResults';
import { BioEntitiesResultsList } from './BioEntitiesResultsList';
import { SearchDrawerHeader } from './SearchDrawerHeader';
import { SearchDrawerTabs } from './SearchDrawerTabs';

export const SearchDrawerWrapper = (): JSX.Element => {
  const currentStep = useSelector(currentStepDrawerStateSelector);
  const stepType = useSelector(stepTypeDrawerSelector);

  const isBioEntityType = stepType === MODEL_ELEMENT;
  const isChemicalsOrDrugsType = DRUGS_CHEMICALS.includes(stepType);

  return (
    <>
      <SearchDrawerHeader />
      <SearchDrawerTabs />
      <div data-testid="search-drawer-content" className="h-full max-h-full">
        {/* first step for displaying search results, drawers etc */}
        {currentStep === STEP.FIRST && <GroupedSearchResults />}
        {/* 2nd step for bioEntities aka content */}
        {currentStep === STEP.SECOND && isBioEntityType && (
          <div data-testid="search-second-step" className="h-full">
            <BioEntitiesResultsList />
          </div>
        )}
        {/* 2nd step for drugs,chemicals */}
        {currentStep === STEP.SECOND && isChemicalsOrDrugsType && (
          <div data-testid="search-second-step" className="h-full">
            <ResultsList />
          </div>
        )}
      </div>
    </>
  );
};
