import React, { useState, JSX, useRef, useEffect } from 'react';
import { IconButton } from '@/shared/IconButton';
import { LayerDrawerLayerContextMenuItems } from '@/components/Map/Drawer/LayersDrawer/LayersDrawerLayerContextMenuItems.component';

type LayerDrawerLayerContextMenuProps = {
  removeLayer: () => void;
  editLayer: () => void;
  addImage: () => void;
  addText: () => void;
  addRect: () => void;
  addOval: () => void;
  addLine: () => void;
};

export const LayerDrawerLayerContextMenu = ({
  removeLayer,
  editLayer,
  addImage,
  addText,
  addRect,
  addOval,
  addLine,
}: LayerDrawerLayerContextMenuProps): JSX.Element => {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);
  const dotsRef = useRef<HTMLDivElement>(null);

  const toggleMenu = (): void => {
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (!dotsRef.current || dotsRef.current.contains(event.target as Node)) {
        return;
      }
      if (!menuRef.current || menuRef.current.contains(event.target as Node)) {
        return;
      }
      setMenuVisible(false);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative inline-block">
      <div ref={dotsRef}>
        <IconButton
          icon="dots"
          title="Switch visibility"
          className="h-auto w-auto bg-transparent p-0"
          onClick={toggleMenu}
        />
      </div>
      {menuVisible && (
        <LayerDrawerLayerContextMenuItems
          ref={menuRef}
          removeLayer={() => {
            setMenuVisible(false);
            removeLayer();
          }}
          editLayer={() => {
            setMenuVisible(false);
            editLayer();
          }}
          addImage={() => {
            setMenuVisible(false);
            addImage();
          }}
          addText={() => {
            setMenuVisible(false);
            addText();
          }}
          addRect={() => {
            setMenuVisible(false);
            addRect();
          }}
          addOval={() => {
            setMenuVisible(false);
            addOval();
          }}
          addLine={() => {
            setMenuVisible(false);
            addLine();
          }}
        />
      )}
    </div>
  );
};
