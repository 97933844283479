import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useMemo } from 'react';
import Polygon from 'ol/geom/Polygon';
import { useSelector } from 'react-redux';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { usePointToProjection } from '@/utils/map/usePointToProjection';
import Style from 'ol/style/Style';
import { Fill } from 'ol/style';
import { LAYER_TYPE } from '@/components/Map/MapViewer/MapViewer.constants';

export const useOlMapCardLayer = (): VectorLayer<VectorSource<Feature<Polygon>>> => {
  const mapSize = useSelector(mapDataSizeSelector);
  const pointToProjection = usePointToProjection();

  const rectangle = useMemo(() => {
    return new Polygon([
      [
        pointToProjection({ x: 0, y: 0 }),
        pointToProjection({ x: mapSize.width, y: 0 }),
        pointToProjection({ x: mapSize.width, y: mapSize.height }),
        pointToProjection({ x: 0, y: mapSize.height }),
        pointToProjection({ x: 0, y: 0 }),
      ],
    ]);
  }, [mapSize.height, mapSize.width, pointToProjection]);

  const rectangleFeature = useMemo(() => {
    return new Feature(rectangle);
  }, [rectangle]);

  const vectorSource = useMemo(() => {
    return new VectorSource({
      features: [rectangleFeature],
    });
  }, [rectangleFeature]);

  return useMemo(() => {
    const vectorLayer = new VectorLayer({
      zIndex: -Infinity,
      source: vectorSource,
      style: new Style({
        fill: new Fill({
          color: '#fff',
        }),
      }),
    });
    vectorLayer.set('type', LAYER_TYPE.MAP_CARD_LAYER);
    return vectorLayer;
  }, [vectorSource]);
};
