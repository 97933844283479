import React, { useState, useEffect, useRef } from 'react';
import ColorPicker, { Color, ColorPickerProps } from '@rc-component/color-picker';
import { Color as RgbIntAlphaColor } from '@/types/models';
import '@rc-component/color-picker/assets/index.css';
import { rgbToHex } from '@/components/Map/MapViewer/utils/mapElementsRendering/style/rgbToHex';

type ColorTilePickerProps = {
  initialColor?: RgbIntAlphaColor;
  colorChange: (color: string) => void;
  height?: string;
  testId?: string;
};

export const ColorTilePicker: React.FC<ColorTilePickerProps> = ({
  initialColor,
  colorChange,
  height = '40px',
  testId = 'color-tile-picker',
}) => {
  const [color, setColor] = useState<Color>(
    () => new Color(initialColor ? rgbToHex(initialColor) : '#000000'),
  );
  const [visible, setVisible] = useState<boolean>(false);

  const pickerRef = useRef<HTMLDivElement>(null); // Referencja do elementu pickera

  const handleChange: ColorPickerProps['onChange'] = (newColor: Color) => {
    setColor(newColor);
    colorChange(newColor.toHexString());
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      setVisible(!visible);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div data-testid={testId} style={{ position: 'relative' }}>
      <div
        className="w-full cursor-pointer rounded shadow-primary"
        role="button"
        tabIndex={0}
        onClick={() => setVisible(!visible)}
        onKeyDown={handleKeyPress}
        style={{
          height,
          backgroundColor: color.toHexString(),
        }}
      />
      {visible && (
        <div ref={pickerRef} className="absolute right-full top-0" data-testid="color-picker">
          <ColorPicker value={color} onChange={handleChange} />
        </div>
      )}
    </div>
  );
};
