import React, { forwardRef, JSX } from 'react';
import { Icon } from '@/shared/Icon';

type LayerDrawerLayerContextMenuProps = {
  removeLayer: () => void;
  editLayer: () => void;
  addImage: () => void;
  addText: () => void;
  addRect: () => void;
  addOval: () => void;
  addLine: () => void;
};

export const LayerDrawerLayerContextMenuItems = forwardRef<
  HTMLUListElement,
  LayerDrawerLayerContextMenuProps
>(
  (
    {
      removeLayer,
      editLayer,
      addImage,
      addText,
      addRect,
      addOval,
      addLine,
    }: LayerDrawerLayerContextMenuProps,
    ref,
  ): JSX.Element => {
    const handleKeyPress = (): void => {};

    return (
      <ul
        ref={ref}
        className="absolute right-[24px] top-[-14px] z-[1] mt-2 w-[12rem] rounded border bg-white p-2 shadow-md"
      >
        <li
          className="flex min-h-[24px] cursor-pointer gap-3 px-4 py-1 hover:bg-gray-200"
          tabIndex={0}
          onClick={addImage}
          onKeyDown={handleKeyPress}
          role="menuitem"
        >
          <Icon name="image" />
          <span>Add glyph</span>
        </li>
        <li
          className="flex min-h-[24px] cursor-pointer gap-3 px-4 py-1 hover:bg-gray-200"
          tabIndex={0}
          onClick={addText}
          onKeyDown={handleKeyPress}
          role="menuitem"
        >
          <Icon name="text" />
          <span>Add text</span>
        </li>
        <li
          className="flex min-h-[24px] cursor-pointer gap-3 px-4 py-1 hover:bg-gray-200"
          tabIndex={0}
          onClick={addRect}
          onKeyDown={handleKeyPress}
          role="menuitem"
        >
          <Icon name="rectangle" />
          <span>Add rectangle</span>
        </li>
        <li
          className="flex min-h-[24px] cursor-pointer gap-3 px-4 py-1 hover:bg-gray-200"
          tabIndex={0}
          onClick={addOval}
          onKeyDown={handleKeyPress}
          role="menuitem"
        >
          <Icon name="oval" />
          <span>Add oval</span>
        </li>
        <li
          className="flex min-h-[24px] cursor-pointer gap-3 px-4 py-1 hover:bg-gray-200"
          tabIndex={0}
          onClick={addLine}
          onKeyDown={handleKeyPress}
          role="menuitem"
        >
          <Icon name="line" />
          <span>Add line</span>
        </li>
        <li
          className="flex min-h-[24px] cursor-pointer gap-3 px-4 py-1 hover:bg-gray-200"
          tabIndex={0}
          onClick={editLayer}
          onKeyDown={handleKeyPress}
          role="menuitem"
        >
          <Icon name="edit" className="stroke-black" />
          <span>Edit layer</span>
        </li>
        <hr />
        <li
          className="flex min-h-[24px] cursor-pointer gap-3 px-4 py-1 hover:bg-gray-200"
          tabIndex={0}
          onClick={removeLayer}
          onKeyDown={handleKeyPress}
          role="menuitem"
        >
          <Icon name="trash" className="fill-red-600" />
          <span>Delete layer</span>
        </li>
      </ul>
    );
  },
);

LayerDrawerLayerContextMenuItems.displayName = 'LayerDrawerLayerContextMenuItems';
