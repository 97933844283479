import { BASE_API_URL, PROJECT_ID } from '@/constants';
import { mapBackgroundSelector, mapDataSizeSelector } from '@/redux/map/map.selectors';
import { useSelector } from 'react-redux';

export type GetSubmapDownloadUrl = ({
  handler,
  modelId,
}: {
  handler: string;
  modelId: number;
}) => string;

export const useGetSubmapDownloadUrl = (): GetSubmapDownloadUrl => {
  const background = useSelector(mapBackgroundSelector);
  const mapSize = useSelector(mapDataSizeSelector);

  const getSubmapDownloadUrl: GetSubmapDownloadUrl = ({ handler, modelId }) => {
    const allParamsValid = [modelId, background, mapSize.maxZoom, handler].reduce(
      (a, b) => Boolean(a) && Boolean(b),
      true,
    );
    if (!allParamsValid) {
      return '';
    }

    return `${BASE_API_URL}/projects/${PROJECT_ID}/models/${modelId}:downloadModel?backgroundOverlayId=${background}&handlerClass=${handler}&zoomLevel=${mapSize.maxZoom}`;
  };

  return getSubmapDownloadUrl;
};
