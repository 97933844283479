/* eslint-disable no-magic-numbers */
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import Style from 'ol/style/Style';
import { Point } from 'ol/geom';
import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { MapInstance } from '@/types/map';
import { Color, LayerText as LayerTextModel } from '@/types/models';
import {
  LAYER_ELEMENT_TYPES,
  TEXT_CUTOFF_FONTSIZE,
} from '@/components/Map/MapViewer/MapViewer.constants';
import {
  BoundingBox,
  HorizontalAlign,
  VerticalAlign,
} from '@/components/Map/MapViewer/MapViewer.types';
import getTextCoords from '@/components/Map/MapViewer/utils/mapElementsRendering/text/getTextCoords';
import getTextStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/text/getTextStyle';
import { rgbToHex } from '@/components/Map/MapViewer/utils/mapElementsRendering/style/rgbToHex';
import Polygon from 'ol/geom/Polygon';
import getStroke from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStroke';
import getStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStyle';
import getScaledElementStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getScaledElementStyle';
import { Stroke } from 'ol/style';
import { MapSize } from '@/redux/map/map.types';
import getBoundingBoxFromExtent from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getBoundingBoxFromExtent';
import { Coordinate } from 'ol/coordinate';
import { store } from '@/redux/store';
import { updateLayerText } from '@/redux/layers/layers.thunks';
import { layerUpdateText } from '@/redux/layers/layers.slice';
import { mapEditToolsSetLayerObject } from '@/redux/mapEditTools/mapEditTools.slice';
import getBoundingBoxPolygon from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/utils/getBoundingBoxPolygon';

export interface LayerTextProps {
  elementId: number;
  x: number;
  y: number;
  width: number;
  height: number;
  layer: number;
  zIndex: number;
  text: string;
  fontSize: number;
  color: Color;
  borderColor: Color;
  backgroundColor: Color;
  verticalAlign: VerticalAlign;
  horizontalAlign: HorizontalAlign;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  mapSize: MapSize;
}

export default class LayerText {
  elementId: number;

  x: number;

  y: number;

  zIndex: number;

  width: number;

  height: number;

  layer: number;

  text: string;

  verticalAlign: VerticalAlign;

  horizontalAlign: HorizontalAlign;

  backgroundColor: Color;

  borderColor: Color;

  color: Color;

  fontSize: number;

  style: Style = new Style();

  polygonStyle: Style = new Style();

  polygon: Polygon = new Polygon([]);

  strokeStyle: Stroke = new Stroke();

  point: Point;

  feature: Feature<Polygon>;

  mapSize: MapSize;

  pointToProjection: UsePointToProjectionResult;

  constructor({
    elementId,
    x,
    y,
    width,
    height,
    layer,
    zIndex,
    text,
    fontSize,
    color,
    borderColor,
    backgroundColor,
    verticalAlign,
    horizontalAlign,
    pointToProjection,
    mapInstance,
    mapSize,
  }: LayerTextProps) {
    this.text = text;
    this.fontSize = fontSize;
    this.elementId = elementId;
    this.x = x;
    this.y = y;
    this.zIndex = zIndex;
    this.width = width;
    this.height = height;
    this.layer = layer;
    this.text = text;
    this.verticalAlign = verticalAlign;
    this.horizontalAlign = horizontalAlign;
    this.backgroundColor = backgroundColor;
    this.borderColor = borderColor;
    this.color = color;
    this.mapSize = mapSize;
    this.pointToProjection = pointToProjection;

    const lines = this.text.split(/\r\n|\r|\n/).length;
    const textCoords = getTextCoords({
      x,
      y,
      lines,
      height,
      width,
      fontSize,
      verticalAlign,
      horizontalAlign,
      pointToProjection,
    });
    this.point = new Point(textCoords);

    this.polygon = getBoundingBoxPolygon({
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      pointToProjection: this.pointToProjection,
    });

    this.setStyles();

    this.feature = new Feature({
      geometry: this.polygon,
      getScale: (resolution: number): number => {
        const maxZoom = mapInstance?.getView().get('originalMaxZoom');
        if (maxZoom) {
          const minResolution = mapInstance?.getView().getResolutionForZoom(maxZoom);
          if (minResolution) {
            return Math.round((minResolution / resolution) * 100) / 100;
          }
        }
        return 1;
      },
      elementType: LAYER_ELEMENT_TYPES.TEXT,
      layer,
    });
    this.feature.setId(this.elementId);
    this.feature.set('getObjectData', this.getData.bind(this));
    this.feature.set('setCoordinates', this.setCoordinates.bind(this));
    this.feature.set('refreshPolygon', this.refreshPolygon.bind(this));
    this.feature.set('save', this.save.bind(this));
    this.feature.set('updateElement', this.updateElement.bind(this));
    this.feature.setStyle(this.getStyle.bind(this));
  }

  private getData(): LayerTextModel {
    return {
      id: this.elementId,
      x: this.x,
      y: this.y,
      z: this.zIndex,
      width: this.width,
      height: this.height,
      layer: this.layer,
      fontSize: this.fontSize,
      notes: this.text,
      verticalAlign: this.verticalAlign,
      horizontalAlign: this.horizontalAlign,
      backgroundColor: this.backgroundColor,
      borderColor: this.borderColor,
      color: this.color,
    };
  }

  private async save({
    modelId,
    boundingBox,
  }: {
    modelId: number;
    boundingBox: BoundingBox;
  }): Promise<void> {
    const { dispatch } = store;
    const layerText = await dispatch(
      updateLayerText({
        modelId,
        layerId: this.layer,
        ...this.getData(),
        ...boundingBox,
      }),
    ).unwrap();
    if (layerText) {
      dispatch(layerUpdateText({ modelId, layerId: layerText.layer, layerText }));
      dispatch(mapEditToolsSetLayerObject(layerText));
      this.updateElement(layerText);
    }
  }

  private refreshPolygon(): void {
    this.polygon = getBoundingBoxPolygon({
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      pointToProjection: this.pointToProjection,
    });
    this.polygonStyle.setGeometry(this.polygon);
    this.feature.setGeometry(this.polygon);
    this.feature.changed();
  }

  private setStyles(): void {
    this.strokeStyle = getStroke({
      color: rgbToHex(this.borderColor),
      width: 1,
    });
    this.polygonStyle = getStyle({
      geometry: this.polygon,
      borderColor: this.borderColor,
      fillColor: { rgb: 0, alpha: 0 },
      lineWidth: 1,
      zIndex: this.zIndex,
    });
    this.style = getTextStyle({
      text: this.text,
      fontSize: this.fontSize,
      color: rgbToHex(this.color),
      zIndex: this.zIndex,
      horizontalAlign: this.horizontalAlign,
    });
    this.style.setGeometry(this.point);
  }

  private updateElement(layerText: LayerTextModel): void {
    this.elementId = layerText.id;
    this.x = layerText.x;
    this.y = layerText.y;
    this.zIndex = layerText.z;
    this.width = layerText.width;
    this.height = layerText.height;
    this.text = layerText.notes;
    this.fontSize = layerText.fontSize;
    this.color = layerText.color;
    this.borderColor = layerText.borderColor;
    this.verticalAlign = layerText.verticalAlign;
    this.horizontalAlign = layerText.horizontalAlign;

    this.refreshPolygon();
    this.setStyles();
    this.feature.changed();
  }

  private setCoordinates(coords: Coordinate[][]): void {
    const geometry = this.polygonStyle.getGeometry();
    if (geometry && geometry instanceof Polygon) {
      geometry.setCoordinates(coords);
    }
  }

  protected getStyle(feature: FeatureLike, resolution: number): Style | Array<Style> | void {
    const getScale = feature.get('getScale');
    let scale = 1;
    if (getScale instanceof Function) {
      scale = getScale(resolution);
    }
    const geometry = feature.getGeometry();
    if (geometry && geometry instanceof Polygon) {
      const polygonExtent = geometry.getExtent();
      if (polygonExtent) {
        const lines = this.text.split(/\r\n|\r|\n/).length;
        const boundingBox = getBoundingBoxFromExtent(polygonExtent, this.mapSize);
        const textCoords = getTextCoords({
          x: boundingBox.x,
          y: boundingBox.y,
          lines,
          height: boundingBox.height,
          width: boundingBox.width,
          fontSize: this.fontSize,
          verticalAlign: this.verticalAlign,
          horizontalAlign: this.horizontalAlign,
          pointToProjection: this.pointToProjection,
        });
        this.point.setCoordinates(textCoords);
      }
    }

    if (scale * this.fontSize < TEXT_CUTOFF_FONTSIZE) {
      return undefined;
    }
    return [
      getScaledElementStyle(this.polygonStyle, this.strokeStyle, scale),
      getScaledElementStyle(this.style, undefined, scale),
    ];
  }
}
