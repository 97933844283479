import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { currentEditedOverlaySelector } from '@/redux/modal/modal.selector';
import { closeModal } from '@/redux/modal/modal.slice';
import {
  getAllPublicOverlaysByProjectId,
  getAllUserOverlaysByCreator,
  removeOverlay,
  updateOverlays,
} from '@/redux/overlays/overlays.thunks';
import { loginUserSelector } from '@/redux/user/user.selectors';
import { MapOverlay, OverlayGroup } from '@/types/models';
import React, { useState } from 'react';
import { overlayGroupsSelector } from '@/redux/overlayGroup/overlayGroup.selectors';
import { ZERO } from '@/constants/common';
import { PROJECT_ID } from '@/constants';

type UseEditOverlayReturn = {
  name: string | undefined;
  description: string | undefined;
  group: OverlayGroup;
  overlayGroups: OverlayGroup[];
  handleCancelEdit: () => void;
  handleRemoveOverlay: () => void;
  handleSaveEditedOverlay: () => Promise<void>;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleGroupChange: (value: OverlayGroup) => void;
  handleDescriptionChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

type UpdatedOverlay = {
  editedOverlay: MapOverlay;
  overlayName: string;
  overlayDescription: string;
  overlayGroup: OverlayGroup;
};

export const useEditOverlay = (): UseEditOverlayReturn => {
  const overlayGroups = useAppSelector(overlayGroupsSelector);
  const currentEditedOverlay = useAppSelector(currentEditedOverlaySelector);
  const login = useAppSelector(loginUserSelector);
  const dispatch = useAppDispatch();
  const [name, setName] = useState(currentEditedOverlay?.name);
  const [description, setDescription] = useState(currentEditedOverlay?.description);
  const [group, setGroup] = useState<OverlayGroup>(
    overlayGroups.filter(overlayGroup => overlayGroup.id === currentEditedOverlay?.group)[ZERO],
  );

  const handleCancelEdit = (): void => {
    dispatch(closeModal());
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setDescription(e.target.value);
  };
  const handleGroupChange = (value: OverlayGroup): void => {
    setGroup(value);
  };

  const handleRemoveOverlay = (): void => {
    if (!login || !currentEditedOverlay) return;
    dispatch(removeOverlay({ overlayId: currentEditedOverlay.id }));
  };

  const handleUpdateOverlay = async ({
    editedOverlay,
    overlayDescription,
    overlayName,
    overlayGroup,
  }: UpdatedOverlay): Promise<void> => {
    await dispatch(
      updateOverlays([
        {
          ...editedOverlay,
          name: overlayName,
          description: overlayDescription,
          group: overlayGroup.id,
        },
      ]),
    );
  };

  const getUserOverlaysByCreator = async (): Promise<void> => {
    await dispatch(getAllUserOverlaysByCreator());
  };

  const handleCloseModal = (): void => {
    dispatch(closeModal());
  };

  const handleSaveEditedOverlay = async (): Promise<void> => {
    if (!currentEditedOverlay || !name || !login) return;
    await handleUpdateOverlay({
      editedOverlay: currentEditedOverlay,
      overlayDescription: description || '',
      overlayName: name,
      overlayGroup: group,
    });

    await getUserOverlaysByCreator();
    dispatch(getAllPublicOverlaysByProjectId(PROJECT_ID));

    handleCloseModal();
  };

  return {
    handleCancelEdit,
    handleRemoveOverlay,
    handleSaveEditedOverlay,
    handleNameChange,
    handleDescriptionChange,
    handleGroupChange,
    name,
    description,
    group,
    overlayGroups,
  };
};
