import { handleDataReset } from '@/components/Map/MapViewer/utils/listeners/mouseClick/handleDataReset';
import { store } from '@/redux/store';
import { mapDataSizeSelector } from '@/redux/map/map.selectors';
import { resultDrawerOpen } from '@/redux/drawer/drawer.selectors';
import { MapInstance } from '@/types/map';
import getFeatureAtCoordinate from '@/components/Map/MapViewer/utils/listeners/mouseClick/getFeatureAtCoordinate';
import { pointToLngLat } from '@/utils/map/pointToLatLng';
import { fromLonLat } from 'ol/proj';
import { FEATURE_TYPE } from '@/constants/features';
import { leftClickHandleAlias } from '@/components/Map/MapViewer/utils/listeners/mouseClick/mouseLeftClick/leftClickHandleAlias';
import { clickHandleReaction } from '@/components/Map/MapViewer/utils/listeners/mouseClick/clickHandleReaction';
import { modelElementsByModelIdSelector } from '@/redux/modelElements/modelElements.selector';
import { newReactionsByModelIdSelector } from '@/redux/newReactions/newReactions.selectors';
import { closeDrawer } from '@/redux/drawer/drawer.slice';
import { resetReactionsData } from '@/redux/reactions/reactions.slice';
import { clearSearchModelElements } from '@/redux/modelElements/modelElements.slice';
import { Coordinates } from './triggerSearch.types';

export const searchByCoordinates = async (
  mapInstance: MapInstance,
  coordinates: Coordinates,
  modelId: number,
  hasFitBounds?: boolean,
): Promise<void> => {
  const { dispatch, getState } = store;
  // side-effect below is to prevent complications with data update - old data may conflict with new data
  // so we need to reset all the data before updating
  dispatch(handleDataReset);

  const mapSize = mapDataSizeSelector(getState());
  const isResultDrawerOpen = resultDrawerOpen(getState());
  const newReactions = newReactionsByModelIdSelector(getState(), modelId);
  const modelElements = modelElementsByModelIdSelector(getState(), modelId);
  const [lng, lat] = pointToLngLat(coordinates, mapSize);
  const projection = fromLonLat([lng, lat]);
  const coordinate = projection.map(v => Math.round(v));
  const searchResultVector = getFeatureAtCoordinate({
    mapInstance,
    coordinate,
    point: coordinates,
  });

  if (!searchResultVector) {
    if (isResultDrawerOpen) {
      dispatch(closeDrawer());
    }

    dispatch(resetReactionsData());
    dispatch(clearSearchModelElements());
    return;
  }

  const type = searchResultVector.get('type');
  const id = searchResultVector.get('id');
  if ([FEATURE_TYPE.ALIAS, FEATURE_TYPE.GLYPH, FEATURE_TYPE.COMPARTMENT].includes(type)) {
    await leftClickHandleAlias(dispatch, hasFitBounds)(modelElements, searchResultVector, modelId);
  } else if (type === FEATURE_TYPE.REACTION) {
    clickHandleReaction(dispatch)(modelElements, newReactions, id, modelId);
  }
};
