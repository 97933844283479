/* eslint-disable @next/next/no-img-element */
import { BASE_API_URL, PROJECT_ID } from '@/constants';
import { MapOverlay } from '@/types/models';

interface Props {
  overlay: Pick<MapOverlay, 'name' | 'id'>;
}

export const OverlaySingleLegend = ({ overlay }: Props): JSX.Element => {
  const overlayName = overlay.name;
  const overlayImageSrc = `${BASE_API_URL}/projects/${PROJECT_ID}/overlays/${overlay.id}:downloadLegend`;

  return (
    <div>
      <p className="mb-5 text-sm font-semibold">{overlayName}</p>
      <img src={overlayImageSrc} alt={`${overlayName} legend`} />
    </div>
  );
};
