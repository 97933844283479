/* eslint-disable no-magic-numbers */
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import Style from 'ol/style/Style';
import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { MapInstance } from '@/types/map';
import { Arrow, Color, LayerLine as LayerLineModel, Segment } from '@/types/models';
import getStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStyle';
import getScaledElementStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getScaledElementStyle';
import { Stroke } from 'ol/style';
import { MapSize } from '@/redux/map/map.types';
import { Coordinate } from 'ol/coordinate';
import { LAYER_ELEMENT_TYPES, SELECT_COLOR } from '@/components/Map/MapViewer/MapViewer.constants';
import { LineString, MultiPolygon } from 'ol/geom';
import getRotation from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getRotation';
import { ArrowTypeDict, LineTypeDict } from '@/redux/shapes/shapes.types';
import getStroke from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStroke';
import { rgbToHex } from '@/components/Map/MapViewer/utils/mapElementsRendering/style/rgbToHex';
import getArrowFeature from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/utils/getArrowFeature';
import { updateLayerLine } from '@/redux/layers/layers.thunks';
import { store } from '@/redux/store';
import { mapEditToolsSetLayerLine } from '@/redux/mapEditTools/mapEditTools.slice';
import { layerUpdateLine } from '@/redux/layers/layers.slice';
import VectorSource from 'ol/source/Vector';
import Polygon from 'ol/geom/Polygon';

export interface LayerLineProps {
  layerLine: LayerLineModel;
  layer: number;
  lineTypes: LineTypeDict;
  arrowTypes: ArrowTypeDict;
  pointToProjection: UsePointToProjectionResult;
  vectorSource: VectorSource<Feature<Polygon> | Feature<LineString> | Feature<MultiPolygon>>;
  mapInstance: MapInstance;
  mapSize: MapSize;
}

export default class LayerLine {
  elementId: number;

  segments: Array<Segment>;

  startArrow: Arrow;

  endArrow: Arrow;

  lineType: string;

  layer: number;

  zIndex: number;

  color: Color;

  lineWidth: number;

  lineTypes: LineTypeDict;

  arrowTypes: ArrowTypeDict;

  points: Coordinate[] = [];

  styles: Array<{
    style: Style;
    strokeStyle: Stroke;
    selectStyle?: Style;
    selectStrokeStyle?: Stroke;
  }> = [];

  strokeStyle: Stroke = new Stroke();

  lineString: LineString = new LineString([]);

  lineFeature: Feature<LineString> = new Feature();

  startArrowFeature: Feature<MultiPolygon> | undefined;

  endArrowFeature: Feature<MultiPolygon> | undefined;

  vectorSource: VectorSource<Feature<Polygon> | Feature<LineString> | Feature<MultiPolygon>>;

  mapSize: MapSize;

  pointToProjection: UsePointToProjectionResult;

  minResolution: number;

  constructor({
    layerLine,
    layer,
    lineTypes,
    arrowTypes,
    pointToProjection,
    vectorSource,
    mapInstance,
    mapSize,
  }: LayerLineProps) {
    this.elementId = layerLine.id;
    this.segments = layerLine.segments;
    this.startArrow = layerLine.startArrow;
    this.endArrow = layerLine.endArrow;
    this.lineType = layerLine.lineType;
    this.layer = layer;
    this.zIndex = layerLine.z;
    this.color = layerLine.color;
    this.lineWidth = layerLine.width;
    this.lineTypes = lineTypes;
    this.arrowTypes = arrowTypes;
    this.vectorSource = vectorSource;
    this.pointToProjection = pointToProjection;
    this.mapSize = mapSize;

    const maxZoom = mapInstance?.getView().get('originalMaxZoom');
    this.minResolution = mapInstance?.getView().getResolutionForZoom(maxZoom) || 1;

    this.lineFeature = new Feature<LineString>({
      geometry: this.lineString,
      elementType: LAYER_ELEMENT_TYPES.LINE,
      layer: this.layer,
    });

    this.drawLayerLine();

    this.lineFeature.setId(this.elementId);
    this.lineFeature.set('getObjectData', this.getData.bind(this));
    this.lineFeature.set('setCoordinates', this.setCoordinates.bind(this));
    this.lineFeature.set('updateElement', this.updateElement.bind(this));
    this.lineFeature.set('drawLayerLine', this.drawLayerLine.bind(this));
    this.lineFeature.set('save', this.save.bind(this));
    this.lineFeature.setStyle(this.getStyle.bind(this));
  }

  private getData(): LayerLineModel {
    return {
      id: this.elementId,
      startArrow: this.startArrow,
      endArrow: this.endArrow,
      z: this.zIndex,
      width: this.lineWidth,
      lineType: this.lineType,
      color: this.color,
      segments: this.segments,
      layer: this.layer,
    };
  }

  private setLinePoints(): void {
    this.points = this.segments
      .map((segment, index) => {
        if (index === 0) {
          return [
            this.pointToProjection({ x: segment.x1, y: segment.y1 }),
            this.pointToProjection({ x: segment.x2, y: segment.y2 }),
          ];
        }
        return [this.pointToProjection({ x: segment.x2, y: segment.y2 })];
      })
      .flat();
  }

  private drawStartArrow(): void {
    if (this.startArrow.arrowType !== 'NONE') {
      const firstSegment = this.segments[0];
      const startArrowRotation = getRotation(
        [firstSegment.x2, firstSegment.y2],
        [firstSegment.x1, firstSegment.y1],
      );
      const shortenedX1 = firstSegment.x1 - this.startArrow.length * Math.cos(startArrowRotation);
      const shortenedY1 = firstSegment.y1 + this.startArrow.length * Math.sin(startArrowRotation);
      this.points[0] = this.pointToProjection({ x: shortenedX1, y: shortenedY1 });

      const arrowFeature = getArrowFeature({
        arrowTypes: this.arrowTypes,
        arrow: this.startArrow,
        x: shortenedX1,
        y: shortenedY1,
        zIndex: this.zIndex,
        rotation: startArrowRotation,
        lineWidth: this.lineWidth,
        color: this.color,
        pointToProjection: this.pointToProjection,
      });
      if (arrowFeature) {
        const { feature, styles } = arrowFeature;
        feature.setId(`start_arrow_${this.elementId}`);
        feature.set('type', LAYER_ELEMENT_TYPES.ARROW);
        feature.set('lineWidth', this.lineWidth);
        feature.set('lineFeature', this.lineFeature);
        feature.setStyle(this.getStyle.bind(this));
        this.styles.push(...styles);
        this.startArrowFeature = feature;
      }
    } else {
      this.startArrowFeature = undefined;
    }
  }

  private drawEndArrow(): void {
    if (this.endArrow.arrowType !== 'NONE') {
      const lastSegment = this.segments.at(-1);
      if (!lastSegment) {
        return;
      }
      const endArrowRotation = getRotation(
        [lastSegment.x1, lastSegment.y1],
        [lastSegment.x2, lastSegment.y2],
      );
      const shortenedX2 = lastSegment.x2 - this.endArrow.length * Math.cos(endArrowRotation);
      const shortenedY2 = lastSegment.y2 + this.endArrow.length * Math.sin(endArrowRotation);
      this.points[this.points.length - 1] = this.pointToProjection({
        x: shortenedX2,
        y: shortenedY2,
      });

      const arrowFeature = getArrowFeature({
        arrowTypes: this.arrowTypes,
        arrow: this.endArrow,
        x: shortenedX2,
        y: shortenedY2,
        zIndex: this.zIndex,
        rotation: endArrowRotation,
        lineWidth: this.lineWidth,
        color: this.color,
        pointToProjection: this.pointToProjection,
      });
      if (arrowFeature) {
        const { feature, styles } = arrowFeature;
        feature.setId(`end_arrow_${this.elementId}`);
        feature.set('type', LAYER_ELEMENT_TYPES.ARROW);
        feature.setStyle(this.getStyle.bind(this));
        feature.set('lineFeature', this.lineFeature);
        this.styles.push(...styles);
        this.endArrowFeature = feature;
      }
    } else {
      this.endArrowFeature = undefined;
    }
  }

  private drawLineString(): void {
    this.lineString = new LineString(this.points);
  }

  private setLineStyles(): void {
    const lineDash = this.lineTypes[this.lineType] || [];
    const lineStrokeStyle = getStroke({
      color: rgbToHex(this.color),
      width: this.lineWidth,
      lineDash,
    });
    const lineStyle = getStyle({
      geometry: this.lineString,
      borderColor: this.color,
      lineWidth: this.lineWidth,
      lineDash,
      zIndex: this.zIndex,
    });
    const lineSelectStrokeStyle = getStroke({
      color: rgbToHex(SELECT_COLOR),
      width: this.lineWidth,
    });
    const lineSelectStyle = getStyle({
      geometry: this.lineString,
      borderColor: SELECT_COLOR,
      lineWidth: this.lineWidth,
      zIndex: 99999,
    });
    this.styles.push({
      style: lineStyle,
      strokeStyle: lineStrokeStyle,
      selectStyle: lineSelectStyle,
      selectStrokeStyle: lineSelectStrokeStyle,
    });
  }

  private drawLayerLine(): void {
    this.startArrowFeature = undefined;
    this.endArrowFeature = undefined;
    this.styles = [];
    this.setLinePoints();
    this.drawStartArrow();
    this.drawEndArrow();
    this.drawLineString();
    this.setLineStyles();
    this.lineFeature.setGeometry(this.lineString);
    this.lineFeature.changed();
  }

  private updateElement(layerLine: LayerLineModel): void {
    this.elementId = layerLine.id;
    this.startArrow = layerLine.startArrow;
    this.endArrow = layerLine.endArrow;
    this.zIndex = layerLine.z;
    this.lineWidth = layerLine.width;
    this.lineType = layerLine.lineType;
    this.color = layerLine.color;
    this.segments = layerLine.segments;

    this.drawLayerLine();
    if (
      this.startArrowFeature &&
      !this.vectorSource.getFeatureById(`start_arrow_${this.elementId}`)
    ) {
      this.vectorSource.addFeature(this.startArrowFeature);
    }
    if (this.endArrowFeature && !this.vectorSource.getFeatureById(`end_arrow_${this.elementId}`)) {
      this.vectorSource.addFeature(this.endArrowFeature);
    }
  }

  private async save({
    modelId,
    segments,
    firstPoint,
    lastPoint,
  }: {
    modelId: number;
    segments: Array<Segment>;
    firstPoint: Coordinate;
    lastPoint: Coordinate;
  }): Promise<void> {
    const firstSegment = segments.at(0);
    const lastSegment = segments.at(-1);
    const firstCurrentSegment = this.segments.at(0);
    const lastCurrentSegment = this.segments.at(-1);
    const firstCurrentPoint = this.points.at(0);
    const lastCurrentPoint = this.points.at(-1);
    if (
      firstCurrentPoint &&
      firstSegment &&
      firstCurrentSegment &&
      firstPoint[0] === firstCurrentPoint[0] &&
      firstPoint[1] === firstCurrentPoint[1]
    ) {
      firstSegment.x1 = firstCurrentSegment.x1;
      firstSegment.y1 = firstCurrentSegment.y1;
    }
    if (
      lastCurrentPoint &&
      lastSegment &&
      lastCurrentSegment &&
      lastPoint[0] === lastCurrentPoint[0] &&
      lastPoint[1] === lastCurrentPoint[1]
    ) {
      lastSegment.x2 = lastCurrentSegment.x2;
      lastSegment.y2 = lastCurrentSegment.y2;
    }
    const { dispatch } = store;
    const layerLine = await dispatch(
      updateLayerLine({
        modelId,
        layerId: this.layer,
        lineId: this.elementId,
        payload: { ...this.getData(), segments },
      }),
    ).unwrap();
    if (layerLine) {
      dispatch(layerUpdateLine({ modelId, layerId: this.layer, layerLine }));
      dispatch(mapEditToolsSetLayerLine(layerLine));
      this.updateElement(layerLine);
    }
  }

  private setCoordinates(coords: Coordinate[]): void {
    const lineString = this.lineFeature.getGeometry();
    if (lineString) {
      lineString.setCoordinates(coords);
    }
  }

  protected getStyle(_: FeatureLike, resolution: number): Style | Array<Style> | void {
    const scale = this.minResolution / resolution;
    return this.styles.map(({ style, strokeStyle, selectStyle, selectStrokeStyle }) => {
      if (selectStyle && selectStrokeStyle && this.lineFeature.get('selected')) {
        return getScaledElementStyle(selectStyle, selectStrokeStyle, scale);
      }
      return getScaledElementStyle(style, strokeStyle, scale);
    });
  }
}
