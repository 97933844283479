/* eslint-disable no-magic-numbers */
import { Drawer } from '@/components/Map/Drawer';
import { Legend } from '@/components/Map/Legend';
import { MapViewer } from '@/components/Map/MapViewer';
import { MapLoader } from '@/components/Map/MapLoader/MapLoader.component';
import { MapAdditionalLogos } from '@/components/Map/MapAdditionalLogos';
import { MapBackgroundSwitchComponent } from '@/components/Map/MapBackgroundSwitch/MapBackgroundSwitch.component';
import { MapAdditionalActions } from './MapAdditionalActions';
import { PluginsDrawer } from './PluginsDrawer';

export const Map = (): JSX.Element => {
  return (
    <div
      className="relative z-0 h-screen w-full overflow-hidden bg-black"
      data-testid="map-container"
    >
      <MapViewer />
      <MapBackgroundSwitchComponent />
      <Drawer />
      <PluginsDrawer />
      <Legend />
      <MapAdditionalActions />
      <MapAdditionalLogos />
      <MapLoader />
    </div>
  );
};
