/* eslint-disable no-magic-numbers */
import { MapInstance } from '@/types/map';
import { useOlMapAdditionalLayers } from '@/components/Map/MapViewer/utils/config/additionalLayers/useOlMapAdditionalLayers';
import { useMemo } from 'react';
import { useOlMapCardLayer } from '@/components/Map/MapViewer/utils/config/mapCardLayer/useOlMapCardLayer';
import { useOlMapPinsLayer } from '@/components/Map/MapViewer/utils/config/pinsLayer/useOlMapPinsLayer';
import { useOlMapReactionsLayer } from '@/components/Map/MapViewer/utils/config/reactionsLayer/useOlMapReactionsLayer';
import { useOlMapCommentsLayer } from '@/components/Map/MapViewer/utils/config/commentsLayer/useOlMapCommentsLayer';
import { MapConfig } from '../../MapViewer.types';
import { useOlMapProcessLayer } from './processLayer/useOlMapProcessLayer';

interface UseOlMapLayersInput {
  mapInstance: MapInstance;
}

export const useOlMapLayers = ({ mapInstance }: UseOlMapLayersInput): MapConfig['layers'] => {
  const processLayer = useOlMapProcessLayer({ mapInstance });
  useOlMapAdditionalLayers(mapInstance);
  const mapCardLayer = useOlMapCardLayer();
  const pinsLayer = useOlMapPinsLayer();
  const reactionsLayer = useOlMapReactionsLayer();
  const commentsLayer = useOlMapCommentsLayer();

  return useMemo(() => {
    return [mapCardLayer, processLayer, pinsLayer, reactionsLayer, commentsLayer];
  }, [mapCardLayer, processLayer, pinsLayer, reactionsLayer, commentsLayer]);
};
