/* eslint-disable no-magic-numbers */
import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '@/redux/root/root.selectors';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { LayerState } from '@/redux/layers/layers.types';

export const layersSelector = createSelector(rootSelector, state => state.layers);

export const layersStateForCurrentModelSelector = createSelector(
  layersSelector,
  currentModelIdSelector,
  (state, currentModelId) => state[currentModelId],
);

export const layersActiveLayersSelector = createSelector(
  layersStateForCurrentModelSelector,
  state => state?.data?.activeLayers || [],
);

export const layersDrawLayerSelector = createSelector(
  layersStateForCurrentModelSelector,
  state => state?.data?.drawLayer || null,
);

export const layerByIdSelector = createSelector(
  [layersStateForCurrentModelSelector, (_state, layerId: number): number => layerId],
  (state, layerId) => state?.data?.layers[layerId],
);

export const layersLoadingSelector = createSelector(
  layersStateForCurrentModelSelector,
  state => state?.loading,
);

export const layersVisibilityForCurrentModelSelector = createSelector(
  layersStateForCurrentModelSelector,
  state => state?.data?.layersVisibility || {},
);

export const layersForCurrentModelSelector = createSelector(
  layersStateForCurrentModelSelector,
  state => state?.data?.layers || {},
);

export const layersArrayForCurrentModelSelector = createSelector(
  layersStateForCurrentModelSelector,
  state => {
    if (state && state.data) {
      return Object.values(state.data.layers);
    }
    return [];
  },
);

export const maxLayerZIndexAboveDiagramSelector = createSelector(
  layersArrayForCurrentModelSelector,
  layers => {
    const layersAboveDiagram = layers.filter(layer => layer.details.z > 0);
    if (layersAboveDiagram.length === 0) {
      return -1000;
    }
    let maxZIndex = -Infinity;
    layersAboveDiagram.forEach((layer: LayerState) => {
      if (layer.details.z > 0 && layer.details.z > maxZIndex) {
        maxZIndex = layer.details.z;
      }
    });
    return maxZIndex;
  },
);

export const maxLayerZIndexBelowDiagramSelector = createSelector(
  layersArrayForCurrentModelSelector,
  layers => {
    const layerBelowDiagram = layers.filter(layer => layer.details.z < 0);
    if (layerBelowDiagram.length === 0) {
      return -1000;
    }
    let maxZIndex = -Infinity;
    layerBelowDiagram.forEach((layer: LayerState) => {
      if (layer.details.z < 0 && layer.details.z > maxZIndex) {
        maxZIndex = layer.details.z;
      }
    });
    return maxZIndex;
  },
);

export const minLayerZIndexAboveDiagramSelector = createSelector(
  layersArrayForCurrentModelSelector,
  layers => {
    const layersAboveDiagram = layers.filter(layer => layer.details.z > 0);
    if (layersAboveDiagram.length === 0) {
      return -1000;
    }
    let minZIndex = Infinity;
    layersAboveDiagram.forEach((layer: LayerState) => {
      if (layer.details.z > 0 && layer.details.z < minZIndex) {
        minZIndex = layer.details.z;
      }
    });
    return minZIndex;
  },
);

export const minLayerZIndexBelowDiagramSelector = createSelector(
  layersArrayForCurrentModelSelector,
  layers => {
    const layerBelowDiagram = layers.filter(layer => layer.details.z < 0);
    if (layerBelowDiagram.length === 0) {
      return -1000;
    }
    let minZIndex = Infinity;
    layerBelowDiagram.forEach((layer: LayerState) => {
      if (layer.details.z < 0 && layer.details.z < minZIndex) {
        minZIndex = layer.details.z;
      }
    });
    return minZIndex;
  },
);

export const maxObjectZIndexForLayerSelector = createSelector(
  [layersArrayForCurrentModelSelector, (_state, layerId: number | null): number | null => layerId],
  (layers, layerId) => {
    if (!layers || layers.length === 0 || !layerId) {
      return 0;
    }
    const foundLayer = layers.find(layer => layer.details.id === layerId);
    if (!foundLayer) {
      return 0;
    }
    const getMaxZFromItems = <T extends { z?: number }>(items: T[] = []): number =>
      items.length > 0 ? Math.max(...items.map(item => item.z || 0)) : 0;

    const textsMaxZ = getMaxZFromItems(Object.values(foundLayer.texts));
    const rectsMaxZ = getMaxZFromItems(Object.values(foundLayer.rects));
    const ovalsMaxZ = getMaxZFromItems(Object.values(foundLayer.ovals));
    const linesMaxZ = getMaxZFromItems(Object.values(foundLayer.lines));
    const imagesMaxZ = getMaxZFromItems(Object.values(foundLayer.images));

    return Math.max(textsMaxZ, rectsMaxZ, ovalsMaxZ, linesMaxZ, imagesMaxZ);
  },
);

export const minObjectZIndexForLayerSelector = createSelector(
  [layersArrayForCurrentModelSelector, (_state, layerId: number | null): number | null => layerId],
  (layers, layerId) => {
    if (!layers || layers.length === 0 || !layerId) {
      return 0;
    }
    const foundLayer = layers.find(layer => layer.details.id === layerId);
    if (!foundLayer) {
      return 0;
    }
    const getMinZFromItems = <T extends { z?: number }>(items: T[] = []): number =>
      items.length > 0 ? Math.min(...items.map(item => item.z || 0)) : 0;

    const textsMinZ = getMinZFromItems(Object.values(foundLayer.texts));
    const rectsMinZ = getMinZFromItems(Object.values(foundLayer.rects));
    const ovalsMinZ = getMinZFromItems(Object.values(foundLayer.ovals));
    const linesMinZ = getMinZFromItems(Object.values(foundLayer.lines));
    const imagesMinZ = getMinZFromItems(Object.values(foundLayer.images));

    return Math.min(textsMinZ, rectsMinZ, ovalsMinZ, linesMinZ, imagesMinZ);
  },
);
