import { WebSocketEntityUpdateInterface } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.types';
import { store } from '@/redux/store';
import { ENTITY_OPERATION_TYPES } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.constants';
import { getLayerText } from '@/redux/layers/layers.thunks';
import { MapInstance } from '@/types/map';
import drawElementOnLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/drawElementOnLayer';
import { layerDeleteText } from '@/redux/layers/layers.slice';
import removeElementFromLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/removeElementFromLayer';
import updateElement from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/updateElement';

export default async function processLayerText({
  data,
  mapInstance,
}: {
  data: WebSocketEntityUpdateInterface;
  mapInstance: MapInstance;
}): Promise<void> {
  const { dispatch } = store;
  if (
    data.type === ENTITY_OPERATION_TYPES.ENTITY_CREATED ||
    data.type === ENTITY_OPERATION_TYPES.ENTITY_UPDATED
  ) {
    const resultText = await dispatch(
      getLayerText({
        modelId: data.mapId,
        layerId: data.layerId,
        textId: data.entityId,
      }),
    ).unwrap();
    if (!resultText) {
      return;
    }
    if (data.type === ENTITY_OPERATION_TYPES.ENTITY_CREATED) {
      drawElementOnLayer({
        mapInstance,
        activeLayer: data.layerId,
        object: resultText,
        drawFunctionKey: 'drawText',
      });
    } else {
      updateElement(mapInstance, data.layerId, resultText);
    }
  } else if (data.type === ENTITY_OPERATION_TYPES.ENTITY_DELETED) {
    dispatch(
      layerDeleteText({
        modelId: data.mapId,
        layerId: data.layerId,
        textId: data.entityId,
      }),
    );
    removeElementFromLayer({ mapInstance, layerId: data.layerId, featureId: data.entityId });
  }
}
