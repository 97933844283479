import { rootSelector } from '@/redux/root/root.selectors';
import { createSelector } from '@reduxjs/toolkit';
import { CommentWithPinType } from '@/types/comment';
import { currentModelIdSelector } from '../models/models.selectors';

export const commentSelector = createSelector(rootSelector, state => state.comment);

export const commentReactionSelector = createSelector(
  commentSelector,
  commentState => commentState.commentReaction,
);

export const allCommentsSelectorOfCurrentMap = createSelector(
  commentSelector,
  currentModelIdSelector,
  (commentState, currentModelId): CommentWithPinType[] => {
    if (!commentState) {
      return [];
    }

    return (commentState.data || [])
      .filter(comment => comment.modelId === currentModelId)
      .map(comment => {
        return {
          ...comment,
          pinType: 'comment',
        };
      });
  },
);
