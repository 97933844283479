/* eslint-disable no-magic-numbers */
import { ColorTilePicker } from '@/shared/ColorPicker/ColorTilePicker.component';
import hexToRgbIntAlpha from '@/utils/convert/hexToRgbIntAlpha';
import { Color } from '@/types/models';
import { LayerLineFactoryForm } from '@/components/FunctionalArea/Modal/LayerLineFactoryModal/LayerLineFactory.types';
import { Select } from '@/shared/Select';
import React from 'react';
import { Input } from '@/shared/Input';
import {
  MAX_ARROW_SCALE,
  MAX_LINE_WIDTH,
  MIN_ARROW_SCALE,
  MIN_LINE_WIDTH,
} from '@/components/FunctionalArea/Modal/LayerLineFactoryModal/LayerLineFactory.constants';

type LayerLineFormProps = {
  data: LayerLineFactoryForm;
  lineTypes: Array<{ id: string; name: string }>;
  arrowTypes: Array<{ id: string; name: string }>;
  onChange: (value: number | string | Color, key: string) => void;
};

export const LayerLineForm = ({
  data,
  onChange,
  lineTypes,
  arrowTypes,
}: LayerLineFormProps): React.JSX.Element => {
  const onLineWidthChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = Number(event.target.value);
    let resultValue = value;
    if (value < MIN_LINE_WIDTH) {
      resultValue = MIN_LINE_WIDTH;
    }
    if (value > MAX_LINE_WIDTH) {
      resultValue = MAX_LINE_WIDTH;
    }
    onChange(resultValue, 'width');
  };

  const onArrowScaleChange = (event: React.ChangeEvent<HTMLInputElement>, key: string): void => {
    const value = Number(event.target.value);
    let resultValue = value;
    if (value < MIN_ARROW_SCALE) {
      resultValue = MIN_ARROW_SCALE;
    }
    if (value > MAX_ARROW_SCALE) {
      resultValue = MAX_ARROW_SCALE;
    }
    onChange(resultValue, key);
  };

  return (
    <div className="grid grid-cols-3 gap-2">
      <div>
        <span>Color:</span>
        <ColorTilePicker
          initialColor={data.color}
          colorChange={color => onChange(hexToRgbIntAlpha(color), 'color')}
        />
      </div>
      <div>
        <span>Line type:</span>
        <Select
          options={lineTypes}
          selectedId={data.lineType}
          listClassName="max-h-48"
          testId="font-size-select"
          onChange={value => onChange(value, 'lineType')}
        />
      </div>
      <div>
        <span>Line width:</span>
        <Input
          type="number"
          name="line-width"
          min={MIN_LINE_WIDTH}
          max={MAX_LINE_WIDTH}
          id="line-width"
          value={data.width}
          onChange={onLineWidthChange}
          className="text-sm font-medium text-font-400"
        />
      </div>

      <div className="grid grid-cols-1 gap-2">
        <div>
          <span>Start arrow:</span>
          <Select
            options={arrowTypes}
            selectedId={data.startArrow}
            listClassName="max-h-48"
            testId="font-size-select"
            onChange={value => onChange(value, 'startArrow')}
          />
        </div>
        <div>
          <span>Start arrow scale:</span>
          <Input
            type="number"
            name="line-width"
            min={MIN_ARROW_SCALE}
            max={MAX_ARROW_SCALE}
            id="start-arrow-scale"
            step={0.1}
            value={data.startArrowScale}
            onChange={event => onArrowScaleChange(event, 'startArrowScale')}
            className="text-sm font-medium text-font-400"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-2">
        <div>
          <span>End arrow:</span>
          <Select
            options={arrowTypes}
            selectedId={data.endArrow}
            listClassName="max-h-48"
            testId="font-size-select"
            onChange={value => onChange(value, 'endArrow')}
          />
        </div>
        <div>
          <span>End arrow scale:</span>
          <Input
            type="number"
            name="line-width"
            min={MIN_ARROW_SCALE}
            max={MAX_ARROW_SCALE}
            id="end-arrow-scale"
            step={0.1}
            value={data.endArrowScale}
            onChange={event => onArrowScaleChange(event, 'endArrowScale')}
            className="text-sm font-medium text-font-400"
          />
        </div>
      </div>
    </div>
  );
};
