/* eslint-disable no-magic-numbers */
import { MapSize } from '@/redux/map/map.types';
import { AppDispatch } from '@/redux/store';
import { Map, MapBrowserEvent } from 'ol';
import { Comment, ModelElement, NewReaction } from '@/types/models';
import { updateLastClick } from '@/redux/map/map.slice';
import { toLonLat } from 'ol/proj';
import { latLngToPoint } from '@/utils/map/latLngToPoint';
import { closeDrawer } from '@/redux/drawer/drawer.slice';
import { leftClickHandleAlias } from '@/components/Map/MapViewer/utils/listeners/mouseClick/mouseLeftClick/leftClickHandleAlias';
import { handleFeaturesClick } from '@/components/Map/MapViewer/utils/listeners/mouseClick/mouseLeftClick/handleFeaturesClick';
import { resetReactionsData } from '@/redux/reactions/reactions.slice';
import { handleDataReset } from '@/components/Map/MapViewer/utils/listeners/mouseClick/handleDataReset';
import { FEATURE_TYPE } from '@/constants/features';
import { clickHandleReaction } from '@/components/Map/MapViewer/utils/listeners/mouseClick/clickHandleReaction';
import getFeatureAtCoordinate from '@/components/Map/MapViewer/utils/listeners/mouseClick/getFeatureAtCoordinate';
import { clearSearchModelElements } from '@/redux/modelElements/modelElements.slice';
import isEditableElementAtPixel from '@/components/Map/MapViewer/utils/listeners/mouseClick/isEditableElementAtPixel';

/* prettier-ignore */
export const onMapLeftClick =
  (
    mapSize: MapSize,
    modelId: number,
    dispatch: AppDispatch,
    isResultDrawerOpen: boolean,
    comments: Comment[],
    modelElements: Array<ModelElement>,
    reactions: Array<NewReaction>,
    activeLayers: number[],
    hasPrivilegeToWriteProject: boolean
  ) =>
    async (
      { coordinate, pixel }: Pick<MapBrowserEvent<UIEvent>, 'coordinate' | 'pixel'>,
      mapInstance: Map,
    ): Promise<void> => {
      const [lng, lat] = toLonLat(coordinate);
      const point = latLngToPoint([lat, lng], mapSize);
      dispatch(updateLastClick({ coordinates: point, modelId }));
      if (hasPrivilegeToWriteProject) {
        const isActiveLayerElementAtPixel = isEditableElementAtPixel({
          mapInstance,
          pixel,
          activeLayers,
        });
        if (isActiveLayerElementAtPixel) {
          dispatch(handleDataReset);
          dispatch(resetReactionsData());
          dispatch(clearSearchModelElements());
          return;
        }
      }
      const featureAtCoordinate = getFeatureAtCoordinate({mapInstance, coordinate, point});

      if (featureAtCoordinate) {
        const { shouldBlockCoordSearch } = handleFeaturesClick([featureAtCoordinate], dispatch, comments);
        if (shouldBlockCoordSearch) {
          return;
        }
      }

      dispatch(handleDataReset);

      if (!featureAtCoordinate) {
        if (isResultDrawerOpen) {
          dispatch(closeDrawer());
        }

        dispatch(resetReactionsData());
        dispatch(clearSearchModelElements());
        return;
      }

      const type = featureAtCoordinate.get('type');
      const id = featureAtCoordinate.get('id');
      if ([FEATURE_TYPE.ALIAS, FEATURE_TYPE.GLYPH, FEATURE_TYPE.COMPARTMENT].includes(type)) {
        await leftClickHandleAlias(dispatch)(modelElements, featureAtCoordinate, modelId);
      } else if (type === FEATURE_TYPE.REACTION) {
        clickHandleReaction(dispatch)(modelElements, reactions, id, modelId);
      }
    };
