import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '@/redux/root/root.selectors';

export const glyphsSelector = createSelector(rootSelector, state => state.glyphs);

export const glyphsDataSelector = createSelector(glyphsSelector, state => state.data);

export const glyphFileNameByIdSelector = createSelector(
  [glyphsDataSelector, (_state, glyphId: number | null): number | null => glyphId],
  (state, glyphId) => {
    if (glyphId) {
      return state.find(glyph => glyph.id === glyphId)?.filename;
    }
    return 'No image file';
  },
);
