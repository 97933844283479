import {
  PinListModelElement,
  PinListModelElementWithCoords,
} from './BioEntitiesPinsListItem.types';

export const isPinWithCoordinates = (
  pin: PinListModelElement,
): pin is PinListModelElementWithCoords => {
  return Boolean('x' in pin && 'y' in pin);
};
