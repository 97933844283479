import { twMerge } from 'tailwind-merge';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { modalSelector } from '@/redux/modal/modal.selector';
import { closeModal } from '@/redux/modal/modal.slice';
import { Icon } from '@/shared/Icon';
import { MODAL_ROLE } from './ModalLayout.constants';

type ModalLayoutProps = {
  children: React.ReactNode;
};

export const ModalLayout = ({ children }: ModalLayoutProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { modalName, modalTitle } = useAppSelector(modalSelector);

  const handleCloseModal = (): void => {
    dispatch(closeModal());
  };

  return (
    <div
      className={twMerge('absolute left-0 top-0 z-20 h-full w-full bg-cetacean-blue/[.48]')}
      role={MODAL_ROLE}
    >
      <div className="relative flex h-full w-full items-center justify-center">
        <div
          className={twMerge(
            'flex h-5/6 w-10/12 flex-col	overflow-hidden rounded-lg',
            modalName === 'login' && 'h-auto w-[400px]',
            modalName === 'access-denied' && 'h-auto w-[400px]',
            modalName === 'select-project' && 'h-auto w-[400px]',
            modalName === 'terms-of-service' && 'h-auto w-[400px]',
            modalName === 'add-comment' && 'h-auto w-[400px]',
            modalName === 'error-report' && 'h-auto w-[800px]',
            modalName === 'layer-factory' && 'h-auto w-[400px]',
            modalName === 'element-glyph' && 'h-auto w-[800px]',
            ['layer-line-factory', 'layer-line-edit-factory'].includes(modalName) &&
              'h-auto w-[550] overflow-visible',
            ['layer-oval-factory', 'layer-oval-edit-factory'].includes(modalName) &&
              'h-auto w-[300px] overflow-visible',
            ['layer-rect-factory', 'layer-rect-edit-factory'].includes(modalName) &&
              'h-auto w-[400px] overflow-visible',
            ['layer-text-factory', 'layer-text-edit-factory'].includes(modalName) &&
              'h-auto w-[900px]',
            ['layer-image-object-factory', 'layer-image-object-edit-factory'].includes(modalName) &&
              'h-auto w-[800px]',
            ['edit-overlay', 'logged-in-menu'].includes(modalName) && 'h-auto w-[432px]',
          )}
        >
          <div
            className={twMerge(
              'flex items-center justify-between bg-white p-[24px] text-xl',
              [
                'layer-rect-factory',
                'layer-rect-edit-factory',
                'layer-oval-factory',
                'layer-oval-edit-factory',
                'layer-line-factory',
                'layer-line-edit-factory',
              ].includes(modalName) && 'rounded-t-lg',
            )}
          >
            {(modalName === 'error-report' || modalName === 'access-denied') && (
              <div className="font-bold text-red-500">
                <Icon name="info" className={twMerge('mr-4 fill-red-500')} />
                {modalTitle}
              </div>
            )}
            {modalName !== 'error-report' && modalName !== 'access-denied' && (
              <div> {modalTitle} </div>
            )}

            {modalName !== 'logged-in-menu' && modalName !== 'terms-of-service' && (
              <button type="button" onClick={handleCloseModal} aria-label="close button">
                <Icon name="close" className="fill-font-500" />
              </button>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
