import { createSelector } from '@reduxjs/toolkit';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { currentDrawerReactionIdSelector } from '@/redux/drawer/drawer.selectors';
import { Comment, NewReaction } from '@/types/models';
import { allCommentsSelectorOfCurrentMap } from '@/redux/comment/comment.selectors';
import { rootSelector } from '../root/root.selectors';

export const newReactionsSelector = createSelector(rootSelector, state => state.newReactions);

export const newReactionsStateForCurrentModelSelector = createSelector(
  newReactionsSelector,
  currentModelIdSelector,
  (state, currentModelId) => state[currentModelId],
);

export const newReactionsLoadingSelector = createSelector(
  newReactionsStateForCurrentModelSelector,
  state => state?.loading,
);

export const newReactionsForCurrentModelSelector = createSelector(
  newReactionsStateForCurrentModelSelector,
  state => state?.data || [],
);

export const newReactionsByModelIdSelector = createSelector(
  [newReactionsSelector, (_state, modelId: number): number => modelId],
  (state, modelId) => state[modelId]?.data || [],
);

export const currentDrawerNewReactionSelector = createSelector(
  newReactionsForCurrentModelSelector,
  currentDrawerReactionIdSelector,
  (newReactions, currentDrawerReactionId): NewReaction | undefined => {
    return newReactions.find(newReaction => newReaction.id === currentDrawerReactionId);
  },
);

export const currentDrawerReactionCommentsSelector = createSelector(
  currentDrawerNewReactionSelector,
  allCommentsSelectorOfCurrentMap,
  (reaction, comments): Comment[] => {
    if (reaction) {
      return comments.filter(
        comment =>
          comment.type === 'REACTION' &&
          comment.modelId === reaction.model &&
          Number(comment.elementId) === reaction.id,
      );
    }
    return [];
  },
);
