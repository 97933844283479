import { searchedChemicalsElementsOfCurrentMapSelector } from '@/redux/chemicals/chemicals.selectors';
import { searchedDrugsElementsOfCurrentMapSelector } from '@/redux/drugs/drugs.selectors';
import { markersPinsOfCurrentMapDataSelector } from '@/redux/markers/markers.selectors';
import { store } from '@/redux/store';
import { searchedModelElementsForCurrentModelSelector } from '@/redux/modelElements/modelElements.selector';
import { GetShownElementsPluginMethodResult } from './getShownElements.types';

export const getShownElements = (): GetShownElementsPluginMethodResult => {
  const { getState } = store;

  const content = searchedModelElementsForCurrentModelSelector(getState());
  const chemicals = searchedChemicalsElementsOfCurrentMapSelector(getState());
  const drugs = searchedDrugsElementsOfCurrentMapSelector(getState());
  const markers = markersPinsOfCurrentMapDataSelector(getState());

  return {
    content,
    chemicals,
    drugs,
    markers,
  };
};
