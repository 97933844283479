import { BASE_API_URL, PROJECT_ID } from '@/constants';
import { getBounds } from '@/services/pluginsManager/map/data/getBounds';

export interface GetGraphicsDownloadUrlProps {
  modelId?: string;
  handler?: string;
  zoom?: number;
  overlayIds: string[];
  currentView?: boolean;
}

export const getGraphicsDownloadUrl = ({
  modelId,
  handler,
  zoom,
  overlayIds,
  currentView,
}: GetGraphicsDownloadUrlProps): string | undefined => {
  const isAllElementsTruthy = [modelId, handler, zoom].reduce(
    (a, b) => Boolean(a) && Boolean(b),
    true,
  );

  if (!isAllElementsTruthy) {
    return undefined;
  }

  const overlays = overlayIds.join(',');

  let polygonSuffix = '';
  if (currentView) {
    const bounds = getBounds();
    if (bounds) {
      const { x1, y1, x2, y2 } = bounds;
      polygonSuffix = `&polygonString=${x1},${y1};${x1},${y2};${x2},${y2};${x2},${y1}`;
    }
  }

  return `${BASE_API_URL}/projects/${PROJECT_ID}/models/${modelId}:downloadImage?handlerClass=${handler}&zoomLevel=${zoom}&overlayIds=${overlays}${polygonSuffix}`;
};
