import { Coordinate } from 'ol/coordinate';
import { Segment } from '@/types/models';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';

export default function getLayerLineBoundingBoxCoords({
  segments,
  pointToProjection,
}: {
  segments: Array<Segment>;
  pointToProjection: UsePointToProjectionResult;
}): Coordinate[] {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  segments.forEach(segment => {
    if (segment.x1 < minX) {
      minX = segment.x1;
    }
    if (segment.x2 < minX) {
      minX = segment.x2;
    }
    if (segment.y1 < minY) {
      minY = segment.y1;
    }
    if (segment.y2 < minY) {
      minY = segment.y2;
    }
    if (segment.x1 > maxX) {
      maxX = segment.x1;
    }
    if (segment.x2 > maxX) {
      maxX = segment.x2;
    }
    if (segment.y1 > maxY) {
      maxY = segment.y1;
    }
    if (segment.y2 > maxY) {
      maxY = segment.y2;
    }
  });
  const point1 = pointToProjection({ x: minX, y: minY });
  const point2 = pointToProjection({
    x: maxX,
    y: maxY,
  });
  return [point1, point2];
}
