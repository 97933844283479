/* eslint-disable no-magic-numbers */
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { MapInstance, Point } from '@/types/map';
import { HorizontalAlign, VerticalAlign } from '@/components/Map/MapViewer/MapViewer.types';
import {
  BLACK_COLOR,
  COMPARTMENT_SQUARE_POINTS,
  WHITE_COLOR,
} from '@/components/Map/MapViewer/MapViewer.constants';
import { Color } from '@/types/models';
import VectorSource from 'ol/source/Vector';
import { MapSize } from '@/redux/map/map.types';
import Compartment from '@/components/Map/MapViewer/utils/mapElementsRendering/elements/Compartment/Compartment';
import getPolygonCoords from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getPolygonCoords';

export type CompartmentSquareProps = {
  id: number;
  complexId?: number | null;
  compartmentId: number | null;
  pathwayId: number | null;
  sboTerm: string;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  fillColor?: Color;
  borderColor?: Color;
  fontColor?: Color;
  innerWidth?: number;
  outerWidth?: number;
  thickness?: number;
  text?: string;
  fontSize?: number;
  nameX: number;
  nameY: number;
  nameHeight: number;
  nameWidth: number;
  nameVerticalAlign?: VerticalAlign;
  nameHorizontalAlign?: HorizontalAlign;
  overlaysVisible: boolean;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
  vectorSource: VectorSource;
  backgroundId: number;
  mapSize: MapSize;
};

export default class CompartmentSquare extends Compartment {
  constructor({
    id,
    complexId,
    compartmentId,
    pathwayId,
    sboTerm,
    x,
    y,
    width,
    height,
    zIndex,
    fillColor = WHITE_COLOR,
    borderColor = BLACK_COLOR,
    fontColor = BLACK_COLOR,
    innerWidth = 1,
    outerWidth = 2,
    thickness = 12,
    text = '',
    fontSize = 12,
    nameX,
    nameY,
    nameHeight,
    nameWidth,
    nameVerticalAlign = 'MIDDLE',
    nameHorizontalAlign = 'CENTER',
    overlaysVisible,
    pointToProjection,
    mapInstance,
    vectorSource,
    backgroundId,
    mapSize,
  }: CompartmentSquareProps) {
    super({
      id,
      complexId,
      compartmentId,
      pathwayId,
      sboTerm,
      x,
      y,
      width,
      height,
      thickness,
      outerWidth,
      innerWidth,
      zIndex,
      text,
      fontSize,
      nameX,
      nameY,
      nameWidth,
      nameHeight,
      fontColor,
      nameVerticalAlign,
      nameHorizontalAlign,
      fillColor,
      borderColor,
      overlaysVisible,
      pointToProjection,
      mapInstance,
      vectorSource,
      backgroundId,
      mapSize,
    });
    this.feature.set('isCompartmentClicked', this.isCompartmentClicked.bind(this));
  }

  private isCompartmentClicked({
    point,
    hitTolerance = 5,
  }: {
    point: Point;
    hitTolerance?: number;
  }): boolean {
    const minX = this.x + this.thickness;
    const maxX = this.x + this.width - this.thickness;
    const minY = this.y + this.thickness;
    const maxY = this.y + this.height - this.thickness;

    const innerMinX = minX + this.innerWidth + hitTolerance;
    const innerMaxX = maxX - this.innerWidth - hitTolerance;
    const innerMinY = minY + this.innerWidth + hitTolerance;
    const innerMaxY = maxY - this.innerWidth - hitTolerance;

    return !(
      point.x > innerMinX &&
      point.x < innerMaxX &&
      point.y > innerMinY &&
      point.y < innerMaxY
    );
  }

  protected getCompartmentCoords(): void {
    this.outerCoords = getPolygonCoords({
      points: COMPARTMENT_SQUARE_POINTS,
      x: this.x,
      y: this.y,
      height: this.height,
      width: this.width,
      pointToProjection: this.pointToProjection,
    });
    this.innerCoords = getPolygonCoords({
      points: COMPARTMENT_SQUARE_POINTS,
      x: this.x + this.thickness,
      y: this.y + this.thickness,
      height: this.height - 2 * this.thickness,
      width: this.width - 2 * this.thickness,
      pointToProjection: this.pointToProjection,
    });
  }
}
