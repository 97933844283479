import {
  displayAddOverlayGroupDrawer,
  displayAddOverlaysDrawer,
} from '@/redux/drawer/drawer.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { authenticatedUserSelector, loadingUserSelector } from '@/redux/user/user.selectors';
import { Button } from '@/shared/Button';
import { userOverlaysDataSelector } from '@/redux/overlays/overlays.selectors';
import React from 'react';
import { overlayGroupsSelector } from '@/redux/overlayGroup/overlayGroup.selectors';
import { OverlayGroup } from '@/types/models';
import { UserOverlaysGroup } from './UserOverlaysGroup';

export const UserOverlays = (): JSX.Element => {
  const userOverlays = useAppSelector(userOverlaysDataSelector);
  const overlayGroups = useAppSelector(overlayGroupsSelector);

  const dispatch = useAppDispatch();
  const loadingUser = useAppSelector(loadingUserSelector);
  const authenticatedUser = useAppSelector(authenticatedUserSelector);
  const isPending = loadingUser === 'pending';

  const handleAddOverlay = (): void => {
    dispatch(displayAddOverlaysDrawer());
  };
  const handleAddOverlayGroup = (): void => {
    dispatch(displayAddOverlayGroupDrawer());
  };

  return (
    <div className="py-6">
      {isPending && <h1>Loading</h1>}

      {!isPending && !authenticatedUser && (
        <div className="px-6">
          <p className="mb-5 font-semibold">User provided overlays:</p>
          <p className="mb-5 text-sm">
            You are not logged in, please login to upload and view custom overlays
          </p>
        </div>
      )}

      {authenticatedUser && (
        <>
          <div className="flex items-center justify-between px-6">
            <p className="font-semibold">User provided overlays:</p>
            <Button onClick={handleAddOverlayGroup} aria-label="add overlay group button">
              Add group
            </Button>
            <Button onClick={handleAddOverlay} aria-label="add overlay button">
              Add overlay
            </Button>
          </div>
          {overlayGroups.map((group: OverlayGroup) => (
            <UserOverlaysGroup
              key={group.id}
              groupName={group.name}
              groupId={group.id}
              overlays={userOverlays.filter(overlay => overlay.group === group.id)}
            />
          ))}
        </>
      )}
    </div>
  );
};
