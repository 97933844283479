import { ZERO } from '@/constants/common';
import {
  getChemicalsForBioEntityDrawerTarget,
  getDrugsForBioEntityDrawerTarget,
} from '@/redux/drawer/drawer.thunks';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { DrawerHeading } from '@/shared/DrawerHeading';
import { ElementSearchResultType } from '@/types/models';
import { CommentItem } from '@/components/Map/Drawer/BioEntityDrawer/Comments/CommentItem.component';
import { getTypeBySBOTerm } from '@/utils/bioEntity/getTypeBySBOTerm';
import { ModificationResidueItem } from '@/components/Map/Drawer/BioEntityDrawer/ModificationResidueItem';
import React from 'react';
import { AnnotationItemList } from '@/components/Map/Drawer/BioEntityDrawer/AnnotationItem/AnnotationItemList.component';
import {
  compartmentNameByIdSelector,
  currentDrawerElementCommentsSelector,
  currentDrawerModelElementRelatedSubmapSelector,
  currentDrawerModelElementSelector,
} from '@/redux/modelElements/modelElements.selector';
import { projectDataSelector } from '@/redux/project/project.selectors';
import { showToast } from '@/utils/showToast';
import { IconButton } from '@/shared/IconButton';
import { openElementGlyphModal } from '@/redux/modal/modal.slice';
import { hasPrivilegeToWriteProjectSelector } from '@/redux/user/user.selectors';
import { CollapsibleSection } from '../ExportDrawer/CollapsibleSection';
import { AssociatedSubmap } from './AssociatedSubmap';
import { ChemicalsList } from './ChemicalsList';
import { DrugsList } from './DrugsList';
import { OverlayData } from './OverlayData';

const TARGET_PREFIX: ElementSearchResultType = `ALIAS`;

export const BioEntityDrawer = (): React.ReactNode => {
  const dispatch = useAppDispatch();
  const modelElement = useAppSelector(currentDrawerModelElementSelector);
  const commentsData = useAppSelector(currentDrawerElementCommentsSelector);
  const relatedSubmap = useAppSelector(currentDrawerModelElementRelatedSubmapSelector);
  const hasPrivilegeToWriteProject = useAppSelector(hasPrivilegeToWriteProjectSelector);
  const currentTargetId = modelElement?.id ? `${TARGET_PREFIX}:${modelElement.id}` : '';

  const project = useAppSelector(projectDataSelector);

  const fetchChemicalsForTarget = (): void => {
    if (project === undefined || project.disease === null || project.disease === undefined) {
      showToast({
        type: 'info',
        message: `Project disease not defined. Only projects with defined disease have chemical search available`,
      });
    } else {
      dispatch(getChemicalsForBioEntityDrawerTarget(currentTargetId));
    }
  };
  const compartmentName = useAppSelector(state =>
    compartmentNameByIdSelector(state, modelElement?.compartment),
  );

  const fetchDrugsForTarget = (): void => {
    dispatch(getDrugsForBioEntityDrawerTarget(currentTargetId));
  };
  if (!modelElement) {
    return null;
  }

  const isCommentAvailable = commentsData.length > ZERO;
  const modificationResidues = (
    modelElement.modificationResidues ? modelElement.modificationResidues : []
  ).filter(modificationResidue => modificationResidue.state && modificationResidue.state !== '');
  const isModificationAvailable = modificationResidues.length > ZERO;

  const type = getTypeBySBOTerm(modelElement.sboTerm, modelElement.shape);

  const editElementGlyph = (): void => {
    dispatch(
      openElementGlyphModal({
        model: modelElement.model,
        id: modelElement.id,
        glyph: modelElement.glyph?.id || null,
      }),
    );
  };

  return (
    <div className="h-calc-drawer" data-testid="bioentity-drawer">
      <DrawerHeading
        title={
          <div className="flex items-center">
            <span className="font-normal">{type}:</span>&nbsp;
            {modelElement.name}
            {hasPrivilegeToWriteProject && (
              <IconButton
                className="bg-white-pearl"
                classNameIcon="fill-font-500"
                icon="pencil"
                onClick={editElementGlyph}
              />
            )}
          </div>
        }
      />
      <div className="flex max-h-full flex-col gap-6 overflow-y-auto p-6">
        <div className="text-sm font-normal">
          Compartment: <b className="font-semibold">{compartmentName || 'default'}</b>
        </div>
        {modelElement.fullName && (
          <div className="text-sm font-normal">
            Full name: <b className="font-semibold">{modelElement.fullName}</b>
          </div>
        )}
        {modelElement.notes && (
          <span className="[&_a]:text-blue-600 [&_a]:underline [&_a]:hover:text-blue-800">
            <hr className="border-b border-b-divide" />
            <div
              className="mt-2 text-sm font-normal"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: modelElement.notes }}
            />
          </span>
        )}
        {isModificationAvailable && (
          <h3 className="font-semibold">Post-translational modifications:</h3>
        )}
        {isModificationAvailable && (
          <ul className="ml-5 list-disc">
            {modificationResidues.map(residue => (
              <ModificationResidueItem key={residue.id} state={residue.state} name={residue.name} />
            ))}
          </ul>
        )}
        <AnnotationItemList references={modelElement.references} />
        <AssociatedSubmap />
        {!relatedSubmap && (
          <>
            <CollapsibleSection title="Drugs for target" onOpened={fetchDrugsForTarget}>
              <DrugsList />
            </CollapsibleSection>
            <CollapsibleSection title="Chemicals for target" onOpened={fetchChemicalsForTarget}>
              <ChemicalsList />
            </CollapsibleSection>
          </>
        )}
        <OverlayData
          isShowGroupedOverlays={Boolean(relatedSubmap)}
          isShowOverlayBioEntityName={Boolean(relatedSubmap)}
        />
        {isCommentAvailable && <div className="font-bold"> Comments</div>}
        {isCommentAvailable &&
          commentsData.map(comment => <CommentItem key={comment.id} comment={comment} />)}
      </div>
    </div>
  );
};
