/* eslint-disable no-magic-numbers */
import { ColorTilePicker } from '@/shared/ColorPicker/ColorTilePicker.component';
import hexToRgbIntAlpha from '@/utils/convert/hexToRgbIntAlpha';
import { Color } from '@/types/models';
import { LayerOvalFactoryForm } from '@/components/FunctionalArea/Modal/LayerOvalFactoryModal/LayerOvalFactory.types';

type LayerOvalFormProps = {
  data: LayerOvalFactoryForm;
  onChange: (value: number | string | Color, key: string) => void;
};

export const LayerOvalForm = ({ data, onChange }: LayerOvalFormProps): React.JSX.Element => {
  return (
    <div>
      <span>Color:</span>
      <ColorTilePicker
        initialColor={data.color}
        colorChange={color => onChange(hexToRgbIntAlpha(color), 'color')}
      />
    </div>
  );
};
