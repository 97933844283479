import { WebSocketEntityUpdateInterface } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.types';
import { store } from '@/redux/store';
import { ENTITY_OPERATION_TYPES } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.constants';
import { getLayer } from '@/redux/layers/layers.thunks';
import updateLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/updateLayer';
import { MapInstance } from '@/types/map';
import { layerDelete } from '@/redux/layers/layers.slice';
import removeLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/removeLayer';

export default async function processLayer({
  data,
  mapInstance,
}: {
  data: WebSocketEntityUpdateInterface;
  mapInstance: MapInstance;
}): Promise<void> {
  const { dispatch } = store;
  if (
    data.type === ENTITY_OPERATION_TYPES.ENTITY_CREATED ||
    data.type === ENTITY_OPERATION_TYPES.ENTITY_UPDATED
  ) {
    const updatedLayer = await dispatch(
      getLayer({
        modelId: data.mapId,
        layerId: data.entityId,
      }),
    ).unwrap();
    if (!updatedLayer) {
      return;
    }
    if (data.type === ENTITY_OPERATION_TYPES.ENTITY_UPDATED) {
      updateLayer({ mapInstance, updatedLayer });
    }
  } else if (data.type === ENTITY_OPERATION_TYPES.ENTITY_DELETED) {
    dispatch(
      layerDelete({
        modelId: data.mapId,
        layerId: data.entityId,
      }),
    );
    removeLayer({ mapInstance, layerId: data.entityId });
  }
}
