import { openBioEntityDrawerById, selectTab } from '@/redux/drawer/drawer.slice';
import { AppDispatch } from '@/redux/store';
import { FeatureLike } from 'ol/Feature';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { searchFitBounds } from '@/services/pluginsManager/map/triggerSearch/searchFitBounds';
import { FEATURE_TYPE } from '@/constants/features';
import { ModelElement } from '@/types/models';
import { addNumbersToEntityNumberData } from '@/redux/entityNumber/entityNumber.slice';
import { setModelElementSearch } from '@/redux/modelElements/modelElements.slice';
import { handleOpenImmediateLink } from '@/components/Map/MapViewer/utils/listeners/mapSingleClick/handleOpenImmediateLink';

/* prettier-ignore */
export const leftClickHandleAlias =
  (dispatch: AppDispatch, hasFitBounds = false) =>
    async (modelElements: Array<ModelElement>, feature: FeatureLike, modelId: number): Promise<void> => {
      const id = feature.get('id');

      const modelElement = modelElements.find(element =>
        element.id === id
      );
      if(!modelElement) {
        return;
      }
      handleOpenImmediateLink(modelElement);

      dispatch(selectTab(`${id}`));
      dispatch(openBioEntityDrawerById(id));

      dispatch(setModelElementSearch({ modelElement, perfect: true }));
      dispatch(addNumbersToEntityNumberData([modelElement.elementId]));

      const searchValue = { id, modelId, type: FEATURE_TYPE.ALIAS };
      PluginsEventBus.dispatchEvent('onSearch', {
        type: 'bioEntity',
        searchValues: [searchValue],
        results: [[{
          perfect: true,
          bioEntity: modelElement
        }]
        ],
      });
      PluginsEventBus.dispatchEvent('onBioEntityClick', searchValue);


      if (hasFitBounds) {
        searchFitBounds();
      }
    };
