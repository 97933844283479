import { PINS_COLORS, TEXT_COLOR } from '@/constants/canvas';
import { ModelElement } from '@/types/models';
import { PinType } from '@/types/pin';
import { addAlphaToHexString } from '@/utils/convert/addAlphaToHexString';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { getPinFeature } from './getPinFeature';
import { getPinStyle } from './getPinStyle';

const INACTIVE_ELEMENT_OPACITY = 0.5;

export const getModelElementSingleFeature = (
  modelElement: ModelElement,
  {
    pointToProjection,
    type,
    value,
    isActive,
  }: {
    pointToProjection: UsePointToProjectionResult;
    type: PinType;
    value: number;
    isActive: boolean;
  },
): Feature => {
  const color = isActive
    ? PINS_COLORS[type]
    : addAlphaToHexString(PINS_COLORS[type], INACTIVE_ELEMENT_OPACITY);

  const textColor = isActive
    ? TEXT_COLOR
    : addAlphaToHexString(TEXT_COLOR, INACTIVE_ELEMENT_OPACITY);

  const feature = getPinFeature(modelElement, pointToProjection, type);
  const style = getPinStyle({
    color,
    value,
    textColor,
  });

  feature.setStyle(style);
  return feature;
};
