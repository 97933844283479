import { WebSocketEntityUpdateInterface } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.types';
import processLayerImage from '@/components/Map/MapViewer/utils/websocket/processLayerImage';
import { MapInstance } from '@/types/map';
import { ENTITY_TYPES } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.constants';
import processLayerText from '@/components/Map/MapViewer/utils/websocket/processLayerText';
import processLayerOval from '@/components/Map/MapViewer/utils/websocket/processLayerOval';
import processLayer from '@/components/Map/MapViewer/utils/websocket/processLayer';
import processLayerRect from '@/components/Map/MapViewer/utils/websocket/processLayerRect';
import processLayerLine from '@/components/Map/MapViewer/utils/websocket/processLayerLine';

export default async function processMessage({
  jsonMessage,
  mapInstance,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonMessage: WebSocketEntityUpdateInterface;
  mapInstance: MapInstance;
}): Promise<void> {
  try {
    if (jsonMessage.entityType === ENTITY_TYPES.LAYER_IMAGE) {
      await processLayerImage({ data: jsonMessage, mapInstance });
    } else if (jsonMessage.entityType === ENTITY_TYPES.LAYER_TEXT) {
      await processLayerText({ data: jsonMessage, mapInstance });
    } else if (jsonMessage.entityType === ENTITY_TYPES.LAYER_OVAL) {
      await processLayerOval({ data: jsonMessage, mapInstance });
    } else if (jsonMessage.entityType === ENTITY_TYPES.LAYER_RECTANGLE) {
      await processLayerRect({ data: jsonMessage, mapInstance });
    } else if (jsonMessage.entityType === ENTITY_TYPES.LAYER_LINE) {
      await processLayerLine({ data: jsonMessage, mapInstance });
    } else if (jsonMessage.entityType === ENTITY_TYPES.LAYER) {
      await processLayer({ data: jsonMessage, mapInstance });
    }
  } catch {
    throw new Error(`Process websocket message error`);
  }
}
