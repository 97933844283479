import { OverlayBioEntityRender } from '@/types/OLrendering';
import { OverlayBioEntityGroupedElementsType } from '@/components/Map/MapViewer/MapViewer.types';
import findMatchingSubmapLinkRectangle from '@/components/Map/MapViewer/utils/mapElementsRendering/overlay/findMatchingSubmapLinkRectangle';

export default function groupOverlayEntities(
  overlayBioEntities: Array<OverlayBioEntityRender>,
): OverlayBioEntityGroupedElementsType {
  const groupedElements: OverlayBioEntityGroupedElementsType = {};

  overlayBioEntities.forEach(overlayBioEntity => {
    if (overlayBioEntity.type !== 'submap-link') {
      return;
    }
    if (!groupedElements[overlayBioEntity.overlayId]) {
      groupedElements[overlayBioEntity.overlayId] = [];
    }

    const matchedElement = findMatchingSubmapLinkRectangle(
      groupedElements[overlayBioEntity.overlayId],
      overlayBioEntity,
    );

    if (!matchedElement) {
      groupedElements[overlayBioEntity.overlayId].push({
        ...overlayBioEntity,
        amount: 1,
      });
    } else {
      matchedElement.amount += 1;
    }
  });

  return groupedElements;
}
