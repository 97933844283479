/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import './LayerLineFactoryModal.styles.css';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { Button } from '@/shared/Button';
import { BLACK_COLOR } from '@/components/Map/MapViewer/MapViewer.constants';
import { LayerLineForm } from '@/components/FunctionalArea/Modal/LayerLineFactoryModal/LayerLineForm.component';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import {
  layersDrawLayerSelector,
  maxObjectZIndexForLayerSelector,
} from '@/redux/layers/layers.selectors';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { Color } from '@/types/models';
import { layerLineFactoryStateSelector } from '@/redux/modal/modal.selector';
import { addLayerLine } from '@/redux/layers/layers.thunks';
import { showToast } from '@/utils/showToast';
import { layerAddLine } from '@/redux/layers/layers.slice';
import drawElementOnLayer from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/drawElementOnLayer';
import { closeModal } from '@/redux/modal/modal.slice';
import { mapEditToolsSetActiveAction } from '@/redux/mapEditTools/mapEditTools.slice';
import { SerializedError } from '@reduxjs/toolkit';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useMapInstance } from '@/utils/context/mapInstanceContext';
import {
  LayerLineFactoryForm,
  LayerLineFactoryPayload,
} from '@/components/FunctionalArea/Modal/LayerLineFactoryModal/LayerLineFactory.types';
import { arrowTypesKeysSelector, lineTypesKeysSelector } from '@/redux/shapes/shapes.selectors';
import {
  DEFAULT_ARROW,
  DEFAULT_ARROW_ANGLE,
  DEFAULT_ARROW_LENGTH,
  DEFAULT_ARROW_LINE_TYPE,
  DEFAULT_ARROW_SCALE,
  DEFAULT_LINE_TYPE,
  MIN_LINE_WIDTH,
} from '@/components/FunctionalArea/Modal/LayerLineFactoryModal/LayerLineFactory.constants';

export const LayerLineFactoryModal: React.FC = () => {
  const drawLayer = useAppSelector(layersDrawLayerSelector);
  const currentModelId = useAppSelector(currentModelIdSelector);
  const layerLineFactoryState = useAppSelector(layerLineFactoryStateSelector);
  const maxZIndex = useAppSelector(state => maxObjectZIndexForLayerSelector(state, drawLayer));
  const dispatch = useAppDispatch();
  const lineTypes = useAppSelector(lineTypesKeysSelector).map(lineType => ({
    id: lineType,
    name: lineType,
  }));
  const arrowTypes = useAppSelector(arrowTypesKeysSelector).map(arrowType => ({
    id: arrowType,
    name: arrowType,
  }));
  const { mapInstance } = useMapInstance();

  const [isSending, setIsSending] = useState<boolean>(false);
  const [data, setData] = useState<LayerLineFactoryForm>({
    color: BLACK_COLOR,
    lineType: DEFAULT_LINE_TYPE,
    width: MIN_LINE_WIDTH,
    startArrow: DEFAULT_ARROW,
    startArrowScale: DEFAULT_ARROW_SCALE,
    endArrow: DEFAULT_ARROW,
    endArrowScale: DEFAULT_ARROW_SCALE,
  });

  const getDataToSend = (): LayerLineFactoryPayload => {
    return {
      color: data.color,
      lineType: data.lineType,
      width: data.width,
      startArrow: {
        arrowType: data.startArrow,
        angle: DEFAULT_ARROW_ANGLE,
        lineType: DEFAULT_ARROW_LINE_TYPE,
        length: DEFAULT_ARROW_LENGTH * data.startArrowScale,
      },
      endArrow: {
        arrowType: data.endArrow,
        angle: DEFAULT_ARROW_ANGLE,
        lineType: DEFAULT_ARROW_LINE_TYPE,
        length: DEFAULT_ARROW_LENGTH * data.endArrowScale,
      },
      segments: layerLineFactoryState || [],
      z: maxZIndex + 1,
    };
  };

  const handleSubmit = async (): Promise<void> => {
    if (!layerLineFactoryState || !drawLayer) {
      return;
    }
    try {
      const layerLine = await dispatch(
        addLayerLine({
          modelId: currentModelId,
          layerId: drawLayer,
          payload: getDataToSend(),
        }),
      ).unwrap();
      if (!layerLine) {
        showToast({
          type: 'error',
          message: 'An error occurred while adding a new line.',
        });
        return;
      }
      dispatch(layerAddLine({ modelId: currentModelId, layerId: drawLayer, layerLine }));
      drawElementOnLayer({
        mapInstance,
        activeLayer: drawLayer,
        object: layerLine,
        drawFunctionKey: 'drawLine',
      });
      showToast({
        type: 'success',
        message: 'A new line has been successfully added.',
      });
      dispatch(closeModal());
      dispatch(mapEditToolsSetActiveAction(null));
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while adding a new line.',
      });
    } finally {
      setIsSending(false);
    }
  };

  const changeValues = (value: string | number | Color, key: string): void => {
    setData(prevData => ({ ...prevData, [key]: value }));
  };

  return (
    <div className="relative flex w-[550px] flex-col gap-4 rounded-b-lg border border-t-[#E1E0E6] bg-white p-[24px]">
      {isSending && (
        <div className="c-layer-line-factory-modal-loader">
          <LoadingIndicator width={44} height={44} />
        </div>
      )}
      <LayerLineForm
        onChange={changeValues}
        data={data}
        lineTypes={lineTypes}
        arrowTypes={arrowTypes}
      />
      <hr />
      <Button
        type="button"
        onClick={handleSubmit}
        className="justify-center self-end justify-self-end text-base font-medium"
      >
        Submit
      </Button>
    </div>
  );
};
