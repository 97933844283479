import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import React from 'react';
import {
  searchModelElementsListSelector,
  searchModelElementsLoadingSelector,
} from '@/redux/modelElements/modelElements.selector';
import { BioEntitiesPinsListItem } from '../../SearchDrawerWrapper/BioEntitiesResultsList/BioEntitiesPinsList/BioEntitiesPinsListItem';

export const ConnectedBioEntitiesList = (): React.ReactNode => {
  const bioEntityLoading = useAppSelector(searchModelElementsLoadingSelector);
  const searchModelElements = useAppSelector(searchModelElementsListSelector);
  const isPending = bioEntityLoading === 'pending';

  if (isPending) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <h3 className="mb-1 font-semibold">Reaction elements:</h3>
      {searchModelElements &&
        searchModelElements.map(item => (
          <BioEntitiesPinsListItem
            name={item.modelElement.name}
            pin={item.modelElement}
            key={item.modelElement.name}
          />
        ))}
    </div>
  );
};
