import { Button } from '@/shared/Button';
import { Input } from '@/shared/Input';
import React from 'react';
import { useEditOverlayGroup } from './hooks/useEditOverlayGroup';

export const EditOverlayGroupModal = (): React.ReactNode => {
  const {
    name,
    order,
    handleCancelEdit,
    handleNameChange,
    handleOrderChange,
    handleRemoveOverlayGroup,
    handleSaveEditedOverlayGroup,
  } = useEditOverlayGroup();

  return (
    <div className="w-full border border-t-[#E1E0E6] bg-white p-[24px]">
      <form>
        <label className="text-sm font-semibold" htmlFor="overlayGroupName">
          Name
          <Input
            type="text"
            value={name}
            onChange={handleNameChange}
            className="mt-2.5 text-sm font-medium"
            data-testid="overlay-group-name"
            name="overlayGroupName"
            id="overlayGroupName"
          />
        </label>
        <label className="mt-5 block text-sm font-semibold" htmlFor="overlayGroupOrder">
          Order
          <Input
            type="number"
            value={order}
            onChange={handleOrderChange}
            className="mt-2.5 text-sm font-medium"
            data-testid="overlay-group-order"
            name="overlayGroupOrder"
            id="overlayGroupOrder"
          />
        </label>
        <div className="mt-10 flex items-center justify-between gap-5 text-center">
          <Button
            type="button"
            variantStyles="ghost"
            className="flex-1 justify-center"
            onClick={handleCancelEdit}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            type="button"
            variantStyles="ghost"
            className="flex-1 justify-center"
            onClick={handleRemoveOverlayGroup}
            data-testid="remove-button"
          >
            Remove
          </Button>

          <Button
            type="button"
            className="flex-1 justify-center"
            onClick={handleSaveEditedOverlayGroup}
            data-testid="save-button"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
