import React, { JSX, useMemo } from 'react';
import { LayerLine } from '@/types/models';
import { Icon } from '@/shared/Icon';
import { LayersDrawerObjectActions } from '@/components/Map/Drawer/LayersDrawer/LayersDrawerObjectActions.component';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { mapEditToolsLayerObjectSelector } from '@/redux/mapEditTools/mapEditTools.selectors';
import { hasPrivilegeToWriteProjectSelector } from '@/redux/user/user.selectors';
import { mapEditToolsSetLayerLine } from '@/redux/mapEditTools/mapEditTools.slice';

interface LayersDrawerOvalItemProps {
  layerLine: LayerLine;
  moveToFront: () => void;
  moveToBack: () => void;
  removeObject: () => void;
  centerObject: () => void;
  editObject: () => void;
  isLayerVisible: boolean;
  isLayerActive: boolean;
}

export const LayersDrawerLineItem = ({
  layerLine,
  moveToFront,
  moveToBack,
  removeObject,
  centerObject,
  editObject,
  isLayerVisible,
  isLayerActive,
}: LayersDrawerOvalItemProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const activeLayerObject = useAppSelector(mapEditToolsLayerObjectSelector);
  const hasPrivilegeToWriteProject = useAppSelector(hasPrivilegeToWriteProjectSelector);

  const showActions = useMemo(() => {
    return activeLayerObject?.id === layerLine.id;
  }, [activeLayerObject?.id, layerLine.id]);

  const canSelectItem = useMemo(() => {
    return isLayerVisible && isLayerActive && hasPrivilegeToWriteProject;
  }, [isLayerVisible, isLayerActive, hasPrivilegeToWriteProject]);

  const selectItem = useMemo(() => {
    return (): void => {
      if (canSelectItem) {
        dispatch(mapEditToolsSetLayerLine(layerLine));
      }
    };
  }, [canSelectItem, dispatch, layerLine]);

  const handleKeyPress = (): void => {};

  return (
    <div
      className="flex min-h-[24px] items-center justify-between gap-2"
      id={`layer-line-item-${layerLine.id}`}
    >
      <div
        className={`flex gap-2 ${canSelectItem ? 'cursor-pointer' : 'cursor-default'}`}
        onClick={selectItem}
        tabIndex={0}
        onKeyDown={handleKeyPress}
        role="button"
      >
        <Icon name="line" className="shrink-0" />
        <span className="truncate">line - {layerLine.id}</span>
      </div>
      {showActions && (
        <LayersDrawerObjectActions
          moveToFront={moveToFront}
          moveToBack={moveToBack}
          removeObject={removeObject}
          centerObject={centerObject}
          editObject={editObject}
        />
      )}
    </div>
  );
};
