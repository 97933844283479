import { Icon } from '@/shared/Icon';
import { twMerge } from 'tailwind-merge';

import type { ButtonHTMLAttributes } from 'react';
import type { IconTypes } from '@/types/iconTypes';

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  classNameIcon?: string;
  icon: IconTypes;
  isActive?: boolean;
}

const getActiveFillOrStrokeColor = (icon: IconTypes): string => {
  if (icon !== 'plugin') return 'fill-primary-500';
  return 'stroke-primary-500';
};

export const IconButton = ({
  className = '',
  classNameIcon = '',
  icon,
  isActive = false,
  ...props
}: IconButtonProps): JSX.Element => {
  if (typeof icon === 'undefined') {
    throw new Error('IconButton component must have a icon property!');
  }

  const isStrokeIcon = [
    'plugin',
    'move-back',
    'move-front',
    'center',
    'eye',
    'crossed-eye',
    'padlock-open',
    'padlock-locked',
    'layers',
    'edit',
    'arrow-double-up',
    'arrow-double-down',
  ].includes(icon);

  return (
    <button
      className={twMerge(
        'group flex h-10 w-10 items-center justify-center rounded-e rounded-s py-2',
        'bg-cultured active:bg-white-pearl',
        className,
        isActive && 'bg-white-pearl',
      )}
      data-testid="icon-button"
      type="button"
      {...props}
    >
      <Icon
        className={twMerge(
          isStrokeIcon
            ? 'stroke-font-400 group-hover:stroke-primary-500 group-active:stroke-primary-500'
            : 'fill-font-400 group-hover:fill-primary-500 group-active:fill-primary-500',
          ['move-back', 'move-front'].includes(icon) &&
            'fill-font-400 stroke-font-400 group-hover:fill-primary-500 group-hover:stroke-primary-500 group-active:fill-primary-500 group-active:stroke-primary-500',
          isActive && getActiveFillOrStrokeColor(icon),
          classNameIcon,
        )}
        name={icon}
      />
    </button>
  );
};

IconButton.displayName = 'IconButton';
