/* eslint-disable no-magic-numbers */
import {
  LayerImage as LayerImageModel,
  LayerLine as LayerLineModel,
  LayerOval as LayerOvalModel,
  LayerRect as LayerRectModel,
  LayerText as LayerTextModel,
} from '@/types/models';
import { MapInstance } from '@/types/map';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Feature } from 'ol';
import { LineString, MultiPolygon } from 'ol/geom';
import Polygon from 'ol/geom/Polygon';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { HorizontalAlign, VerticalAlign } from '@/components/Map/MapViewer/MapViewer.types';
import { ArrowTypeDict, LineTypeDict } from '@/redux/shapes/shapes.types';
import { LAYER_TYPE } from '@/components/Map/MapViewer/MapViewer.constants';
import { MapSize } from '@/redux/map/map.types';
import LayerText from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/elements/LayerText';
import LayerImage from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/elements/LayerImage';
import LayerRect from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/elements/LayerRect';
import LayerOval from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/elements/LayerOval';
import LayerLine from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/elements/LayerLine';

export interface LayerProps {
  zIndex: number;
  texts: { [key: string]: LayerTextModel };
  rects: { [key: string]: LayerRectModel };
  ovals: { [key: string]: LayerOvalModel };
  lines: { [key: string]: LayerLineModel };
  images: { [key: string]: LayerImageModel };
  visible: boolean;
  layerId: number;
  lineTypes: LineTypeDict;
  arrowTypes: ArrowTypeDict;
  mapInstance: MapInstance;
  mapSize: MapSize;
  pointToProjection: UsePointToProjectionResult;
}

export default class Layer {
  layerId: number;

  texts: { [key: string]: LayerTextModel };

  rects: { [key: string]: LayerRectModel };

  ovals: { [key: string]: LayerOvalModel };

  lines: { [key: string]: LayerLineModel };

  images: { [key: string]: LayerImageModel };

  lineTypes: LineTypeDict;

  arrowTypes: ArrowTypeDict;

  pointToProjection: UsePointToProjectionResult;

  mapInstance: MapInstance;

  mapSize: MapSize;

  vectorSource: VectorSource<Feature<Polygon> | Feature<LineString> | Feature<MultiPolygon>>;

  vectorLayer: VectorLayer<
    VectorSource<Feature<Polygon> | Feature<LineString> | Feature<MultiPolygon>>
  >;

  constructor({
    zIndex,
    texts,
    rects,
    ovals,
    lines,
    images,
    visible,
    layerId,
    lineTypes,
    arrowTypes,
    mapInstance,
    mapSize,
    pointToProjection,
  }: LayerProps) {
    this.vectorSource = new VectorSource({});
    this.vectorLayer = new VectorLayer({
      zIndex,
      visible,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    this.texts = texts;
    this.rects = rects;
    this.ovals = ovals;
    this.lines = lines;
    this.images = images;
    this.lineTypes = lineTypes;
    this.arrowTypes = arrowTypes;
    this.pointToProjection = pointToProjection;
    this.mapInstance = mapInstance;
    this.mapSize = mapSize;
    this.layerId = layerId;

    const textsFeatures = this.getTextsFeatures();
    this.vectorSource.addFeatures(textsFeatures);

    const rectsFeatures = this.getRectsFeatures();
    this.vectorSource.addFeatures(rectsFeatures);

    const imagesFeatures = this.getImagesFeatures();
    this.vectorSource.addFeatures(imagesFeatures);

    const ovalsFeatures = this.getOvalsFeatures();
    this.vectorSource.addFeatures(ovalsFeatures);

    const { linesFeatures, arrowsFeatures } = this.getLinesFeatures();
    this.vectorSource.addFeatures(linesFeatures);
    this.vectorSource.addFeatures(arrowsFeatures);

    this.vectorLayer.setSource(this.vectorSource);
    this.vectorLayer.set('type', LAYER_TYPE.ADDITIONAL_LAYER);
    this.vectorLayer.set('id', layerId);
    this.vectorLayer.set('imagesFeatures', imagesFeatures);
    this.vectorLayer.set('textsFeatures', textsFeatures);
    this.vectorLayer.set('rectsFeatures', rectsFeatures);
    this.vectorLayer.set('ovalsFeatures', ovalsFeatures);
    this.vectorLayer.set('linesFeatures', linesFeatures);
    this.vectorLayer.set('drawImage', this.drawImage.bind(this));
    this.vectorLayer.set('drawText', this.drawText.bind(this));
    this.vectorLayer.set('drawLine', this.drawLine.bind(this));
    this.vectorLayer.set('drawOval', this.drawOval.bind(this));
    this.vectorLayer.set('drawRect', this.drawRect.bind(this));
  }

  private getTextsFeatures = (): Array<Feature<Polygon>> => {
    return Object.values(this.texts).map(text => this.getTextFeature(text));
  };

  private getTextFeature(text: LayerTextModel): Feature<Polygon> {
    const textObject = new LayerText({
      elementId: text.id,
      x: text.x,
      y: text.y,
      zIndex: text.z,
      width: text.width,
      height: text.height,
      layer: text.layer,
      color: text.color,
      borderColor: text.borderColor,
      backgroundColor: text.backgroundColor,
      fontSize: text.fontSize,
      text: text.notes,
      verticalAlign: text.verticalAlign as VerticalAlign,
      horizontalAlign: text.horizontalAlign as HorizontalAlign,
      pointToProjection: this.pointToProjection,
      mapInstance: this.mapInstance,
      mapSize: this.mapSize,
    });
    return textObject.feature;
  }

  private drawText(text: LayerTextModel): void {
    const textFeature = this.getTextFeature(text);
    this.vectorSource.addFeature(textFeature);
  }

  private getRectsFeatures = (): Array<Feature<Polygon>> => {
    return Object.values(this.rects).map(rect => {
      return this.getRectFeature(rect);
    });
  };

  private getRectFeature = (rect: LayerRectModel): Feature<Polygon> => {
    const rectObject = new LayerRect({
      elementId: rect.id,
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
      layer: rect.layer,
      zIndex: rect.z,
      fillColor: rect.fillColor,
      borderColor: rect.borderColor,
      lineWidth: rect.lineWidth,
      pointToProjection: this.pointToProjection,
      mapInstance: this.mapInstance,
      mapSize: this.mapSize,
    });
    return rectObject.feature;
  };

  private drawRect(rect: LayerRectModel): void {
    const rectFeature = this.getRectFeature(rect);
    this.vectorSource.addFeature(rectFeature);
  }

  private getOvalsFeatures = (): Array<Feature<Polygon>> => {
    return Object.values(this.ovals).map(oval => {
      return this.getOvalFeature(oval);
    });
  };

  private getOvalFeature = (oval: LayerOvalModel): Feature<Polygon> => {
    const ovalObject = new LayerOval({
      elementId: oval.id,
      x: oval.x,
      y: oval.y,
      width: oval.width,
      height: oval.height,
      layer: oval.layer,
      zIndex: oval.z,
      color: oval.color,
      borderColor: oval.borderColor,
      lineWidth: oval.lineWidth,
      pointToProjection: this.pointToProjection,
      mapInstance: this.mapInstance,
      mapSize: this.mapSize,
    });
    return ovalObject.feature;
  };

  private drawOval(oval: LayerOvalModel): void {
    const ovalFeature = this.getOvalFeature(oval);
    const ovalsFeatures = this.vectorLayer.get('ovalsFeatures');
    if (ovalsFeatures && Array.isArray(ovalsFeatures)) {
      ovalsFeatures.push(ovalFeature);
    }
    this.vectorSource.addFeature(ovalFeature);
  }

  private getLinesFeatures = (): {
    linesFeatures: Array<Feature<LineString>>;
    arrowsFeatures: Array<Feature<MultiPolygon>>;
  } => {
    const linesFeatures: Array<Feature<LineString>> = [];
    const linesArrowsFeatures: Array<Feature<MultiPolygon>> = [];
    Object.values(this.lines).forEach(line => {
      const { lineFeature, arrowsFeatures } = this.getLineFeature(line);
      linesFeatures.push(lineFeature);
      linesArrowsFeatures.push(...arrowsFeatures);
    });
    return {
      linesFeatures,
      arrowsFeatures: linesArrowsFeatures,
    };
  };

  private getLineFeature = (
    line: LayerLineModel,
  ): {
    lineFeature: Feature<LineString>;
    arrowsFeatures: Array<Feature<MultiPolygon>>;
  } => {
    const lineObject = new LayerLine({
      layerLine: line,
      layer: this.layerId,
      lineTypes: this.lineTypes,
      arrowTypes: this.arrowTypes,
      pointToProjection: this.pointToProjection,
      vectorSource: this.vectorSource,
      mapInstance: this.mapInstance,
      mapSize: this.mapSize,
    });
    const arrowsFeatures: Array<Feature<MultiPolygon>> = [];
    if (lineObject.startArrowFeature) {
      arrowsFeatures.push(lineObject.startArrowFeature);
    }
    if (lineObject.endArrowFeature) {
      arrowsFeatures.push(lineObject.endArrowFeature);
    }
    return {
      lineFeature: lineObject.lineFeature,
      arrowsFeatures,
    };
  };

  private drawLine(line: LayerLineModel): void {
    const { lineFeature, arrowsFeatures } = this.getLineFeature(line);
    this.vectorSource.addFeature(lineFeature);
    this.vectorSource.addFeatures(arrowsFeatures);
  }

  private getImagesFeatures(): Feature<Polygon>[] {
    return Object.values(this.images).map(image => {
      return this.getGlyphFeature(image);
    });
  }

  private drawImage(image: LayerImageModel): void {
    const glyphFeature = this.getGlyphFeature(image);
    const imagesFeatures = this.vectorLayer.get('imagesFeatures');
    if (imagesFeatures && Array.isArray(imagesFeatures)) {
      imagesFeatures.push(glyphFeature);
    }
    this.vectorSource.addFeature(glyphFeature);
  }

  private getGlyphFeature(image: LayerImageModel): Feature<Polygon> {
    const glyph = new LayerImage({
      elementId: image.id,
      glyphId: image.glyph,
      x: image.x,
      y: image.y,
      width: image.width,
      height: image.height,
      layer: image.layer,
      zIndex: image.z,
      pointToProjection: this.pointToProjection,
      mapInstance: this.mapInstance,
      mapSize: this.mapSize,
    });
    return glyph.feature;
  }
}
