import { PIN_ICON_ANY } from '@/constants/features';
import { Map } from 'ol';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { LAYER_ELEMENT_TYPES } from '@/components/Map/MapViewer/MapViewer.constants';

const isTargetHTMLElement = (target: string | HTMLElement | undefined): target is HTMLElement =>
  !!target && typeof target !== 'string' && 'style' in target;

/* prettier-ignore */
export const onPointerMove =
    (mapInstance: Map, event: MapBrowserEvent<PointerEvent>, showPointerMoveOnLine: (layerId: number) => boolean): void => {
      if (event.dragging) {
        return;
      }

      const pixel = mapInstance.getEventPixel(event.originalEvent);
      const feature = mapInstance.forEachFeatureAtPixel(pixel, firstFeature => {
        const isPinIcon = PIN_ICON_ANY.includes(firstFeature.get('type'));
        const isLayerLine = LAYER_ELEMENT_TYPES.LINE=== firstFeature.get('elementType');
        if (isPinIcon) {
          return firstFeature;
        }

        if(isLayerLine && showPointerMoveOnLine(firstFeature.get('layer'))) {
          return firstFeature;
        }
        return undefined;
      }, {hitTolerance: 3});

      const target = mapInstance.getTarget();
      if (isTargetHTMLElement(target)) {
        target.style.cursor = feature ? 'pointer' : '';
      }
    };
