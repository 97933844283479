import { createSelector } from '@reduxjs/toolkit';
import { rootSelector } from '@/redux/root/root.selectors';
import { currentModelIdSelector, modelsDataSelector } from '@/redux/models/models.selectors';
import {
  currentSearchedBioEntityId,
  currentSelectedSearchElement,
} from '@/redux/drawer/drawer.selectors';
import { Comment, MapModel, ModelElement } from '@/types/models';
import { MODEL_ELEMENTS_DEFAULT_COMPARTMENT_NAME } from '@/redux/modelElements/modelElements.constants';
import { COMPARTMENT_SBO_TERM } from '@/components/Map/MapViewer/MapViewer.constants';
import { MultiSearchData } from '@/types/fetchDataState';
import { SearchModelElementDataState } from '@/redux/modelElements/modelElements.types';
import { SIZE_OF_EMPTY_ARRAY } from '@/constants/common';
import { currentSelectedBioEntityIdSelector } from '@/redux/contextMenu/contextMenu.selector';
import { ElementIdTabObj } from '@/types/elements';
import { allCommentsSelectorOfCurrentMap } from '@/redux/comment/comment.selectors';

export const modelElementsStateSelector = createSelector(
  rootSelector,
  state => state.modelElements,
);

export const modelElementsDataSelector = createSelector(
  modelElementsStateSelector,
  state => state.data,
);

export const modelElementsStateForCurrentModelSelector = createSelector(
  modelElementsDataSelector,
  currentModelIdSelector,
  (data, currentModelId) => data[currentModelId],
);

export const modelElementsByModelIdSelector = createSelector(
  [modelElementsDataSelector, (_state, modelId: number): number => modelId],
  (data, modelId) => data[modelId]?.data || [],
);

export const modelElementsCurrentModelLoadingSelector = createSelector(
  modelElementsStateForCurrentModelSelector,
  state => state?.loading,
);

export const modelElementsAnyModelLoadingSelector = createSelector(
  modelElementsDataSelector,
  state => Object.values(state).some(modelElementState => modelElementState.loading === 'pending'),
);

export const modelElementsForCurrentModelSelector = createSelector(
  modelElementsStateForCurrentModelSelector,
  state => state?.data || [],
);

export const modelElementsWithSubmapConnectionForCurrentModelSelector = createSelector(
  modelElementsForCurrentModelSelector,
  modelElements => modelElements.filter(modelElement => Boolean(modelElement.submodel)) || [],
);

export const compartmentNameByIdSelector = createSelector(
  [
    modelElementsForCurrentModelSelector,
    (_state, compartmentId: number | undefined | null): number | undefined | null => compartmentId,
  ],
  (modelElements, compartmentId) => {
    if (!compartmentId) {
      return MODEL_ELEMENTS_DEFAULT_COMPARTMENT_NAME;
    }
    return (
      modelElements.find(modelElement => {
        return modelElement.sboTerm === COMPARTMENT_SBO_TERM && modelElement.id === compartmentId;
      })?.name || MODEL_ELEMENTS_DEFAULT_COMPARTMENT_NAME
    );
  },
);

export const currentDrawerModelElementSelector = createSelector(
  modelElementsForCurrentModelSelector,
  currentSearchedBioEntityId,
  (modelElements, currentBioEntityId): ModelElement | undefined => {
    return modelElements.find(modelElement => modelElement.id === currentBioEntityId);
  },
);

export const compartmentPathwaysSelector = createSelector(
  modelElementsDataSelector,
  (state): ModelElement[] => {
    const pathways: ModelElement[] = [];
    Object.values(state).forEach(modelState => {
      pathways.push(
        ...(modelState.data || []).filter(
          modelElement => modelElement.sboTerm === COMPARTMENT_SBO_TERM,
        ),
      );
    });
    return pathways;
  },
);

export const allModelElementsSubmapConnectionsForCurrentSubmapSelector = createSelector(
  modelElementsWithSubmapConnectionForCurrentModelSelector,
  currentModelIdSelector,
  (submapConnectionsModelElement, currentModel): ModelElement[] =>
    submapConnectionsModelElement.filter(({ model }) => model === currentModel),
);

// ------SEARCH (OLD BIO ENTITIES)------
export const modelElementsSearchSelector = createSelector(
  modelElementsStateSelector,
  state => state.search,
);

export const modelElementsSearchDataSelector = createSelector(
  modelElementsSearchSelector,
  state => state.data,
);

export const searchModelElementsListSelector = createSelector(
  modelElementsSearchDataSelector,
  modelElementsSearchData => modelElementsSearchData.map(b => b.data || []).flat(),
);

export const allSearchModelElementForCurrentModelSelector = createSelector(
  modelElementsSearchDataSelector,
  currentModelIdSelector,
  (modelElementsSearchData, currentModelId): ModelElement[] => {
    return modelElementsSearchData
      .map(({ data }) => data || [])
      .flat()
      .filter(({ modelElement }) => modelElement.model === currentModelId)
      .map(({ modelElement }) => modelElement);
  },
);

export const searchedModelElementsSelector = createSelector(
  modelElementsSearchDataSelector,
  currentSelectedSearchElement,
  (
    modelElementsSearchData,
    currentSearchElement,
  ): MultiSearchData<SearchModelElementDataState[]> | undefined =>
    modelElementsSearchData.find(
      ({ searchQueryElement }) => searchQueryElement === currentSearchElement,
    ),
);

export const searchedModelElementsForCurrentModelSelector = createSelector(
  modelElementsSearchDataSelector,
  currentModelIdSelector,
  currentSelectedSearchElement,
  (modelElementsSearchData, currentModelId, currentSearchElement): ModelElement[] => {
    return modelElementsSearchData
      .filter(({ searchQueryElement }) =>
        currentSearchElement ? searchQueryElement === currentSearchElement : true,
      )
      .map(({ data }) => data || [])
      .flat()
      .filter(({ modelElement }) => modelElement.model === currentModelId)
      .map(({ modelElement }) => modelElement);
  },
);

export const searchModelElementsLoadingSelector = createSelector(
  modelElementsSearchSelector,
  search => search.loading,
);

export const searchModelElementsLoadingStatusSelector = createSelector(
  searchedModelElementsSelector,
  state => state?.loading,
);

export const numberOfSearchModelElementsSelector = createSelector(
  searchedModelElementsSelector,
  state => (state?.data ? state.data.length : SIZE_OF_EMPTY_ARRAY),
);

export const searchedModelElementForContextMapSelector = createSelector(
  modelElementsSearchDataSelector,
  currentSelectedBioEntityIdSelector,
  (modelElementsSearchData, currentBioEntityId): ModelElement | undefined => {
    return modelElementsSearchData
      .find(({ searchQueryElement }) => searchQueryElement === currentBioEntityId.toString())
      ?.data?.find(({ modelElement }) => modelElement.id === currentBioEntityId)?.modelElement;
  },
);

export const searchedModelElementUniProtIdSelector = createSelector(
  searchedModelElementForContextMapSelector,
  (modelElement): string | undefined => {
    return modelElement?.references.find(({ type }) => type === 'UNIPROT')?.resource;
  },
);

export const allSearchModelElementsIdTabForCurrentModelSelector = createSelector(
  modelElementsSearchDataSelector,
  currentModelIdSelector,
  (modelElementsSearchData, currentModelId): ElementIdTabObj => {
    const entries = modelElementsSearchData.flatMap(({ data, searchQueryElement }) =>
      (data || [])
        .flat()
        .filter(({ modelElement }) => modelElement.model === currentModelId)
        .map(({ modelElement }) => [modelElement.id, searchQueryElement]),
    );
    return Object.fromEntries(entries);
  },
);

export const searchModelElementsPerModelSelector = createSelector(
  searchedModelElementsSelector,
  modelsDataSelector,
  (modelElements, models) => {
    const modelElementsPerModelPerSearchElement = (models || []).map(model => {
      const modelElementsInGivenModel = (modelElements?.data || []).filter(
        entity => model.id === entity.modelElement.model,
      );

      return {
        modelName: model.name,
        modelId: model.id,
        numberOfModelElements: modelElementsInGivenModel.length,
        modelElements: modelElementsInGivenModel,
      };
    });

    return modelElementsPerModelPerSearchElement.filter(
      model => model.numberOfModelElements !== SIZE_OF_EMPTY_ARRAY,
    );
  },
);

export const currentDrawerModelElementRelatedSubmapSelector = createSelector(
  currentDrawerModelElementSelector,
  modelsDataSelector,
  (bioEntity, models): MapModel | undefined =>
    models.find(({ id }) => id === bioEntity?.submodel?.mapId),
);

export const currentDrawerElementCommentsSelector = createSelector(
  currentDrawerModelElementSelector,
  allCommentsSelectorOfCurrentMap,
  (element, comments): Comment[] => {
    if (element) {
      return comments.filter(
        comment =>
          comment.type === 'ALIAS' &&
          comment.modelId === element.model &&
          Number(comment.elementId) === element.id,
      );
    }
    return [];
  },
);
