import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from '@/shared/Accordion';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MapOverlay } from '@/types/models';
import React from 'react';
import { ZERO } from '@/constants/common';
import { Icon } from '@/shared/Icon';
import { openEditOverlayGroupModal } from '@/redux/modal/modal.slice';
import { overlayGroupSelector } from '@/redux/overlayGroup/overlayGroup.selectors';
import { store } from '@/redux/store';
import { useUserOverlays } from './hooks/useUserOverlays';
import { UserOverlayListItem } from './UserOverlayListItem';

type OverlayGroupProps = {
  groupId: number | null;
  groupName: string;
  overlays: MapOverlay[];
  dangerouslySetExpanded?: boolean;
};

export const UserOverlaysGroup = ({
  overlays,
  groupName,
  groupId,
  dangerouslySetExpanded,
}: OverlayGroupProps): React.ReactNode => {
  const { moveUserOverlayListItem, updateUserOverlaysOrder, isPending, userOverlaysList } =
    useUserOverlays(overlays, groupId);

  const { dispatch, getState } = store;
  const state = getState();
  const overlayGroup = overlayGroupSelector(state, groupId);

  const nullOverlay: MapOverlay = {
    id: groupId ? -groupId : ZERO,
    group: groupId,
    publicOverlay: false,
    creator: '',
    type: '',
    description: '',
    name: '',
    genomeType: '',
    genomeVersion: '',
    order: 0,
  };

  const openEditGroup = (): void => {
    if (overlayGroup) {
      dispatch(openEditOverlayGroupModal(overlayGroup));
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="mt-2.5">
        <Accordion allowZeroExpanded>
          <AccordionItem className="border-b-0" dangerouslySetExpanded={dangerouslySetExpanded}>
            <AccordionItemHeading>
              <AccordionItemButton
                className="px-6 text-sm font-semibold"
                sideMenu={
                  groupId && (
                    <button onClick={openEditGroup} type="button" className="mr-2">
                      <Icon name="pencil" />
                    </button>
                  )
                }
              >
                {groupName}
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              {isPending ? (
                <span className="py-4 pl-10 pr-5">Loading...</span>
              ) : (
                <ul>
                  {userOverlaysList?.map((userOverlay, index) => (
                    <UserOverlayListItem
                      moveUserOverlay={moveUserOverlayListItem}
                      key={userOverlay.id}
                      index={index}
                      userOverlay={userOverlay}
                      updateUserOverlaysOrder={updateUserOverlaysOrder}
                    />
                  ))}
                  <UserOverlayListItem
                    moveUserOverlay={moveUserOverlayListItem}
                    key={nullOverlay.id}
                    index={0}
                    userOverlay={nullOverlay}
                    updateUserOverlaysOrder={updateUserOverlaysOrder}
                  />
                </ul>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </DndProvider>
  );
};
