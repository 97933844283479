/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import './LayerTextFactoryModal.styles.css';
import { LayerTextForm } from '@/components/FunctionalArea/Modal/LayerTextFactoryModal/LayerTextForm.component';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { Button } from '@/shared/Button';
import { LayerTextFactoryForm } from '@/components/FunctionalArea/Modal/LayerTextFactoryModal/LayerTextFactory.types';
import { Color } from '@/types/models';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { showToast } from '@/utils/showToast';
import { closeModal } from '@/redux/modal/modal.slice';
import { SerializedError } from '@reduxjs/toolkit';
import { updateLayerText } from '@/redux/layers/layers.thunks';
import { layerUpdateText } from '@/redux/layers/layers.slice';
import { useMapInstance } from '@/utils/context/mapInstanceContext';
import { mapEditToolsSetLayerObject } from '@/redux/mapEditTools/mapEditTools.slice';
import { mapEditToolsLayerObjectSelector } from '@/redux/mapEditTools/mapEditTools.selectors';
import updateElement from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/updateElement';

export const LayerTextEditFactoryModal: React.FC = () => {
  const layerObject = useAppSelector(mapEditToolsLayerObjectSelector);
  const currentModelId = useAppSelector(currentModelIdSelector);
  const dispatch = useAppDispatch();
  const { mapInstance } = useMapInstance();

  if (!layerObject || !('notes' in layerObject)) {
    throw new Error('Invalid layer text object');
  }

  const [isSending, setIsSending] = useState<boolean>(false);
  const [data, setData] = useState<LayerTextFactoryForm>({
    notes: layerObject.notes,
    fontSize: layerObject.fontSize,
    horizontalAlign: layerObject.horizontalAlign,
    verticalAlign: layerObject.verticalAlign,
    color: layerObject.color,
    borderColor: layerObject.borderColor,
  });

  const handleSubmit = async (): Promise<void> => {
    if (!layerObject) {
      return;
    }
    try {
      const layerText = await dispatch(
        updateLayerText({
          modelId: currentModelId,
          layerId: layerObject.layer,
          id: layerObject.id,
          x: layerObject.x,
          y: layerObject.y,
          z: layerObject.z,
          width: layerObject.width,
          height: layerObject.height,
          notes: data.notes,
          fontSize: data.fontSize,
          horizontalAlign: data.horizontalAlign,
          verticalAlign: data.verticalAlign,
          color: data.color,
          borderColor: data.borderColor,
        }),
      ).unwrap();

      if (layerText) {
        dispatch(layerUpdateText({ modelId: currentModelId, layerId: layerText.layer, layerText }));
        dispatch(mapEditToolsSetLayerObject(layerText));
        updateElement(mapInstance, layerText.layer, layerText);
      }
      showToast({
        type: 'success',
        message: 'The text has been successfully updated.',
      });
      dispatch(closeModal());
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while editing the layer text',
      });
    } finally {
      setIsSending(false);
    }
  };

  const changeValues = (value: string | number | Color, key: string): void => {
    setData(prevData => ({ ...prevData, [key]: value }));
  };

  return (
    <div className="relative w-[900px] border border-t-[#E1E0E6] bg-white p-[24px]">
      {isSending && (
        <div className="c-layer-text-factory-modal-loader">
          <LoadingIndicator width={44} height={44} />
        </div>
      )}
      <LayerTextForm onChange={changeValues} data={data} />
      <hr className="py-2" />
      <Button
        type="button"
        onClick={handleSubmit}
        className="justify-center self-end justify-self-end text-base font-medium"
      >
        Submit
      </Button>
    </div>
  );
};
