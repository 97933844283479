/* eslint-disable no-magic-numbers */
import Draw from 'ol/interaction/Draw';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import Polygon from 'ol/geom/Polygon';
import { MapSize } from '@/redux/map/map.types';
import { AppDispatch } from '@/redux/store';
import { Coordinate } from 'ol/coordinate';
import getBoundingBoxFromExtent from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getBoundingBoxFromExtent';
import { Extent } from 'ol/extent';
import getEllipseCoords from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getEllipseCoords';
import { openLayerOvalFactoryModal } from '@/redux/modal/modal.slice';

export default function getDrawOvalInteraction(
  mapSize: MapSize,
  dispatch: AppDispatch,
  restrictionExtent: Extent,
): Draw {
  const drawOvalInteraction = new Draw({
    type: 'Circle',
    freehand: false,
    freehandCondition: (mapBrowserEvent): boolean => {
      const coords = mapBrowserEvent.coordinate;
      return (
        coords[0] >= restrictionExtent[0] &&
        coords[0] <= restrictionExtent[2] &&
        coords[1] >= restrictionExtent[1] &&
        coords[1] <= restrictionExtent[3]
      );
    },
    geometryFunction: (coordinates, geometry): SimpleGeometry => {
      const newGeometry = geometry || new Polygon([]);
      if (!Array.isArray(coordinates) || coordinates.length < 2) {
        return geometry;
      }
      const start = coordinates[0] as Coordinate;
      const end = coordinates[1] as Coordinate;

      const minX = Math.min(
        restrictionExtent[2],
        Math.max(restrictionExtent[0], Math.min(start[0], end[0])),
      );
      const minY = Math.min(
        restrictionExtent[3],
        Math.max(restrictionExtent[1], Math.min(start[1], end[1])),
      );
      const maxX = Math.max(
        restrictionExtent[0],
        Math.min(restrictionExtent[2], Math.max(start[0], end[0])),
      );
      const maxY = Math.max(
        restrictionExtent[1],
        Math.min(restrictionExtent[3], Math.max(start[1], end[1])),
      );
      const centerX = (minX + maxX) / 2;
      const centerY = (minY + maxY) / 2;
      const ellipseCoords = getEllipseCoords({
        x: centerX,
        y: centerY,
        width: Math.abs(maxX - minX),
        height: Math.abs(maxY - minY),
      });
      newGeometry.setCoordinates([ellipseCoords]);

      return newGeometry;
    },
  });

  drawOvalInteraction.on('drawend', event => {
    const geometry = event.feature.getGeometry() as Polygon;
    const extent = geometry.getExtent();

    const boundingBox = getBoundingBoxFromExtent(extent, mapSize);

    if (!boundingBox.width || !boundingBox.height) {
      return;
    }

    dispatch(openLayerOvalFactoryModal(boundingBox));
  });

  return drawOvalInteraction;
}
