import { OverlayBioEntityRender } from '@/types/OLrendering';
import { GetOverlayBioEntityColorByAvailableProperties } from '@/components/Map/MapViewer/utils/config/overlaysLayer/useGetOverlayColor';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { LineString } from 'ol/geom';
import getStyle from '@/components/Map/MapViewer/utils/mapElementsRendering/style/getStyle';
import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import Style from 'ol/style/Style';
import { MapInstance } from '@/types/map';

export type LineOverlayProps = {
  lineOverlay: OverlayBioEntityRender;
  getOverlayColor: GetOverlayBioEntityColorByAvailableProperties;
  pointToProjection: UsePointToProjectionResult;
  mapInstance: MapInstance;
};

export default class LineOverlay {
  lineOverlay: OverlayBioEntityRender;

  getOverlayColor: GetOverlayBioEntityColorByAvailableProperties;

  pointToProjection: UsePointToProjectionResult;

  mapInstance: MapInstance;

  lineFeature: Feature;

  constructor({ lineOverlay, getOverlayColor, pointToProjection, mapInstance }: LineOverlayProps) {
    this.lineOverlay = lineOverlay;
    this.getOverlayColor = getOverlayColor;
    this.pointToProjection = pointToProjection;
    this.mapInstance = mapInstance;
    this.lineFeature = this.drawOverlay();
  }

  drawOverlay(): Feature {
    const points = [
      this.pointToProjection({ x: this.lineOverlay.x1, y: this.lineOverlay.y1 }),
      this.pointToProjection({ x: this.lineOverlay.x2, y: this.lineOverlay.y2 }),
    ];
    const color = this.getOverlayColor(this.lineOverlay);
    const lineString = new LineString(points);
    const lineStyle = getStyle({
      geometry: lineString,
      borderColor: color,
      lineWidth: 6,
      zIndex: 99999,
    });
    const lineFeature = new Feature<LineString>({
      geometry: lineString,
      style: lineStyle,
      lineWidth: 6,
    });
    lineFeature.setStyle(this.getStyle.bind(this));
    return lineFeature;
  }

  protected getStyle(feature: FeatureLike, resolution: number): Style | Array<Style> | void {
    const maxZoom = this.mapInstance?.getView().get('originalMaxZoom');
    const minResolution = this.mapInstance?.getView().getResolutionForZoom(maxZoom);
    const style = feature.get('style');
    if (!minResolution || !style) {
      return [];
    }

    const scale = minResolution / resolution;
    const lineWidth = feature.get('lineWidth') * scale;

    if (style instanceof Style && style.getStroke()) {
      style.getStroke()?.setWidth(lineWidth);
    }
    return style;
  }
}
