/* eslint-disable no-magic-numbers */
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import { AppDispatch } from '@/redux/store';
import Transform from 'ol-ext/interaction/Transform';
import { Geometry } from 'ol/geom';
import { Collection, Feature } from 'ol';
import BaseEvent from 'ol/events/Event';
import getBoundingBoxFromExtent from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getBoundingBoxFromExtent';
import { MapSize } from '@/redux/map/map.types';
import { Extent } from 'ol/extent';
import { mapEditToolsSetLayerObject } from '@/redux/mapEditTools/mapEditTools.slice';
import { openDrawer } from '@/redux/drawer/drawer.slice';
import { LAYER_ELEMENT_TYPES } from '@/components/Map/MapViewer/MapViewer.constants';
import getEllipseCoords from '@/components/Map/MapViewer/utils/mapElementsRendering/coords/getEllipseCoords';

export default function getTransformInteraction(
  dispatch: AppDispatch,
  mapSize: MapSize,
  modelId: number,
  featuresCollection: Collection<Feature<Geometry>>,
  restrictionExtent: Extent,
): Transform {
  const transform = new Transform({
    features: featuresCollection,
    scale: true,
    rotate: false,
    stretch: true,
    keepRectangle: true,
    translate: true,
    translateBBox: true,
    noFlip: true,
  });

  transform.on('translating', (event: BaseEvent | Event) => {
    const transformEvent = event as unknown as { feature: Feature };
    const { feature } = transformEvent;
    const geometry = feature.getGeometry();
    if (geometry) {
      const extent = geometry.getExtent();
      const [minX, minY, maxX, maxY] = extent;

      const width = maxX - minX;
      const height = maxY - minY;

      const correctedMinX = Math.min(
        restrictionExtent[2] - width,
        Math.max(restrictionExtent[0], minX),
      );
      const correctedMaxX = Math.min(
        restrictionExtent[2],
        Math.max(restrictionExtent[0] + width, maxX),
      );
      const correctedMinY = Math.min(
        restrictionExtent[3] - height,
        Math.max(restrictionExtent[1], minY),
      );
      const correctedMaxY = Math.min(
        restrictionExtent[3],
        Math.max(restrictionExtent[1] + height, maxY),
      );

      const dx = correctedMinX - minX;
      const dy = correctedMinY - minY;

      if (dx !== 0 || dy !== 0) {
        geometry.translate(dx, dy);
        geometry.changed();
        transform.setSelection(new Collection([feature]));
      }
      if (feature.get('elementType') === LAYER_ELEMENT_TYPES.OVAL) {
        const setOvalCoordinates = feature.get('setOvalCoordinates');
        const ellipseCoords = getEllipseCoords({
          x: (correctedMinX + correctedMaxX) / 2,
          y: (correctedMinY + correctedMaxY) / 2,
          height,
          width,
          points: 20,
        });
        if (setOvalCoordinates instanceof Function) {
          setOvalCoordinates([ellipseCoords]);
        }
      }
    }
  });

  transform.on('scaling', (event: BaseEvent | Event) => {
    const transformEvent = event as unknown as { feature: Feature };
    const { feature } = transformEvent;
    const geometry = feature.getGeometry();
    if (!geometry) {
      return;
    }
    const extent = geometry.getExtent();
    const [minX, minY, maxX, maxY] = extent;
    const newMinX = Math.max(minX, restrictionExtent[0]);
    const newMinY = Math.max(minY, restrictionExtent[1]);
    const newMaxX = Math.min(maxX, restrictionExtent[2]);
    const newMaxY = Math.min(maxY, restrictionExtent[3]);
    const newExtent = [newMinX, newMinY, newMaxX, newMaxY];

    const hasChanged = newExtent.some((value, index) => value !== extent[index]);

    if (feature.get('elementType') === LAYER_ELEMENT_TYPES.OVAL) {
      const setOvalCoordinates = feature.get('setOvalCoordinates');
      const ellipseCoords = getEllipseCoords({
        x: (newMinX + newMaxX) / 2,
        y: (newMinY + newMaxY) / 2,
        height: Math.abs(newMaxY - newMinY),
        width: Math.abs(newMaxX - newMinX),
        points: 30,
      });
      if (setOvalCoordinates instanceof Function) {
        setOvalCoordinates([ellipseCoords]);
      }
    }
    if (hasChanged) {
      const newGeometry = fromExtent(newExtent);
      newGeometry.scale(1, -1);
      feature.setGeometry(newGeometry);
      transform.setSelection(new Collection([feature]));
      return;
    }
    const setCoordinates = feature.get('setCoordinates');
    if (geometry instanceof Polygon && setCoordinates instanceof Function) {
      setCoordinates(geometry.getCoordinates());
    }
  });

  transform.on('select', event => {
    const transformEvent = event as unknown as { features: Collection<Feature> };
    const { features } = transformEvent;
    if (!features.getLength()) {
      dispatch(mapEditToolsSetLayerObject(null));
      return;
    }
    const getObjectData = features.item(0).get('getObjectData');
    if (getObjectData && getObjectData instanceof Function) {
      const objectData = getObjectData();
      dispatch(mapEditToolsSetLayerObject(objectData));
      dispatch(openDrawer('layers'));
    }
  });

  transform.on(['scaleend', 'translateend'], async (event: BaseEvent | Event): Promise<void> => {
    const transformEvent = event as unknown as { feature: Feature };
    const { feature } = transformEvent;
    const refreshPolygon = feature.get('refreshPolygon');
    const save = feature.get('save');
    const geometry = feature.getGeometry();
    if (geometry && save instanceof Function) {
      try {
        const boundingBox = getBoundingBoxFromExtent(geometry.getExtent(), mapSize);
        save({ modelId, boundingBox });
      } catch {
        if (refreshPolygon instanceof Function) {
          refreshPolygon();
        }
      }
    }
  });

  return transform;
}
