import Polygon from 'ol/geom/Polygon';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';

export default function getBoundingBoxPolygon({
  x,
  y,
  width,
  height,
  pointToProjection,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
  pointToProjection: UsePointToProjectionResult;
}): Polygon {
  return new Polygon([
    [
      pointToProjection({ x, y }),
      pointToProjection({ x: x + width, y }),
      pointToProjection({ x: x + width, y: y + height }),
      pointToProjection({ x, y: y + height }),
      pointToProjection({ x, y }),
    ],
  ]);
}
