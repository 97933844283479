/* eslint-disable no-magic-numbers */
import { HorizontalAlign, VerticalAlign } from '@/components/Map/MapViewer/MapViewer.types';
import { UsePointToProjectionResult } from '@/utils/map/usePointToProjection';
import { Coordinate } from 'ol/coordinate';

export default function getTextCoords({
  x,
  y,
  lines = 1,
  height,
  width,
  fontSize,
  verticalAlign,
  horizontalAlign,
  pointToProjection,
  useProjection = true,
}: {
  x: number;
  y: number;
  lines?: number;
  height: number;
  width: number;
  fontSize: number;
  verticalAlign: VerticalAlign;
  horizontalAlign: HorizontalAlign;
  pointToProjection?: UsePointToProjectionResult;
  useProjection?: boolean;
}): Coordinate {
  const minX = x;
  const maxX = x + width;
  const minY = y;
  const maxY = y + height;
  let textY = (minY + maxY) / 2;
  if (verticalAlign === 'TOP') {
    textY = minY + fontSize / 2 + (fontSize * (lines - 1)) / 2;
  } else if (verticalAlign === 'BOTTOM') {
    textY = maxY - fontSize / 2;
  }

  let textX = (minX + maxX) / 2;
  if (['LEFT', 'START'].includes(horizontalAlign)) {
    textX = minX;
  } else if (['RIGHT', 'END'].includes(horizontalAlign)) {
    textX = maxX;
  }

  if (useProjection && pointToProjection) {
    return pointToProjection({ x: textX, y: textY });
  }
  return [textX, textY];
}
