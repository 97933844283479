import { Fill, Text } from 'ol/style';
import Style from 'ol/style/Style';
import { HorizontalAlign } from '@/components/Map/MapViewer/MapViewer.types';

export default function getTextStyle({
  text,
  fontSize,
  color,
  zIndex,
  horizontalAlign,
  overflow = true,
}: {
  text: string;
  fontSize: number;
  color: string;
  zIndex: number;
  horizontalAlign: HorizontalAlign;
  overflow?: boolean;
}): Style {
  return new Style({
    text: new Text({
      text,
      font: `${fontSize}pt Arial`,
      fill: new Fill({
        color,
      }),
      placement: 'point',
      textAlign: horizontalAlign.toLowerCase() as CanvasTextAlign,
      textBaseline: 'middle',
      overflow,
    }),
    zIndex,
  });
}
