import { MapOverlay } from '@/types/models';
import { DataOverlayEntry } from '@/services/pluginsManager/map/overlays/types/DataOverlayEntry';

export class DataOverlay {
  id: number;

  name: string;

  order: number;

  creator: string;

  description: string;

  genomeType: string | null;

  genomeVersion: string | null;

  publicOverlay: boolean;

  type: string;

  entries: DataOverlayEntry[];

  constructor(mapOverlay: MapOverlay) {
    this.id = mapOverlay.id;
    this.name = mapOverlay.name;
    this.order = mapOverlay.order;
    this.creator = mapOverlay.creator;
    this.description = mapOverlay.description;
    this.genomeType = mapOverlay.genomeType;
    this.genomeVersion = mapOverlay.genomeVersion;
    this.publicOverlay = mapOverlay.publicOverlay;
    this.type = mapOverlay.type;
    this.entries = [];
  }

  public addEntry(entry: DataOverlayEntry): void {
    this.entries.push(entry);
  }
}
