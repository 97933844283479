import { clearChemicalsData } from '@/redux/chemicals/chemicals.slice';
import { closeContextMenu } from '@/redux/contextMenu/contextMenu.slice';
import { resultDrawerOpen } from '@/redux/drawer/drawer.selectors';
import { closeDrawer } from '@/redux/drawer/drawer.slice';
import { clearDrugsData } from '@/redux/drugs/drugs.slice';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { resetReactionsData } from '@/redux/reactions/reactions.slice';
import { clearSearchData } from '@/redux/search/search.slice';
import { Button } from '@/shared/Button';
import { Icon } from '@/shared/Icon';
import React from 'react';
import { useSelector } from 'react-redux';
import { clearSearchModelElements } from '@/redux/modelElements/modelElements.slice';

export const ClearAnchorsButton = (): React.ReactNode => {
  const dispatch = useAppDispatch();
  const isResultDrawerOpen = useSelector(resultDrawerOpen);

  const closeInterfaceElements = (): void => {
    if (isResultDrawerOpen) {
      dispatch(closeDrawer());
    }
    dispatch(closeContextMenu());
  };

  const resetData = (): void => {
    // Reset reactions list to prevent keeping the old selected reaction rendered
    dispatch(resetReactionsData());

    // Reset search data to prevent invalid filtering of the click-search ()
    dispatch(clearSearchData());

    // Reset old pins data
    dispatch(clearSearchModelElements());
    dispatch(clearDrugsData());
    dispatch(clearChemicalsData());
  };

  const onClearAnchorsClick = (): void => {
    closeInterfaceElements();
    resetData();
  };

  return (
    <Button
      className="ml-2 hover:bg-transparent active:bg-transparent"
      onClick={onClearAnchorsClick}
      title="Clear"
      variantStyles="quiet"
    >
      <Icon name="clear" />
    </Button>
  );
};
