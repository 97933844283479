/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import './LayerRectFactoryModal.styles.css';
import { LoadingIndicator } from '@/shared/LoadingIndicator';
import { Button } from '@/shared/Button';
import { LayerRectForm } from '@/components/FunctionalArea/Modal/LayerRectFactoryModal/LayerRectForm.component';
import { LayerRectFactoryForm } from '@/components/FunctionalArea/Modal/LayerRectFactoryModal/LayerRectFactory.types';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { currentModelIdSelector } from '@/redux/models/models.selectors';
import { Color } from '@/types/models';
import { updateLayerRect } from '@/redux/layers/layers.thunks';
import { showToast } from '@/utils/showToast';
import { layerUpdateRect } from '@/redux/layers/layers.slice';
import { closeModal } from '@/redux/modal/modal.slice';
import { mapEditToolsSetLayerObject } from '@/redux/mapEditTools/mapEditTools.slice';
import { SerializedError } from '@reduxjs/toolkit';
import { useAppDispatch } from '@/redux/hooks/useAppDispatch';
import { useMapInstance } from '@/utils/context/mapInstanceContext';
import { mapEditToolsLayerObjectSelector } from '@/redux/mapEditTools/mapEditTools.selectors';
import updateElement from '@/components/Map/MapViewer/utils/mapElementsRendering/layer/utils/updateElement';

export const LayerRectEditFactoryModal: React.FC = () => {
  const layerObject = useAppSelector(mapEditToolsLayerObjectSelector);
  const currentModelId = useAppSelector(currentModelIdSelector);
  const dispatch = useAppDispatch();
  const { mapInstance } = useMapInstance();

  if (!layerObject || !('fillColor' in layerObject && 'borderColor' in layerObject)) {
    throw new Error('Invalid layer rectangle object');
  }

  const [isSending, setIsSending] = useState<boolean>(false);
  const [data, setData] = useState<LayerRectFactoryForm>({
    fillColor: layerObject.fillColor,
    borderColor: layerObject.borderColor,
  });

  const handleSubmit = async (): Promise<void> => {
    if (!layerObject) {
      return;
    }
    try {
      const layerRect = await dispatch(
        updateLayerRect({
          modelId: currentModelId,
          layerId: layerObject.layer,
          id: layerObject.id,
          x: layerObject.x,
          y: layerObject.y,
          z: layerObject.z,
          width: layerObject.width,
          height: layerObject.height,
          fillColor: data.fillColor,
          borderColor: data.borderColor,
        }),
      ).unwrap();

      if (!layerRect) {
        showToast({
          type: 'error',
          message: 'An error occurred while editing the rectangle.',
        });
        return;
      }

      dispatch(layerUpdateRect({ modelId: currentModelId, layerId: layerRect.layer, layerRect }));
      dispatch(mapEditToolsSetLayerObject(layerRect));
      updateElement(mapInstance, layerRect.layer, layerRect);
      showToast({
        type: 'success',
        message: 'The rectangle has been successfully updated.',
      });
      dispatch(closeModal());
    } catch (error) {
      const typedError = error as SerializedError;
      showToast({
        type: 'error',
        message: typedError.message || 'An error occurred while editing the rectangle.',
      });
    } finally {
      setIsSending(false);
    }
  };

  const changeValues = (value: string | number | Color, key: string): void => {
    setData(prevData => ({ ...prevData, [key]: value }));
  };

  return (
    <div className="relative flex w-[400px] flex-col gap-4 rounded-b-lg border border-t-[#E1E0E6] bg-white p-[24px]">
      {isSending && (
        <div className="c-layer-rect-factory-modal-loader">
          <LoadingIndicator width={44} height={44} />
        </div>
      )}
      <LayerRectForm onChange={changeValues} data={data} />
      <hr />
      <Button
        type="button"
        onClick={handleSubmit}
        className="justify-center self-end justify-self-end text-base font-medium"
      >
        Submit
      </Button>
    </div>
  );
};
