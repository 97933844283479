import { ONE } from '@/constants/common';
import { ModelElementWithPinType, MultiPinModelElement } from '@/types/modelElement';
import { ModelElement } from '@/types/models';
import { PinType } from '@/types/pin';

interface Args {
  modelElements: MultiPinModelElement;
}

const SEPARATOR = '-';
const POSITION_PRESCISION_SEPERATOR = '.';

const getUniqueKey = (element: Pick<ModelElement, 'x' | 'y'>): string => {
  const [x] = `${element.x}`.split(POSITION_PRESCISION_SEPERATOR);
  const [y] = `${element.y}`.split(POSITION_PRESCISION_SEPERATOR);

  return [x, y].join(SEPARATOR);
};

const groupByPosition = (
  accumulator: Record<string, MultiPinModelElement>,
  element: ModelElementWithPinType,
): Record<string, MultiPinModelElement> => {
  const key = getUniqueKey(element);

  return {
    ...accumulator,
    [key]: accumulator[key] ? [...accumulator[key], element] : [element],
  };
};

const toUniqueTypeMultipin = (multipin: MultiPinModelElement): MultiPinModelElement => {
  const allTypes: PinType[] = multipin.map(pin => pin.type);
  const uniqueTypes = [...new Set(allTypes)];

  return uniqueTypes
    .map(type => multipin.find(pin => pin.type === type))
    .filter((value): value is ModelElementWithPinType => value !== undefined);
};

export const getMultipinsModelElements = ({ modelElements }: Args): MultiPinModelElement[] => {
  const multipiledModelElements = modelElements.filter(
    baseElement =>
      modelElements.filter(element => getUniqueKey(baseElement) === getUniqueKey(element)).length >
      ONE,
  );

  const duplicatedMultipinsGroupedByPosition = multipiledModelElements.reduce(
    groupByPosition,
    {} as Record<string, MultiPinModelElement>,
  );

  const allGroupedMultipins = Object.values(duplicatedMultipinsGroupedByPosition);
  const uniqueTypeGroupedMultipins = allGroupedMultipins.map(toUniqueTypeMultipin);
  const multipiledMultiPins = uniqueTypeGroupedMultipins.filter(multipin => multipin.length > ONE);

  return multipiledMultiPins;
};
